import {
    GET_ALL_ITEMS,
    ITEM_ERROR
} from '../actions/types';

const initialState = {
    items: [],
    loading: true,
    error: {}
}

function itemReducer(state = initialState, action) {

    const { type, payload } = action;
    
    switch(type){
        case GET_ALL_ITEMS:
            return{
                ...state,
                items: payload
            };
        case ITEM_ERROR:
            return{
                ...state,
                error: payload
            };
        default:
            return {...state}
    }
}

export default itemReducer;