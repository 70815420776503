import React, {Fragment, useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProfile, updateProfile } from '../../actions/profile';

const UpdateProfile = ({updateProfile, getCurrentProfile}) => {
    const [formData, setFormData] = useState({
        name: '',
        avatar: '',
        location: '',
        twitter: '',
        youtube: '',
        discord: ''
    });

    const {
        name,
        avatar,
        location,
        twitter,
        youtube,
        discord
    } = formData;

    const onChange = e => {
      setFormData({...formData, [e.target.name]: e.target.value});
    }

    const onSubmit = e => {
        e.preventDefault();
        updateProfile(formData);
    }

    return (
        <Fragment>
      <h1 className="large text-primary">Edit Your Profile</h1>
      <form className="form" onSubmit={onSubmit}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Name"
            name="name"
            value={name}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Profile Picture URL"
            name="avatar"
            value={avatar}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Location"
            name="location"
            value={location}
            onChange={onChange}
          />
          <small className="form-text">
            At least country and state/region are recommended
          </small>
        </div>
        <div className="form-group social-input">
            <input
            type="text"
            placeholder="Twitter URL"
            name="twitter"
            value={twitter}
            onChange={onChange}
            />
        </div>

        <div className="form-group social-input">
            <input
            type="text"
            placeholder="YouTube URL"
            name="youtube"
            value={youtube}
            onChange={onChange}
            />
        </div>

        <div className="form-group social-input">
            <input
            type="text"
            placeholder="Discord URL"
            name="discord"
            value={discord}
            onChange={onChange}
            />
        </div>

        <input type="submit" className="btn btn-primary my-1" />
        <Link className="btn btn-light my-1" to="/dashboard">
          Go Back
        </Link>
      </form>
    </Fragment>
    )
}

UpdateProfile.propTypes = {
    updateProfile: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired
}

export default connect(null, {updateProfile, getCurrentProfile})(UpdateProfile);
