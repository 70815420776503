import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import profile from './profile';
import character from './character';
import campaign from './campaign';
import skill from './skill';
import item from './item';

export default combineReducers({
    alert,
    auth,
    profile,
    character,
    campaign,
    skill,
    item
});