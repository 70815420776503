import React, { Fragment} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const CharViewStatus = ({
    character: {
        character
    }
}) => {

    const {
        currentHP,
        baseHP,
        currentBAR,
        baseBAR,
        armorClass,
        statusEffects,
        weaknesses,
        resistances,
        immunities,
        magazines,
        head,
        chest,
        hands,
        feet,
        mainHand,
        offHand,
        rechargeAvailable,
        psychStim,
        psychCharges,
        energyStim,
        energyCharges,
        fluidStim,
        fluidCharges,
        gravStim,
        gravCharges,
        waveStim,
        waveCharges
    } = character;


    return (
        <Fragment>
            <div className="char-sheet-status-panel">
                <div className="health">
                    <div>
                        <p className="lead text-blue">HEALTH</p>
                        <div className="health-fill" style={{width: currentHP/baseHP*400 + 'px'}}></div>
                        <div className="health-border">
                            <span>{currentHP}</span>/<span>{baseHP}</span>
                        </div>
                    </div>
                    <div>
                        <p className="lead text-blue">BARRIER</p>
                        <div className="bar-fill" style={{width: currentBAR/baseBAR*400 + 'px'}}></div>
                        <div className="health-border">
                            <span>{currentBAR}</span>/<span>{baseBAR}</span>
                        </div>
                    </div>
                    <div className="ac lead text-blue">
                        AC<p id="ac">{armorClass}</p>
                    </div>
                </div>
                <div className="status">
                    <div className="status-item">
                        <p>STATUS EFFECTS</p>
                        <div>
                        </div>
                    </div>
                    <div className="status-item">
                        <p>WEAKNESSES</p>
                        <div>
                        </div>
                    </div>
                    <div className="status-item">
                        <p>RESISTANCES</p>
                        <div>
                        </div>
                    </div>
                    <div className="status-item">
                        <p>IMMUNITIES</p>
                        <div>
                        </div>
                    </div>
                </div>
                <div className="equipment">
                    <p className="lead text-blue">EQUIPMENT</p>
                    <p className="equip-type">HELMET</p>
                    <div className="armor-container">
                        <div className="armor-name medium"></div>
                        <div className="armor-className"></div>
                        <div className="armor-ac">AC <span></span></div>
                        <div className="armor-bar">BAR <span></span></div>
                        <div className="armor-cond">COND <span></span></div>
                        <div className="armor-effects">EFFECTS
                        </div>
                        <div className="mods">
                            <p>MODS</p>
                            <div className="mod-area">
                            </div>
                        </div>
                    </div>
                    <p className="equip-type">CHEST</p>
                    <div className="armor-container">
                        <div className="armor-name medium"></div>
                        <div className="armor-className"></div>
                        <div className="armor-ac">AC <span></span></div>
                        <div className="armor-bar">BAR <span></span></div>
                        <div className="armor-cond">COND <span></span></div>
                        <div className="armor-effects">EFFECTS
                        </div>
                        <div className="mods">
                            <p>MODS</p>
                            <div className="mod-area">
                            </div>
                        </div>
                    </div>
                    <p className="equip-type">GLOVES</p>
                    <div className="armor-container">
                        <div className="armor-name medium"></div>
                        <div className="armor-className"></div>
                        <div className="armor-ac">AC <span></span></div>
                        <div className="armor-bar">BAR <span></span></div>
                        <div className="armor-cond">COND <span></span></div>
                        <div className="armor-effects">EFFECTS
                        </div>
                        <div className="mods">
                            <p>MODS</p>
                            <div className="mod-area">
                            </div>
                        </div>
                    </div>
                    <p className="equip-type">BOOTS</p>
                    <div className="armor-container">
                        <div className="armor-name medium"></div>
                        <div className="armor-className"></div>
                        <div className="armor-ac">AC <span></span></div>
                        <div className="armor-bar">BAR <span></span></div>
                        <div className="armor-cond">COND <span></span></div>
                        <div className="armor-effects">EFFECTS
                        </div>
                        <div className="mods">
                            <p>MODS</p>
                            <div className="mod-area">
                            </div>
                        </div>
                    </div>
                    <p className="equip-type">MAIN HAND</p>
                    <div className="weapon-container">
                        <div className="weapon-name medium"></div>
                        <div className="weapon-className"></div>
                        <div className="weapon-dd">DD <span></span></div>
                        <div className="weapon-range">RNG <span></span></div>
                        <div className="weapon-cond">COND <span></span></div>
                        <div className="weapon-effects">EFFECTS
                        </div>
                        <div className="mods">
                            <p>MODS</p>
                            <div className="mod-area">
                            </div>
                        </div>
                    </div>
                    <p className="equip-type">OFF HAND</p>
                    <div className="weapon-container">
                        <div className="weapon-name medium"></div>
                        <div className="weapon-className"></div>
                        <div className="weapon-dd">DD <span></span></div>
                        <div className="weapon-range">RNG <span></span></div>
                        <div className="weapon-cond">COND <span></span></div>
                        <div className="weapon-effects">EFFECTS
                        </div>
                        <div className="mods">
                            <p>MODS</p>
                            <div className="mod-area">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="stims">
                    <p className="lead text-blue">STIMS</p>
                    <p className="stim-type">ENERGY</p>
                    <div className="stim-container">
                        <div className="stim-name">
                            <p></p>
                        </div>
                        <div className="charges">
                        </div>
                    </div>
                    <p className="stim-type">FLUID</p>
                    <div className="stim-container">
                        <div className="stim-name">
                            <p></p>
                        </div>
                        <div className="charges">
                        </div>
                    </div>
                    <p className="stim-type">GRAV</p>
                    <div className="stim-container">
                        <div className="stim-name">
                            <p></p>
                        </div>
                        <div className="charges">
                        </div>
                    </div>
                    <p className="stim-type">PSYCHE</p>
                    <div className="stim-container">
                        <div className="stim-name">
                            <p></p>
                        </div>
                        <div className="charges">
                        </div>
                    </div>
                    <p className="stim-type">WAVE</p>
                    <div className="stim-container">
                        <div className="stim-name">
                            <p></p>
                        </div>
                        <div className="charges">
                        </div>
                    </div>
                </div>
                <div className="status-items">
                    <p className="lead text-blue">ITEMS</p>
                    <div>
                        <div>MAGAZINES <br /><p>{magazines}</p></div>
                        <div>BATTERIES <br /><p>{rechargeAvailable}</p></div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

CharViewStatus.propTypes = {
    character: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    character: state.character
});

export default connect(mapStateToProps)(CharViewStatus);
