import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import landing_bg from '../../img/landing_bg.jpg'

const Landing = ({ isAuthenticated }) => {



    if(isAuthenticated) {
        return <Navigate to='/dashboard' />
    }

    return (
        
        <section className="landing">
            <img src={landing_bg} className="landing-bg"></img>
            <div className="dark-overlay">
                
            </div>
            <div className="landing-inner">
                <h1 className="x-large landing-title">KALEIDO</h1>
                <p className="lead">A digitally-based, science fiction, role playing experience.</p>
                <p className="lead">Join a team and explore the unknown reaches of the Kaleido and Milky Way galaxies.</p>
                <div className="buttons pady-2">
                    <Link to="/register" className="btn btn-med2 btn-landing">Register</Link>
                    <Link to="/login" className="btn btn-med2 btn-landing">Login</Link>
                </div>
            </div>
            
        </section>
    )
}

Landing.propTypes = {
    isAuthenticated: PropTypes.bool
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(Landing);
