import React, { Fragment} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { populateActions } from '../../../utils/calculations';
import { skills } from '../../../resources/skills';

const CharViewActions = ({
    character: {
        character
    }
}) => {

    const {
        armorLevel,
        armorExpertise,
        assaultWeaponLevel,
        assaultWeaponExpertise,
        astrobiologyLevel,
        astrobiologyExpertise,
        athleticsLevel,
        athleticsExpertise,
        bladeLevel,
        bladeExpertise,
        bluntLevel,
        bluntExpertise,
        energyLevel,
        energyExpertise,
        espionageLevel,
        espionageExpertise,
        explosivesLevel,
        explosivesExpertise,
        fluidLevel,
        fluidExpertise,
        gravLevel,
        gravExpertise,
        longRangeLevel,
        longRangeExpertise,
        negotiationLevel,
        negotiationExpertise,
        observationLevel,
        observationExpertise,
        pistolLevel,
        pistolExpertise,
        psycheLevel,
        psycheExpertise,
        techLevel,
        techExpertise,
        unarmedLevel,
        unarmedExpertise,
        vehiclesLevel,
        vehiclesExpertise,
        waveLevel,
        waveExpertise
    } = character;

    let charPropArray = Object.keys(character);
    charPropArray = charPropArray.slice(charPropArray.length - 60);

    const levelArray = [
        armorLevel,
        assaultWeaponLevel,
        astrobiologyLevel,
        athleticsLevel,
        bladeLevel,
        bluntLevel,
        energyLevel,
        espionageLevel,
        explosivesLevel,
        fluidLevel,
        gravLevel,
        longRangeLevel,
        negotiationLevel,
        observationLevel,
        pistolLevel,
        psycheLevel,
        techLevel,
        unarmedLevel,
        vehiclesLevel,
        waveLevel
    ];

    const expertiseArray = [
        armorExpertise,
        assaultWeaponExpertise,
        astrobiologyExpertise,
        athleticsExpertise,
        bladeExpertise,
        bluntExpertise,
        energyExpertise,
        espionageExpertise,
        explosivesExpertise,
        fluidExpertise,
        gravExpertise,
        longRangeExpertise,
        negotiationExpertise,
        observationExpertise,
        pistolExpertise,
        psycheExpertise,
        techExpertise,
        unarmedExpertise,
        vehiclesExpertise,
        waveExpertise
    ];

    const allActions = populateActions(skills, levelArray, expertiseArray);

    return ( 
        <Fragment>
            <div className="char-sheet-actions-panel">
                <h1 className="lead text-blue">MAJOR</h1>
                {allActions.map((action) => action.type === "Major" && (
                    <div key={action.name}>
                        <p className="action-name">{action.name}</p>
                        <p className="action-desc">{action.desc}</p>
                    </div>
                ))}
                <h1 className="lead text-blue">MINOR</h1>
                {allActions.map((action) => action.type === "Minor" && (
                    <div key={action.name}>
                        <p className="action-name">{action.name}</p>
                        <p className="action-desc">{action.desc}</p>
                    </div>
                ))}
                
                <h1 className="lead text-blue">MOVEMENT</h1>
                {allActions.map((action) => action.type === "Movement" && (
                    <div key={action.name}>
                        <p className="action-name">{action.name}</p>
                        <p className="action-desc">{action.desc}</p>
                    </div>
                ))}
                <h1 className="lead text-blue">NON-COMBAT</h1>
                {allActions.map((action) => action.type === "Non-Combat" && (
                    <div key={action.name}>
                        <p className="action-name">{action.name}</p>
                        <p className="action-desc">{action.desc}</p>
                    </div>
                ))}
                <h1 className="lead text-blue">BONUS</h1>
                {allActions.map((action) => action.type === "Bonus" && (
                    <div key={action.name}>
                        <p className="action-name">{action.name}</p>
                        <p className="action-desc">{action.desc}</p>
                    </div>
                ))}
            </div>
        </Fragment>
    );
};

CharViewActions.propTypes = {
    character: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    character: state.character
});

export default connect(mapStateToProps)(CharViewActions);
