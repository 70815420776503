import React, { Fragment, useState} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { saveCharacter } from '../../../actions/character';

const LevelUp = ({
    character: {
        character
    },
    saveCharacter,
    closeModal
}) => {

    const {
        level,
        baseHP,
        currentHP,
        athleticsLevel,
        athleticsExpertise,
        STR,
        DEX,
        CON,
        INT,
        WIS,
        CHA
    } = character;

    const attrList = ['STR', 'DEX', 'CON', 'INT', 'WIS', 'CHA'];

    const [selectedAttr, updateSelectedAttr] = useState(null);

    const setHPChange = () => {
        var HPchange = 2;

        if(athleticsLevel >= 5) { HPchange = 4 }

        if(athleticsLevel >= 35 && athleticsExpertise === 'Bodybuilder'){ HPchange = 6 }

        console.log(athleticsLevel, athleticsExpertise, HPchange);

        return baseHP + HPchange;
    }

    const updateAttributeButtons = () => {

        const attrButtons = attrList.map((a, index) => 
            <p 
                className={index === selectedAttr ? 'selected' : ''} 
                key={index} 
                value={a} 
                onClick={(e) => updateSelectedAttr(index)}
            >
                {attrList[index]}
            </p>
        )

        console.log(selectedAttr);

        return attrButtons;
    }

    const confirmLevelUp = e => {

        if(level % 2 === 1 && selectedAttr === null){
            alert('Please select an attribute to increase!');
        } else {
            var newSkillPoints;

            if(level < 8){ newSkillPoints = 15; }
            else { newSkillPoints = 10; }

            var HPchange = 2;

            if(athleticsLevel >= 5) { HPchange = 4 } else
            if(athleticsLevel >= 35 && athleticsExpertise === 'Bodybuilder'){ HPchange = 6 }

            var attrChange;

            switch(selectedAttr){
                case(0):
                    attrChange = STR + 1;
                    break;
                case(1):
                    attrChange = DEX + 1;
                    break;
                case(2):
                    attrChange = CON + 1;
                    break;
                case(3):
                    attrChange = INT + 1;
                    break;
                case(4):
                    attrChange = WIS + 1;
                    break;
                case(5):
                    attrChange = CHA + 1;
                    break;
                default:
                    break;
            }

            if(selectedAttr === null){
                saveCharacter({
                    ...character,
                    'level': level + 1,
                    'currentHP': currentHP + HPchange,
                    'baseHP': baseHP + HPchange,
                    'skillPoints': character.skillPoints + newSkillPoints
                });
            } else {
                saveCharacter({
                    ...character,
                    'level': level + 1,
                    'currentHP': currentHP + HPchange,
                    'baseHP': baseHP + HPchange,
                    'skillPoints': character.skillPoints + newSkillPoints,
                    [attrList[selectedAttr]]: attrChange
                });
            } 
            

            closeModal();
        }

        
    }

    console.log(selectedAttr);

    return (
        <Fragment>
            <div className="levelUpWrapper">
                <div className='closeLvlUpModal' onClick={e => closeModal()(e)}>X</div>
                <h1>Level Up</h1>
                <div className="levelUpItem">
                    <h2>New Level:</h2>
                    <p>{level + 1}</p>
                </div>
                <div className="levelUpItem">
                    <h2>Skill Points:</h2>
                    <p>{character.level < 8 ? 15 : 10}</p>
                </div>
                <div className="levelUpItem">
                    <h2>New Base HP:</h2>
                    <p>{setHPChange()}</p>
                    <span></span>
                </div>
                { character.level % 2 == 1 && (
                    <div className="levelUpAttrItem">
                        <h2>Attribute Increase:</h2>
                        <div className="levelUpAttrContainer">
                            {updateAttributeButtons()}
                        </div>
                        {selectedAttr !== null &&
                        <div className="attrChangeInfo">
                            <span>New {attrList[selectedAttr]} value: </span>
                            <span>
                                {
                                    selectedAttr === 0 ? STR + 1 :
                                    selectedAttr === 1 ? DEX + 1 :
                                    selectedAttr === 2 ? CON + 1 :
                                    selectedAttr === 3 ? INT + 1 :
                                    selectedAttr === 4 ? WIS + 1 :
                                    selectedAttr === 5 && CHA + 1 
                                }
                            </span>
                        </div>}
                    </div>
                )}
                <div class="levelUpConfirm" onClick={e => confirmLevelUp(e)}>Confirm Level Up?</div>
            </div>
            
        </Fragment>
    )
}

LevelUp.propTypes = {
    character: PropTypes.object.isRequired,
    saveCharacter: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    character: state.character
});

export default connect(mapStateToProps, {saveCharacter})(LevelUp);