import axios from 'axios';
import { setAlert } from './alert';

import {
    CAMPAIGN_ERROR,
    GET_MY_CAMPAIGNS,
    GET_CAMPAIGN,
    CREATE_CAMPAIGN,
    SAVE_CAMPAIGN,
    UPDATE_CAMPAIGN,
    DELETE_CAMPAIGN
} from '../actions/types';

// Add new campaign to the database
export const createCampaign = (campObject, history) => async dispatch => {
    try {

        const res = await axios.post('/api/campaign', campObject);

        dispatch({
            type: CREATE_CAMPAIGN,
            payload: res.data
        });

        // Need to have this automatically send you to the campiagn edit page once we have an ID to use for the GET call
        //console.log(history);

        
        
    } catch (err) {
        const errors = err.response.data.errors;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: CAMPAIGN_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

//Get campaigns for current user
export const getMyCampaigns = () => async dispatch => {

    try {
        const res = await axios.get('/api/campaign/me');

        dispatch({
            type: GET_MY_CAMPAIGNS,
            payload: res.data
        });
    } catch (err) {
        const errors = err.response.data.errors;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: CAMPAIGN_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}

//Get campaign based on current id
export const getCurrentCampaign = id => async dispatch => {

    try {
        const res = await axios.get(`/api/campaign/${id}`);

        dispatch({
            type: GET_CAMPAIGN,
            payload: res.data
        });

    } catch (err) {
        const errors = err.response.data.errors;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: CAMPAIGN_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}

//Update database campaign object
export const updateCampaign = (campID, newArea) => async dispatch => {
    try {

        const res = await axios.post(
            `/api/campaign/${campID}`, 
            newArea, 
            {
                headers: {'Content-Type': 'multipart/form-data'}
            }
        );
        
        dispatch({
            type: UPDATE_CAMPAIGN,
            payload: res.data
        });

        console.log(res.data)

    } catch (err) {
        dispatch({
            type: CAMPAIGN_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

//Delete a campaign
export const deleteCampaign = id => async dispatch => {
    try {

        await axios.delete(`/api/campaign/${id}`);

        dispatch({
            type: DELETE_CAMPAIGN,
            payload: id
        });

        dispatch(setAlert("Campaign Deleted", 'success', 2000));

    } catch (err) {
        dispatch({
            type: CAMPAIGN_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};