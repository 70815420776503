import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { clearCharacter } from '../../actions/character';

const Navbar = ({ auth: { isAuthenticated, loading }, logout, clearCharacter }) => {

    const logoutUser = () => {
        logout();
        clearCharacter();
    }

    const authLinks = (
        <ul>
            <li>
                <Link to="/wiki">Wiki</Link>
                <Link to="/dashboard">Dashboard</Link>
                <Link to="/" onClick={logoutUser} href="#!">Logout</Link>
            </li>
        </ul>
    );

    const guestLinks = (
            <ul>
                <li>
                    <Link to="/register">Register</Link>
                    <Link to="/login">Login</Link>
                </li>
            </ul>
    );

    return (
        <nav className="navbar bg-dark">
            <h1>
                <Link to="/">
                    <i className="fas fa-rocket"></i> KALEIDO
                </Link>
            </h1>
            {!loading && (
                <Fragment>{ isAuthenticated ? authLinks : guestLinks }</Fragment>
            )}
        </nav>
    )
}

Navbar.propTypes = {
    logout: PropTypes.func.isRequired,
    clearCharacter: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {logout, clearCharacter})(Navbar);
