import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import { saveCharacter } from '../../../actions/character';
import CreateSpeciesModal from '../modals/CreateSpeciesModal';

const CharCreationRace = ({
    currentState,
    saveCharacter,
    raceSelected,
    character: {loading, character}
}) => {

    const {
        languages,
        inventory
    } = currentState;

    var [selectedSpecies, updateSelectedSpecies] = useState(null)

    // Modal state and functions
    var [showSpeciesModal, toggleSpeciesModal] = useState(false);

    const openSpeciesModal = () => (e) => {

        updateSelectedSpecies(e.target.id);

        toggleSpeciesModal(true);
    }

    const closeModal = (e) => {
        toggleSpeciesModal(false);
    }
    
    var [atr1, updateAttr1] = useState('');
    var [atr2, updateAttr2] = useState(''); 
    var [selectedTab, tabUpdate] = useState('Human');

    useEffect(() => {
        if(!loading && character){
            raceSelected(1);
        }
    });

    const changeTab = e => {
        tabUpdate(e.target.innerHTML);
        updateAttr1('');
        updateAttr2('');
    }

    return (
        <Fragment>
            <div className="speciesListContainer">
                <p className="text-light2">Currently, there are 8 space-bound, sentient species in the Kaleido universe. 
                    While they all have strengths, weaknesses, and tendencies, it's recommended you choose a species more on backstory and personality than on in-game mechanics.
                </p>
                <h1 className="text-blue mary-1">Select a Species:</h1>
                <div className="speciesListItem pointer" id="Human" onClick={e => openSpeciesModal()(e)}>
                    <h1 id="Human">Human</h1>
                    <p id="Human">Highly adaptable and versatile, each human has their own personality and preferences. Some may consider not having a specialization to be a weakness.</p>
                </div>
                <div className="speciesListItem pointer" id="Cyborg" onClick={e => openSpeciesModal()(e)}>
                    <h1 id="Cyborg">Cyborg</h1>
                    <p id="Cyborg">Similar to Humans, they are very adaptable, though they tend to be more specialized. Their cybernetics are seen as unnatural by some other species.</p>
                </div>
                <div className="speciesListItem pointer" id="Quexi" onClick={e => openSpeciesModal()(e)}>
                    <h1 id="Quexi">Quexi</h1>
                    <p id="Quexi">The Quexi are highly skilled engineers and have been travelling space longer than any of the other species.</p>
                </div>
                <div className="speciesListItem pointer" id="Aerzha" onClick={e => openSpeciesModal()(e)}>
                    <h1 id="Aerzha">Aerzha</h1>
                    <p id="Aerzha">Though short in stature, there are no better diplomats in the universe. And should words fail, they have other ways of exerting their will.</p>
                </div>
                <div className="speciesListItem pointer" id="Muhmin" onClick={e => openSpeciesModal()(e)}>
                    <h1 id="Muhmin">Muhmin</h1>
                    <p id="Muhmin">These large, feline creatures are masters of combat. Whether its using guns, melee weapons, or even just their raw strength, you'll find no better warrior.</p>
                </div>
                <div className="speciesListItem pointer" id="Ts'Tsen" onClick={e => openSpeciesModal()(e)}>
                    <h1 id="Ts'tsen">Ts'Tsen</h1>
                    <p id="Ts'tsen">The Ts'tsen come from a society full of deception and crony capitalism. They are intelligent and shrewd, and incredibly capable assassains.</p>
                </div>
                <div className="speciesListItem pointer" id="Koz'aid" onClick={e => openSpeciesModal()(e)}>
                    <h1 id="Koz'aid">Koz'aid</h1>
                    <p id="Koz'aid">The large Koz'aid grow a carapace that is as durable as any heavy armor. They are the only species that are space-bound against their will, but their past makes them resilient.</p>
                </div>
                <div className="speciesListItem pointer" id="Veith" onClick={e => openSpeciesModal()(e)}>
                    <h1 id="Veith">Veith</h1>
                    <p id="Veith">This semi-aquatic species tries to live life to the max. More than any other species, they have a knack for using stims to bend the laws of nature.</p>
                </div>
                <Modal
                    isOpen={showSpeciesModal}
                    onRequestClose={closeModal}
                    className="lvlUpModal"
                    overlayClassName="lvlUpOverlay"
                >
                    <CreateSpeciesModal closeModal={closeModal} species={selectedSpecies} currentState={currentState}/>
                </Modal>
            </div>
        
        </Fragment>
    );
};

CharCreationRace.propTypes = {
    saveCharacter: PropTypes.func.isRequired,
    currentState: PropTypes.object.isRequired,
    raceSelected: PropTypes.func.isRequired,
    character: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    character: state.character
});

export default connect(mapStateToProps, {saveCharacter})(CharCreationRace);
