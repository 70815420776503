import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteCharacter } from '../../actions/character';
import { skills } from '../../resources/skills';

import Modal from 'react-modal';

const DashCharItem = ({
    character: {
        _id,
        createdOn,
        name,
        race,
        level,
        currentHP,
        baseHP,
        currentKIN,
        baseKIN,
        currentDIS,
        baseDIS,
        mainHand,
        armorLevel,
        assaultWeaponLevel,
        astrobiologyLevel,
        athleticsLevel,
        bladeLevel,
        bluntLevel,
        energyLevel,
        espionageLevel,
        explosivesLevel,
        fluidLevel,
        gravLevel,
        longRangeLevel,
        negotiationLevel,
        observationLevel,
        pistolLevel,
        psycheLevel,
        techLevel,
        unarmedLevel,
        vehiclesLevel,
        waveLevel,
        inventory
    },
    deleteCharacter
}) => {

    var [deleteModal, toggleDeleteModal] = useState(true);

    const openDeleteModal = (e) => {
        toggleDeleteModal(true);
    }

    const closeDeleteModal = (e) => {
        toggleDeleteModal(false);
    }

    const levelArray = [
        armorLevel,
        assaultWeaponLevel,
        astrobiologyLevel,
        athleticsLevel,
        bladeLevel,
        bluntLevel,
        energyLevel,
        espionageLevel,
        explosivesLevel,
        fluidLevel,
        gravLevel,
        longRangeLevel,
        negotiationLevel,
        observationLevel,
        pistolLevel,
        psycheLevel,
        techLevel,
        unarmedLevel,
        vehiclesLevel,
        waveLevel
    ];

    const formattedDate = Intl.DateTimeFormat().format(new Date(createdOn));

    var charMainHand = inventory.find(x => x.equipped === "Main Hand");
    
    const getTopSkillIndexes = (arr, num) => {
        var outp = [];
        for (var i = 0; i < arr.length; i++) {
            outp.push(i); // add index to output array
            if (outp.length > num) {
                outp.sort(function(a, b) { return arr[b] - arr[a]; }); // descending sort the output array
                outp.pop(); // remove the last index (index of smallest element in output array)
            }
        }
        return outp;
    }

    var topIndexes = getTopSkillIndexes(levelArray, 3);

    const confirmDelete = (e) => {
        var conf = window.confirm('Are you sure you want to delete ' + name);
            if (conf) {
                deleteCharacter(_id);
            } else return;
        
        //closeDeleteModal();
    }

    return (
        <div className="dash-char-item">
            <div className="dash-char-name text-orange">{name}</div>
            <div className="char-item-top">
                <div className="dash-info">
                    <div className="char-level text-light1">Level {level} {race}</div>
                    <div className="char-created text-med1">Created on: {formattedDate}</div>
                </div>
                <div className = "dash-skill-info hide-sm">
                    <h3>Top Skills</h3>
                    <div className="dash-skill-item">
                        <div>{skills[topIndexes[0]].baseSkill.name}</div>
                        <div>{levelArray[topIndexes[0]]}</div>
                    </div>
                    <div className="dash-skill-item">
                        <div>{skills[topIndexes[1]].baseSkill.name}</div>
                        <div>{levelArray[topIndexes[1]]}</div>
                    </div>
                    <div className="dash-skill-item">
                        <div>{skills[topIndexes[2]].baseSkill.name}</div>
                        <div>{levelArray[topIndexes[2]]}</div>
                    </div>
                </div>
                <div className="dash-char-panel3">
                    <div className="char-item-health">
                        <div className="dash-health marx-1">
                            <h3 className="text-blue">HP</h3>
                            <div className="health-numbers">
                                <div className="text-light1">{currentHP}</div>/<div className="text-orange">{baseHP}</div>
                            </div>
                        </div>
                        <div className="dash-health marx-1">
                            <h3 className="text-blue">DIS</h3>
                            <div className="health-numbers">
                                <div className="text-light1">{currentDIS}</div>/<div className="text-med2">{baseDIS}</div>
                            </div>
                        </div>
                        <div className="dash-health marx-1">
                            <h3 className="text-blue">KIN</h3>
                            <div className="health-numbers">
                                <div className="text-light1">{currentKIN}</div>/<div className="text-light2">{baseKIN}</div>
                            </div>
                        </div>
                    </div>
                    <div className="char-item-main-hand hide-sm">
                        <h3>Main Hand</h3>
                        <div>
                            <p className="weap-name">{charMainHand === undefined ? "" : charMainHand.name}</p>
                            <p className="weap-dd">{charMainHand === undefined ? "" : charMainHand.dd}</p>
                        </div>
                    </div>
                </div>
                <div className="dash-char-buttons">
                    <Link to={`/character/edit/${_id}`} className="char-link">EDIT</Link>
                    <button className="delete-button" onClick={e => confirmDelete(e)}>DELETE</button>
                    <Modal
                        isOpen={deleteModal.open}
                        onRequestClose={closeDeleteModal}
                        className="offhandModal"
                        overlayClassName="offhandOverlay"
                    >
                        <h1>Are you sure you want to delete {name}?</h1>
                        <div>
                            <button onClick={e => confirmDelete(e)}>Yes</button>
                            <button onClick={closeDeleteModal}>No</button>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

DashCharItem.propTypes = {
    character: PropTypes.object.isRequired,
    deleteCharacter: PropTypes.func.isRequired
}

export default connect(null, {deleteCharacter})(DashCharItem);
