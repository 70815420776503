import React, {Fragment, useState, useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { resetFindUser, changePassword } from '../../actions/auth';
import PropTypes from 'prop-types'

// Eventually, we will want to make sure the incoming email address is for the correct user
// Maybe also make sure the new password isn't the same as the old one?

const PasswordReset = ({ 
    setAlert, 
    resetFindUser,
    changePassword,
    match,
    isAuthenticated
}) => {

    useEffect(() => {
        resetFindUser(match.params.token);
    }, [resetFindUser, match.params.token]);

    const [formData, setFormData] = useState({
        password: '',
        password2: ''
    }); 

    const { password, password2 } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        e.preventDefault();

        if(password !== password2) {
            setAlert('Passwords do not match', 'danger');
        } else {
            changePassword(match.params.token, password);
        }
    }

    if(isAuthenticated){
        return <Navigate to='/dashboard' />
    }

    return <Fragment>
            <h1 className="large text-light2">
                Change your password
            </h1>
            <form className="form" onSubmit={e => onSubmit(e)}>
                <div className="form-group">
                    <input 
                        type="password" 
                        placeholder="Password" 
                        name='password'
                        value={password}
                        onChange={e => onChange(e)}
                        minLength="6" />
                </div>
                <small className="form-text">Minimum 6 characters. Use at least one uppercase letter and one number.</small>
                <div className="form-group">
                    <input 
                        type="password" 
                        placeholder="Confirm Password" 
                        name='password2'
                        value={password2}
                        onChange={e => onChange(e)}
                        minLength="6" />
                </div>
                <input type="submit" value="ChangePassword" className="btn btn-primary" />
            </form>
        </Fragment>
    ;
};

PasswordReset.propTypes = {
    setAlert: PropTypes.func.isRequired,
    resetFindUser: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert, resetFindUser, changePassword })(PasswordReset);