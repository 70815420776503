import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentCampaign, updateCampaign } from '../../actions/campaign';

import Modal from 'react-modal';

const EditCampaignAreas = ({
    campaign: {campaign},
    match,
    history,
    getCurrentCampaign,
    updateCampaign
}) => {

    useEffect(() => {
        getCurrentCampaign(match.params.id);
    }, [getCurrentCampaign, match.params.id]);

    const createAreaList = () => {

        var areaDisplays = campaign.areas.map((a, index) =>
            <div key={index} id={index} className="areaItem mary-1">
                <h2 className="text-primary marx-1">{a.areaName}</h2>
                <p className="marx-1">{a.areaType}</p>
                <p className="marx-1">{a.areaDescription}</p>
                <Link to={`/campaign/edit/areas/editMap/${a._id}`} currentArea={a} className="marx-1">EDIT MAP</Link>
            </div>
        );

        return areaDisplays;
    }

    var [newAreaModal, toggleNewAreaModal] = useState(false);
    var [newMapModal, toggleNewMapModal] = useState(false);

    var [fileInput, setFileInput] = useState('');
    var [imgPreview, setImgPreview] = useState('');

    const closeModals = () => {
        toggleNewAreaModal(false);
        toggleNewMapModal(false);
    }

    const openNewAreaModal = () => {
        toggleNewAreaModal(true);
    }

    const openNewMapModal = () => {
        toggleNewAreaModal(false);
        toggleNewMapModal(true);
    }

    const updateFileInput = (e) => {
        const file = e.target.files[0];

        if ( !(/^image\/(png|jpe?g)$/).test(file.type)){
            alert('Unsupported file type');
            e.target.value = '';
            setImgPreview('');
            setFileInput('');
            return;
        }

        const reader = new FileReader();
        
        reader.readAsDataURL(file);

        reader.onloadend = () => {

            var image = new Image();
            image.src = reader.result;

            image.onload = () => {

                if(image.height < 400 || image.width < 600){
                    alert('The selected image is too small');
                    e.target.value = '';
                    setImgPreview('');
                    setFileInput('');
                    return;
                }
    
                if(image.width/image.height < 1){
                    alert('Selected image cannot be taller than it is wide.');
                    e.target.value = '';
                    setImgPreview('');
                    setFileInput('');
                    return;
                }
    
                setImgPreview(reader.result);
                setFileInput(file);
            }
        };
    }

    const onSubmit = e => {
        e.preventDefault();

        if(!fileInput){
            alert("You haven't uploaded an image yet!")
            return;
        }

        const newArea = {
            areaName: "Test",
            areaDescription: "A test map for dev purposes",
            areaType: "Map",
            bgImage: fileInput,
            areaOrder: campaign.areas.length + 1
        }

        const formData = new FormData();
        formData.append('areaName', newArea.areaName);
        formData.append('areaType', newArea.areaType);
        formData.append('areaDescription', newArea.areaDescription);
        formData.append('bgImage', newArea.bgImage);
        formData.append('areaOrder', newArea.areaOrder);
        
        updateCampaign(match.params.id, formData);
        
        closeModals();
    }

    return !campaign ? (
        <Fragment></Fragment>
    ) : (
        <Fragment>
            <div className="editCampaignContainer">
                <button onClick={openNewAreaModal}>Add New Area</button>
                <div className="areaList">
                    {createAreaList()}
                </div>
                <Modal
                    isOpen={newAreaModal}
                    onRequestClose={closeModals}
                    className="campaignModal"
                    overlayClassName="campaignOverlay"
                >
                    <button>New Image</button>
                    <button onClick={openNewMapModal}>New Map</button>
                </Modal>
                <Modal
                    isOpen={newMapModal}
                    onRequestClose={closeModals}
                    className="campaignModal"
                    overlayClassName="campaignOverlay"
                >
                    <form className="form" name="map" onSubmit={onSubmit}>
                        <h1>NEW MAP</h1>
                        <div className="form-group">
                            <input placeholder="Name" type="text" name="name" className="" />
                        </div>
                        <div className="form-group">
                            <input 
                                type="file" 
                                name="bgImage"
                                onChange={e => updateFileInput(e)} 
                                className="" 
                            />
                        </div>
                        {imgPreview && (
                            <img src={imgPreview} alt="preview" style={{width: '600px', margin: '1rem 0rem'}} />
                        )}
                        <input type="submit" value="CREATE AREA" className="btn btn-primary my-1" />
                    </form>
                </Modal>
            </div>
        </Fragment>
    )
}

EditCampaignAreas.propTypes = {
    getCurrentCampaign: PropTypes.func.isRequired,
    updateCampaign: PropTypes.func.isRequired,
    campaign: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    campaign: state.campaign
});

export default connect(mapStateToProps, {getCurrentCampaign, updateCampaign})(EditCampaignAreas);
