import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { skills } from '../../resources/skills';
import { createBrowserHistory } from 'history';

import Modal from 'react-modal';

//Actions
import {
    addPrebuiltCharacter
} from '../../actions/character';

const PrebuiltCharItem = ({
    character,
    addPrebuiltCharacter
}) => {

    const history = createBrowserHistory();

    var [confirmModal, toggleConfirmModal] = useState(false);

    const openConfirmModal = (e) => {
        toggleConfirmModal(true);
    }

    const closeModals = (e) => {
        toggleConfirmModal(false);
    }

    const {
        _id,
        name,
        race,
        level,
        build,
        background,
        personality,
        currentHP,
        baseHP,
        currentKIN,
        baseKIN,
        currentDIS,
        baseDIS,
        mainHand,
        armorLevel,
        assaultWeaponLevel,
        astrobiologyLevel,
        athleticsLevel,
        bladeLevel,
        bluntLevel,
        energyLevel,
        espionageLevel,
        explosivesLevel,
        fluidLevel,
        gravLevel,
        longRangeLevel,
        negotiationLevel,
        observationLevel,
        pistolLevel,
        psycheLevel,
        techLevel,
        unarmedLevel,
        vehiclesLevel,
        waveLevel,
        inventory
    } = character;

    const levelArray = [
        armorLevel,
        assaultWeaponLevel,
        astrobiologyLevel,
        athleticsLevel,
        bladeLevel,
        bluntLevel,
        energyLevel,
        espionageLevel,
        explosivesLevel,
        fluidLevel,
        gravLevel,
        longRangeLevel,
        negotiationLevel,
        observationLevel,
        pistolLevel,
        psycheLevel,
        techLevel,
        unarmedLevel,
        vehiclesLevel,
        waveLevel
    ];

    var charMainHand = inventory.find(x => x.equipped === "Main Hand");

    const getTopSkillIndexes = (arr, num) => {
        var outp = [];
        for (var i = 0; i < arr.length; i++) {
            outp.push(i); // add index to output array
            if (outp.length > num) {
                outp.sort(function(a, b) { return arr[b] - arr[a]; }); // descending sort the output array
                outp.pop(); // remove the last index (index of smallest element in output array)
            }
        }
        return outp;
    }

    var topIndexes = getTopSkillIndexes(levelArray, 3);

    const submitPrebuilt = (e) => {
        openConfirmModal();
        addPrebuiltCharacter(character, history);
    }

    return (
        <div className="dash-char-item">
            <div className="char-item-top">
                <div className="dash-info">
                    <div className="char-name text-orange">{name}</div>
                    <div className="char-level text-light1">Level {level} {race} {build}</div>
                </div>
                <div className = "dash-skill-info">
                    <h3>Top Skills</h3>
                    <div className="dash-skill-item">
                        <div>{skills[topIndexes[0]].baseSkill.name}</div>
                        <div>{levelArray[topIndexes[0]]}</div>
                    </div>
                    <div className="dash-skill-item">
                        <div>{skills[topIndexes[1]].baseSkill.name}</div>
                        <div>{levelArray[topIndexes[1]]}</div>
                    </div>
                    <div className="dash-skill-item">
                        <div>{skills[topIndexes[2]].baseSkill.name}</div>
                        <div>{levelArray[topIndexes[2]]}</div>
                    </div>
                </div>
                <div className="dash-char-panel3">
                    <div className="char-item-health">
                        <div className="dash-health marx-1">
                            <h3 className="text-blue">HP</h3>
                            <div className="health-numbers">
                                <div className="text-light1">{currentHP}</div>/<div className="text-orange">{baseHP}</div>
                            </div>
                        </div>
                        <div className="dash-health marx-1">
                            <h3 className="text-blue">DIS</h3>
                            <div className="health-numbers">
                                <div className="text-light1">{currentDIS}</div>/<div className="text-med2">{baseDIS}</div>
                            </div>
                        </div>
                        <div className="dash-health marx-1">
                            <h3 className="text-blue">KIN</h3>
                            <div className="health-numbers">
                                <div className="text-light1">{currentKIN}</div>/<div className="text-light2">{baseKIN}</div>
                            </div>
                        </div>
                    </div>
                    <div className="char-item-main-hand">
                        <h3>Main Hand</h3>
                        <div>
                            <p className="weap-name">{charMainHand === undefined ? "" : charMainHand.name}</p>
                        </div>
                    </div>
                </div>
                <div className="dash-char-buttons">
                    <div onClick={e=>submitPrebuilt(e)} className="char-link bg-orange">SELECT</div>
                </div>
                <Modal
                    isOpen={confirmModal.open}
                    onRequestClose={closeModals}
                    className="offhandModal"
                    overlayClassName="offhandOverlay"
                >
                    <h1>`{name} has been added to your character list.</h1>
                    <div>
                        <Link to={`/dashboard`}>Return</Link>
                    </div>
                </Modal>
            </div>
            <div className="char-item-bottom">
                <div>
                    <h3>Personality</h3>
                    <div>{personality}</div>
                </div>
            </div>
        </div>
    )
}

PrebuiltCharItem.propTypes = {
    character: PropTypes.object.isRequired,
    addPrebuiltCharacter: PropTypes.func.isRequired
}

export default connect(null, {addPrebuiltCharacter})(PrebuiltCharItem);
