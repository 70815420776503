import React, { Fragment, useState} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { populateActions } from '../../../utils/calculations';
import { skills } from '../../../resources/skills';

import Modal from 'react-modal';
import AttackModal from '../modals/AttackModal';

const CharEditActions = ({
    character: {
        character
    }
}) => {

    const {
        armorLevel,
        armorExpertise,
        assaultWeaponLevel,
        assaultWeaponExpertise,
        astrobiologyLevel,
        astrobiologyExpertise,
        athleticsLevel,
        athleticsExpertise,
        bladeLevel,
        bladeExpertise,
        bluntLevel,
        bluntExpertise,
        energyLevel,
        energyExpertise,
        espionageLevel,
        espionageExpertise,
        explosivesLevel,
        explosivesExpertise,
        fluidLevel,
        fluidExpertise,
        gravLevel,
        gravExpertise,
        longRangeLevel,
        longRangeExpertise,
        negotiationLevel,
        negotiationExpertise,
        observationLevel,
        observationExpertise,
        pistolLevel,
        pistolExpertise,
        psycheLevel,
        psycheExpertise,
        techLevel,
        techExpertise,
        unarmedLevel,
        unarmedExpertise,
        vehiclesLevel,
        vehiclesExpertise,
        waveLevel,
        waveExpertise,
        inventory
    } = character;

    let charPropArray = Object.keys(character);
    charPropArray = charPropArray.slice(charPropArray.length - 60);

    const levelArray = [
        armorLevel,
        assaultWeaponLevel,
        astrobiologyLevel,
        athleticsLevel,
        bladeLevel,
        bluntLevel,
        energyLevel,
        espionageLevel,
        explosivesLevel,
        fluidLevel,
        gravLevel,
        longRangeLevel,
        negotiationLevel,
        observationLevel,
        pistolLevel,
        psycheLevel,
        techLevel,
        unarmedLevel,
        vehiclesLevel,
        waveLevel,
    ];

    const expertiseArray = [
        armorExpertise,
        assaultWeaponExpertise,
        astrobiologyExpertise,
        athleticsExpertise,
        bladeExpertise,
        bluntExpertise,
        energyExpertise,
        espionageExpertise,
        explosivesExpertise,
        fluidExpertise,
        gravExpertise,
        longRangeExpertise,
        negotiationExpertise,
        observationExpertise,
        pistolExpertise,
        psycheExpertise,
        techExpertise,
        unarmedExpertise,
        vehiclesExpertise,
        waveExpertise
    ];

    var [showMainAttackModal, toggleMainAttackModal] = useState(false);
    var [showOffhandAttackModal, toggleOffhandAttackModal] = useState(false);

    const openMainAttackModal = (e) => {
        toggleMainAttackModal(true);
    }

    const openOffhandAttackModal = (e) => {
        toggleOffhandAttackModal(true);
    }

    const closeModals = (e) => {
        toggleMainAttackModal(false);
        toggleOffhandAttackModal(false);
    }

    const allActions = populateActions(skills, levelArray, expertiseArray);

    var charMainHand;
    var charOffHand;
    var mainHandAttackType;
    var offHandAttackType;

    if (inventory != undefined) {
        charMainHand = inventory.find(x => x.equipped === "Main Hand");
        charOffHand = inventory.find(x => x.equipped === "Off Hand");

        //If we have nothing equipped in main hand, only option is to perform an unarmed attack with main hand
        if(charMainHand === undefined){
            mainHandAttackType = "Unarmed";

            //We may have something in our offhand which could also be used for an attack at this point
            if(charOffHand !== undefined){
                //We have something equipped in our offhand, if it's blade or blunt do melee attack, otherwise do ranged attack
                if(charOffHand.type === "Blade" || charOffHand.type === "Blunt"){
                    offHandAttackType = "Melee";
                } else {
                    offHandAttackType = "Ranged";
                }
            }
        } else {

            //We have something equipped in main hand. If it is blade or blunt, do melee attack, otherwise do ranged
            if(charMainHand.type === "Blade" || charMainHand.type === "Blunt"){
                mainHandAttackType = "Melee";
            } else {
                mainHandAttackType = "Ranged";
            }

            //We may have the option to attack with offhand instead. If nothing is equipped in offhand AND if we're not wielding a two-handed weapon, we can do an off-hand unarmed attack
            if(charOffHand === undefined){
                
                if(charMainHand.type !== "Assault Weapon" && charMainHand.type !== "Long Range"){
                    // *** Need to check two-handed melee weapons here eventually ***
                    offHandAttackType = "Unarmed";
                }
            } else {
                //We have something equipped in our offhand, if it's blade or blunt do melee attack, otherwise do ranged attack
                if(charOffHand.type === "Blade" || charOffHand.type === "Blunt"){
                    offHandAttackType = "Melee";
                } else {
                    offHandAttackType = "Ranged";
                }
            }
        }
    }

    return ( 
        <Fragment>
            <div className="char-sheet-actions-panel">
                <Modal
                    isOpen={showMainAttackModal}
                    onRequestClose={closeModals}
                    className="attackModal"
                    overlayClassName="attackOverlay"
                >
                    <AttackModal closeModal={closeModals} attackType={mainHandAttackType} attackSource="Main Hand" />
                </Modal>
                <Modal
                    isOpen={showOffhandAttackModal}
                    onRequestClose={closeModals}
                    className="attackModal"
                    overlayClassName="attackOverlay"
                >
                    <AttackModal closeModal={closeModals} attackType={offHandAttackType} attackSource="Off Hand" />
                </Modal>
                <div className="attackModalButtonContainer">
                    {mainHandAttackType === "Melee" ? 
                        <div className="attackModalButton" onClick={openMainAttackModal}>MELEE ATTACK</div> 
                        : mainHandAttackType === "Ranged" ?
                        <div className="attackModalButton" onClick={openMainAttackModal}>RANGED ATTACK</div>
                        : mainHandAttackType === "Unarmed" &&
                        <div className="attackModalButton" onClick={openMainAttackModal}>UNARMED ATTACK</div> 
                    }
                    {offHandAttackType === "Melee" ? 
                        <div className="attackModalButton" onClick={openOffhandAttackModal}>MELEE ATTACK</div> 
                        : offHandAttackType === "Ranged" ?
                        <div className="attackModalButton" onClick={openOffhandAttackModal}>RANGED ATTACK</div>
                        : offHandAttackType === "Unarmed" &&
                        <div className="attackModalButton" onClick={openOffhandAttackModal}>UNARMED ATTACK</div> 
                    }
                </div>
                
                <div className="hand">Main Hand</div>
                {charMainHand !== undefined &&
                    <div className="equipContainer">
                        <h2>{charMainHand.name}</h2>
                        <div>
                            <p>Num Dice: <span>{charMainHand.numDice}</span></p>
                            <p>Dice Size: <span>d{charMainHand.diceSize}</span></p>
                        </div>
                        <div>
                            {(charMainHand.type !== "Blade" && charMainHand.type !== "Blunt") &&
                                <div>
                                    <p>Range: <span>{charMainHand.range}m</span></p>
                                    <p>Mag Size: <span>{charMainHand.magSize}</span></p>
                                </div>
                            }
                        </div>
                    </div>
                }
                <div className="hand">Off Hand</div>
                {charOffHand !== undefined && 
                    <div className="equipContainer">
                        <h2>{charOffHand.name}</h2>
                        <div>
                            <p>Num Dice: {charOffHand.numDice}</p>
                            <p>Dice Size: d{charOffHand.diceSize}</p>
                            {(charOffHand.type !== "Blade" && charOffHand.type !== "Blunt") &&
                                <div>
                                    <p>Range: {charOffHand.range}m</p>
                                    <p>Mag Size: {charOffHand.magSize}</p>
                                </div>
                            }
                            
                            <p>Mod Slots: {charOffHand.modSlots}</p>
                        </div>
                    </div>
                }
                <h1>MAJOR</h1>
                {allActions.map((action) => action.type === "Major" && (
                    <div className="action-container" key={action.name}>
                        <p className="action-name">{action.name}</p>
                        <p className="action-desc">{action.desc}</p>
                    </div>
                ))}
                <h1>MINOR</h1>
                {allActions.map((action) => action.type === "Minor" && (
                    <div className="action-container" key={action.name}>
                        <p className="action-name">{action.name}</p>
                        <p className="action-desc">{action.desc}</p>
                    </div>
                ))}
                
                <h1>MOVEMENT</h1>
                {allActions.map((action) => action.type === "Movement" && (
                    <div className="action-container" key={action.name}>
                        <p className="action-name">{action.name}</p>
                        <p className="action-desc">{action.desc}</p>
                    </div>
                ))}
                <h1>NON-COMBAT</h1>
                {allActions.map((action) => action.type === "Non-Combat" && (
                    <div className="action-container" key={action.name}>
                        <p className="action-name">{action.name}</p>
                        <p className="action-desc">{action.desc}</p>
                    </div>
                ))}
                <h1>BONUS</h1>
                {allActions.map((action) => action.type === "Bonus" && (
                    <div className="action-container" key={action.name}>
                        <p className="action-name">{action.name}</p>
                        <p className="action-desc">{action.desc}</p>
                    </div>
                ))}
            </div>
        </Fragment>
    );
};

CharEditActions.propTypes = {
    character: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    character: state.character
});

export default connect(mapStateToProps)(CharEditActions);
