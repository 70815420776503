import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveCharacter } from '../../../actions/character';
import { calculateSkillModifiers, calculateSingleSkillMod } from '../../../utils/calculations';
import { skills } from '../../../resources/skills';

const CharEditSkills = ({
    character: {character},
    saveCharacter
}) => {

    const {
        STR,
        DEX,
        CON,
        INT,
        WIS,
        CHA,
        skillPoints,
        armorLevel,
        armorExpertise,
        armorMod,
        assaultWeaponLevel,
        assaultWeaponExpertise,
        assaultWeaponMod,
        astrobiologyLevel,
        astrobiologyExpertise,
        astrobiologyMod,
        athleticsLevel,
        athleticsExpertise,
        athleticsMod,
        bladeLevel,
        bladeExpertise,
        bladeMod,
        bluntLevel,
        bluntExpertise,
        bluntMod,
        energyLevel,
        energyExpertise,
        energyMod,
        espionageLevel,
        espionageExpertise,
        espionageMod,
        explosivesLevel,
        explosivesExpertise,
        explosivesMod,
        fluidLevel,
        fluidExpertise,
        fluidMod,
        gravLevel,
        gravExpertise,
        gravMod,
        longRangeLevel,
        longRangeExpertise,
        longRangeMod,
        negotiationLevel,
        negotiationExpertise,
        negotiationMod,
        observationLevel,
        observationExpertise,
        observationMod,
        pistolLevel,
        pistolExpertise,
        pistolMod,
        psycheLevel,
        psycheExpertise,
        psycheMod,
        techLevel,
        techExpertise,
        techMod,
        unarmedLevel,
        unarmedExpertise,
        unarmedMod,
        vehiclesLevel,
        vehiclesExpertise,
        vehiclesMod,
        waveLevel,
        waveExpertise,
        waveMod
    } = character;

    let charPropArray = Object.keys(character);
    charPropArray = charPropArray.slice(charPropArray.indexOf('armorLevel'));
    charPropArray = charPropArray.splice(0,charPropArray.indexOf('waveExpertise')+1);

    var armorPenalty = 0;

    if(character.inventory != 'undefined'){
        if(character.armorLevel < 30 || character.armorExpertise === 'Light Armor'){
            armorPenalty = character.inventory.filter(x => x.subtype==="Heavy Armor" && (x.equipped==="Body" || x.equipped==="Feet")).length;
        }
    }

    const levelArray = [
        armorLevel,
        assaultWeaponLevel,
        astrobiologyLevel,
        athleticsLevel,
        bladeLevel,
        bluntLevel,
        energyLevel,
        espionageLevel,
        explosivesLevel,
        fluidLevel,
        gravLevel,
        longRangeLevel,
        negotiationLevel,
        observationLevel,
        pistolLevel,
        psycheLevel,
        techLevel,
        unarmedLevel,
        vehiclesLevel,
        waveLevel
    ];

    const expertiseArray = [
        armorExpertise,
        assaultWeaponExpertise,
        astrobiologyExpertise,
        athleticsExpertise,
        bladeExpertise,
        bluntExpertise,
        energyExpertise,
        espionageExpertise,
        explosivesExpertise,
        fluidExpertise,
        gravExpertise,
        longRangeExpertise,
        negotiationExpertise,
        observationExpertise,
        pistolExpertise,
        psycheExpertise,
        techExpertise,
        unarmedExpertise,
        vehiclesExpertise,
        waveExpertise
    ];

    const modArray = [
        armorMod,
        assaultWeaponMod,
        astrobiologyMod,
        athleticsMod,
        bladeMod,
        bluntMod,
        energyMod,
        espionageMod,
        explosivesMod,
        fluidMod,
        gravMod,
        longRangeMod,
        negotiationMod,
        observationMod,
        pistolMod,
        psycheMod,
        techMod,
        unarmedMod,
        vehiclesMod,
        waveMod
    ];

    const createSkillTable = skills => {

        const skillRows = skills.map((s, index) => 
            <div key={index} id={index} className='skill-row'>
                <div className="skill-subcontainer">
                    <div className="skill-name">{s.baseSkill.name}</div>
                    <div className="skill-mod">
                        <div name={charPropArray[1 + 3*index]}>{modArray[index]}</div>
                    </div>
                    <div className="skill-att">{s.baseSkill.main_attr}</div>
                    <div className="skill-level">
                        <button onClick={levelArray[index] > 0 && decreaseSkillLvl}>-</button>
                        <button onClick={levelArray[index] < 50 && increaseSkillLvl}>+</button>
                        <div className="skill-level-value">{levelArray[index]}</div>
                    </div>
                    <div className="expertise_container">
                        <select name={charPropArray[2 + index*3]} className="expertise_select" defaultValue={expertiseArray[index]} onChange={onChange}>
                            <option>{s.expertise1.name}</option>
                            <option>{s.expertise2.name}</option>
                        </select>   
                    </div>
                </div>
                <div className="skill-subcontainer">
                    <div className="skill-level-chart">
                        <div data-for={`basePerkInfo${s.baseSkill.name}`} data-tip='0' className={levelArray[index] > 0 ? "perk filled" : "perk"}></div>
                        <div className={levelArray[index] > 1 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 2 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 3 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div data-for={`basePerkInfo${s.baseSkill.name}`} data-tip='1' className={levelArray[index] > 4 ? "perk filled" : "perk"}></div>
                        <div className={levelArray[index] > 5 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 6 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 7 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 8 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div data-for={`basePerkInfo${s.baseSkill.name}`} data-tip='2' className={levelArray[index] > 9 ? "perk filled" : "perk"}></div>
                        <div className={levelArray[index] > 10 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 11 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 12 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 13 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div data-for={`basePerkInfo${s.baseSkill.name}`} data-tip='3' className={levelArray[index] > 14 ? "perk filled" : "perk"}></div>
                        <div className={levelArray[index] > 15 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 16 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 17 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 18 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div data-for={`basePerkInfo${s.baseSkill.name}`} data-tip='4' className={levelArray[index] > 19 ? "perk filled" : "perk"}></div>
                        <div className={levelArray[index] > 20 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 21 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 22 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 23 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div data-for={`expPerkInfo${s.baseSkill.name}`} data-tip='0' className={levelArray[index] > 24 ? "perk filled" : "perk"}></div>
                        <div className={levelArray[index] > 25 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 26 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 27 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 28 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div data-for={`expPerkInfo${s.baseSkill.name}`} data-tip='1' className={levelArray[index] > 29 ? "perk filled" : "perk"}></div>
                        <div className={levelArray[index] > 30 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 31 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 32 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 33 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div data-for={`expPerkInfo${s.baseSkill.name}`} data-tip='2' className={levelArray[index] > 34 ? "perk filled" : "perk"}></div>
                        <div className={levelArray[index] > 35 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 36 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 37 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 38 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div data-for={`expPerkInfo${s.baseSkill.name}`} data-tip='3' className={levelArray[index] > 39 ? "perk filled" : "perk"}></div>
                        <div className={levelArray[index] > 40 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 41 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 42 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 43 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div data-for={`expPerkInfo${s.baseSkill.name}`} data-tip='4' className={levelArray[index] > 44 ? "perk filled" : "perk"}></div>
                        <div className={levelArray[index] > 45 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 46 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 47 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div className={levelArray[index] > 48 ? "hide-med skill-mark filled" : "hide-med skill-mark"}></div>
                        <div data-for={`expPerkInfo${s.baseSkill.name}`} data-tip='5' className={levelArray[index] > 49 ? "perk filled" : "perk"}></div>
                        <ReactTooltip 
                            id={`basePerkInfo${s.baseSkill.name}`}
                            className='skillTooltip'
                            place='right'
                            effect='solid'
                            getContent={(dataTip) => 
                                <Fragment>
                                    <p>Level: {dataTip && s.baseSkill.perks[dataTip].lvl_req}</p>
                                    <h1>{dataTip && s.baseSkill.perks[dataTip].perk_name}</h1>
                                    <div><p>Perk Description</p>{dataTip && s.baseSkill.perks[dataTip].pk_desc}</div>
                                    <div><p>Action Description</p>{dataTip && s.baseSkill.perks[dataTip].action_desc && s.baseSkill.perks[dataTip].action_desc}</div>
                                    <div><p>Action Type</p>{dataTip && s.baseSkill.perks[dataTip].action_type && s.baseSkill.perks[dataTip].action_type}</div>
                                </Fragment>
                                
                            } 
                        />
                        <ReactTooltip 
                            id={`expPerkInfo${s.baseSkill.name}`}
                            className='skillTooltip'
                            place='right'
                            effect='solid'
                            getContent={(dataTip) =>
                                <Fragment>
                                    <p>Level: {dataTip ? (expertiseArray[index] === s.expertise1.name ? s.expertise1.perks[dataTip].lvl_req : s.expertise2.perks[dataTip].lvl_req) : ''}</p>
                                    <h1>{dataTip ? (expertiseArray[index] === s.expertise1.name ? s.expertise1.perks[dataTip].perk_name : s.expertise2.perks[dataTip].perk_name) : ''}</h1>
                                    <div><p>Perk Description</p>{dataTip ? (expertiseArray[index] === s.expertise1.name ? s.expertise1.perks[dataTip].pk_desc : s.expertise2.perks[dataTip].pk_desc) : ''}</div>
                                    <div>
                                        <p>Action Description</p>{ dataTip ? (expertiseArray[index] === s.expertise1.name ? s.expertise1.perks[dataTip].action_desc : s.expertise2.perks[dataTip].action_desc) : ''}
                                    </div>
                                    <div><p>Action Type</p>{dataTip ? (expertiseArray[index] === s.expertise1.name ? s.expertise1.perks[dataTip].action_type : s.expertise2.perks[dataTip].action_type) : ''}</div>
                                </Fragment>
                            } 
                        />
                    </div>
                </div>
            </div>
        )

        return skillRows;
    };

    const increaseSkillLvl = event => {

        let index = event.target.closest('.skill-row').id;

        if(skillPoints > 0){
            var newLvl = levelArray[index] + 1;
            var newMod = calculateSingleSkillMod(skills[index] ,newLvl, { "STR": STR, "DEX": DEX, "CON": CON, "INT": INT, "WIS": WIS, "CHA": CHA }, expertiseArray[index], armorPenalty);
            
            saveCharacter({
                ...character,
                [charPropArray[0 + 3*index]]: newLvl,
                [charPropArray[1 + 3*index]]: newMod,
                'skillPoints': skillPoints -1
            });
        };
    }

    const decreaseSkillLvl = event => {

        let index = event.target.closest('.skill-row').id;

        if(levelArray[index] > 0){
            var newLvl = levelArray[index] - 1;
            var newMod = calculateSingleSkillMod(skills[index] ,newLvl, { "STR": STR, "DEX": DEX, "CON": CON, "INT": INT, "WIS": WIS, "CHA": CHA }, expertiseArray[index], armorPenalty);

            saveCharacter({
                ...character,
                [charPropArray[0 + 3*index]]: newLvl,
                [charPropArray[1 + 3*index]]: newMod,
                'skillPoints': skillPoints + 1
            });
        }
    }

    const onChange = e => {
        saveCharacter({...character, [e.target.name]: e.target.value});
    };

    

    return ( 
    <Fragment>
        <div className="char-sheet-skill-panel">
            <div className="skill-top">
                <div className="skill-info">
                    <p>
                    </p>
                </div>
                <div className="skill-points-container">
                    <div className="skill-points-item">
                        <p>Skill points remaining:</p>
                        <input type="number" name='skillPoints' value={skillPoints} onChange={onChange} />
                    </div>
                </div>
            </div>
            <div className="skill-header">
                <div className="select-skill"></div>
                <div className="skill-name">SKILL<br />NAME</div>
                <div className="skill-mod">MOD</div>
                <div className="skill-att">MAIN<br />ATTR.</div>
                <div className="skill-level">LEVEL</div>
                <div className="expertise_container">EXPERTISE</div>
                <div className="skill-level-chart hide-900">PERK CHART</div>
                
            </div>
            {createSkillTable(skills)}
        </div>
    </Fragment>
    );
};

CharEditSkills.propTypes = {
    saveCharacter: PropTypes.func.isRequired,
    character: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    character: state.character
});

export default connect(mapStateToProps, {saveCharacter})(CharEditSkills);