import axios from 'axios';
import { setAlert } from './alert';

import { statChanges } from '../utils/calculations';

import {
    GET_MY_CHARACTERS,
    GET_CHARACTER,
    CREATE_CHARACTER,
    CHARACTER_ERROR,
    SAVE_CHARACTER,
    DELETE_CHARACTER,
    UPDATE_CHARACTER,
    CLEAR_CHARACTER,
    GET_PREBUILTS
} from './types';

//Save current character data to state, not an API call
export const saveCharacter = (charObject) => async dispatch => {

    var updatedChar = statChanges(charObject);

    dispatch({
        type: SAVE_CHARACTER,
        payload: updatedChar
    });
}

//Add character to database
export const createCharacter = (charObject, history) => async dispatch => {
    try {
        
        const res = await axios.post('/api/character', charObject);

        dispatch({
            type: CREATE_CHARACTER,
            payload: res.data
        });

        history.push('/dashboard');

        dispatch(setAlert('Character Created','success', 2000));

        
    } catch (err) {
        const errors = err.response.data.errors;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: CHARACTER_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}

export const addPrebuiltCharacter = (charObject, history) => async dispatch => {
    try {
        
        const res = await axios.post('/api/character', charObject);

        dispatch({
            type: CREATE_CHARACTER,
            payload: res.data
        });

        history.push('/dashboard');

        dispatch(setAlert('Character Created','success', 2000));

        
    } catch (err) {
        const errors = err.response.data.errors;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: CHARACTER_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}

//Update database character object
export const updateCharacter = (charObject) => async dispatch => {
    try {

        const res = await axios.post(`/api/character/${charObject._id}`, charObject);
        
        dispatch({
            type: UPDATE_CHARACTER,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: CHARACTER_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}

//Get characters for current user
export const getMyCharacters = () => async dispatch => {
    try {
        const res = await axios.get('/api/character/me');

        dispatch({
            type: GET_MY_CHARACTERS,
            payload: res.data
        });
    } catch (err) {
        const errors = err.response.data.errors;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: CHARACTER_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}

//Get prebuilt characters
export const getPrebuiltCharacters = () => async dispatch => {

    try {
        const res = await axios.get('/api/character/prebuilt');

        dispatch({
            type: GET_PREBUILTS,
            payload: res.data
        });
    } catch (err) {
        const errors = err.response.data.errors;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: CHARACTER_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}

//Get a character
export const getCharacter = id => async dispatch => {

    try {
        const res = await axios.get(`/api/character/${id}`);

        dispatch({
            type: GET_CHARACTER,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: CHARACTER_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

//Delete a character
export const deleteCharacter = id => async dispatch => {
    try {

        await axios.delete(`/api/character/${id}`);

        dispatch({
            type: DELETE_CHARACTER,
            payload: id
        });

        dispatch(setAlert("Character Deleted", 'success', 2000));

    } catch (err) {
        dispatch({
            type: CHARACTER_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

//Clear character state
export const clearCharacter = () => async dispatch => {
    try{
        dispatch({
            type: CLEAR_CHARACTER
        });
    } catch(err){
        dispatch({
            type: CHARACTER_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};