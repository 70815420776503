import React, { Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import grenade_bg from '../../../img/grenade_color.png';
import weap_bg from '../../../img/sci_fi_gun.png';
import armor_bg from '../../../img/armor.png';
import drone_bg from '../../../img/drone.png';
import serum_bg from '../../../img/vial.png';
import lockpick_bg from '../../../img/lockpick.png';

import { saveCharacter } from '../../../actions/character';

const CraftingModal = ({
    item: {
        items
    },
    character: {
        character
    },
    saveCharacter,
    closeStore
}) => {

    const {
        astrobiologyLevel,
        espionageLevel,
        explosivesLevel,
        techLevel,
        inventory
    } = character;

    var [showRecipePanel, toggleRecipePanel] = useState(false);

    const openRecipePanel = (e) => {
        toggleRecipePanel(true);
    }

    const craftables = items.filter(i => i.craftable === true);

    const generateCurrentlyCraftable = () => {

        //Find way to not call this every update

        var currentlyCraftableList = [];

        for(var i = 0; i < craftables.length; i++){

            var recipeCheck = true;

            for(var j = 0; j < craftables[i].recipe.length; j++){

                var charComponentQuant;
                switch(craftables[i].recipe[j].component){
                    case "Gun":
                        charComponentQuant = inventory.filter(x => x.type === "Assault Weapon" || x.type === "Long Range" || x.type === "Pistol").length;
                        break;
                    case "Blade":
                    case "Blunt":
                    case "Assault Weapon":
                    case "Long Range":
                    case "Pistol":
                    case "Helmet":
                    case "Body":
                    case "Gloves":
                    case "Boots":
                    case "Battery":
                        charComponentQuant = inventory.filter(x => x.type === craftables[i].recipe[j].component).length;
                        break;
                    default:
                        charComponentQuant = inventory.filter(x => x.name === craftables[i].recipe[j].component).length;
                        break;
                }

                if(craftables[i].type !== "Serum" && craftables[i].recipe[j].component !== "Empty Vial"){
                    if(charComponentQuant < craftables[i].recipe[j].quantity){
                        recipeCheck = false;
                        break;
                    }
                } else {
                    if(charComponentQuant === 0){
                        recipeCheck = false;
                        break;
                    }
                }

                
            }

            if(recipeCheck){
                currentlyCraftableList.push(craftables[i]);
            }
        }

        console.log(currentlyCraftableList);

        return currentlyCraftableList;
    }

    var [selectedRecipe, toggleSelectedRecipe] = useState({});
    var [currentlyCraftable, updateCurrentlyCraftable] = useState( generateCurrentlyCraftable() );
    var [recipeFilter, toggleRecipeFilter] = useState(0);
   

    //Set state for filter, selected recipe...

    const updateFilter = (event) => {
        toggleRecipeFilter(event.target.selectedIndex);
    }

    const changeSelectedRecipe = (event, item) => {

        var recipeElements = document.getElementById('recipeListContainer').children;

        for(var i = 0; i < recipeElements.length; i++){
            recipeElements[i].className = "craftableListItem";
        }

        toggleSelectedRecipe(item);

        event.target.className = "craftableListItem selected";

        toggleRecipePanel(false);
    }

    const createRecipeList = () => {

        const serumList = craftables.filter(i => i.skillRequirementName === "Astrobiology" && i.skillRequirementLevel <= astrobiologyLevel);
        const lockpickList = craftables.filter(i => i.skillRequirementName === "Espionage" && i.skillRequirementLevel <= espionageLevel);
        const grenadeList = craftables.filter(i => i.skillRequirementName === "Explosives" && i.skillRequirementLevel <= explosivesLevel);
        const techList = craftables.filter(i => i.skillRequirementName === "Tech" && i.skillRequirementLevel <= techLevel);

        var filteredRecipes = [];

        switch(recipeFilter){
            case 0:
                filteredRecipes = [...serumList, ...lockpickList, ...grenadeList, ...techList];
                break;
            case 1:
                filteredRecipes = [...grenadeList];
                break;
            case 2:
                filteredRecipes = [...serumList];
                break;
            case 3:
                filteredRecipes = [...techList];
                break;
            case 4:
                filteredRecipes = [...lockpickList];
                break;
            default:
                filteredRecipes = [...serumList, ...lockpickList, ...grenadeList, ...techList];
                break;
        }

        var displayedRecipes = filteredRecipes.map((item, index) => 
            <div key={index} className="craftableListItem" onClick={e => changeSelectedRecipe(e,item)}>{item.name}</div>
        );

        return displayedRecipes;

        
    }

    const simpleGrenadeRecipe = () => {

        var recipeSpecificComp = selectedRecipe.recipe.find(r => r.component != "Scrap Metal" && r.component != "Spare Parts").component;

        var charSpecificComp = inventory.filter(x => x.name === recipeSpecificComp).length;
        var charScrapMetal = inventory.filter(x => x.name === 'Scrap Metal').length;
        var charSpareParts = inventory.filter(x => x.name === 'Spare Parts').length;

        return(
            <div className="recipeVisual">
                <img src={grenade_bg} alt="" className="recipeBG grenadeBG" />
                <div className={charSpecificComp > 0 ? "recipeComponent simpleGrenComp1" : "recipeComponentNA simpleGrenComp1"}>
                    <p className="compQuant"></p>
                    <p className="compName">{recipeSpecificComp}</p>
                </div>
                <div className={charScrapMetal > 0 ? "recipeComponent simpleGrenComp2" : "recipeComponentNA simpleGrenComp2"}>
                    <p className="compQuant"></p>
                    <p className="compName">Scrap Metal</p>
                </div>
                <div className={charSpareParts > 0 ? "recipeComponent simpleGrenComp3" : "recipeComponentNA simpleGrenComp3"}>
                    <p className="compQuant"></p>
                    <p className="compName">Spare Parts</p>
                </div>
                <div className={charSpareParts > 1 ? "recipeComponent simpleGrenComp4" : "recipeComponentNA simpleGrenComp4"}>
                    <p className="compQuant"></p>
                    <p className="compName">Spare Parts</p>
                </div>
                <div className="recipeYield">
                    <div>
                        <span>YIELDS:</span> 2 {selectedRecipe.name}s
                    </div>
                    {currentlyCraftable.find(x => x.name === selectedRecipe.name) 
                        ? <button className="craftButton" onClick={e => craftSelectedItem(e)}>CRAFT</button>
                        : <button className="craftButtonNA">CRAFT</button> 
                    }
                </div>
            </div>
        )
    }

    const simpleModRecipe = () => {

        var charModComp1;
        
        switch(selectedRecipe.recipe[0].component){
            case "Gun":
                charModComp1 = inventory.filter(x => x.type === "Assault Weapon" || x.type === "Long Range" || x.type === "Pistol").length;
                break;
            case "Blade":
            case "Blunt":
            case "Assault Weapon":
            case "Long Range":
            case "Pistol":
            case "Helmet":
            case "Body":
            case "Gloves":
            case "Boots":
            case "Battery":
                charModComp1 = inventory.filter(x => x.type === selectedRecipe.recipe[0].component).length;
                break;
            default:
                charModComp1 = inventory.filter(x => x.name === selectedRecipe.recipe[0].component).length;
                break;
        }
        
        var charModComp2 = inventory.filter(x => x.name === 'Spare Parts').length;

        var charModComp3;

        switch(selectedRecipe.recipe[2].component){
            case "Gun":
                charModComp3 = inventory.filter(x => x.type === "Assault Weapon" || x.type === "Long Range" || x.type === "Pistol").length;
                break;
            case "Blade":
            case "Blunt":
            case "Assault Weapon":
            case "Long Range":
            case "Pistol":
            case "Helmet":
            case "Body":
            case "Gloves":
            case "Boots":
            case "Battery":
                charModComp3 = inventory.filter(x => x.type === selectedRecipe.recipe[2].component).length;
                break;
            default:
                charModComp3 = inventory.filter(x => x.name === selectedRecipe.recipe[2].component).length;
                break;
        }

        return(
            <div className="recipeVisual">
                <img 
                    src={selectedRecipe.modItem === "Assault Weapon" || selectedRecipe.modItem === "Long Range" || selectedRecipe.modItem === "Pistol" ? weap_bg : armor_bg} 
                    alt="" 
                    className= {selectedRecipe.modItem === "Assault Weapon" || selectedRecipe.modItem === "Long Range" || selectedRecipe.modItem === "Pistol" ? "recipeBG weapBG" : "recipeBG armorBG" }/>
                <div className={charModComp1 > 0 ? "recipeComponent simpleModComp1" : "recipeComponentNA simpleModComp1"}>
                    <p className="compQuant"></p>
                    <p className="compName">{selectedRecipe.recipe[0].component}</p>
                </div>
                <div className={charModComp2 > 0 ? "recipeComponent simpleModComp2" : "recipeComponentNA simpleModComp2"}>
                    <p className="compQuant"></p>
                    <p className="compName">Spare Parts</p>
                </div>
                <div className={charModComp3 > 0 ? "recipeComponent simpleModComp3" : "recipeComponentNA simpleModComp3"}>
                    <p className="compQuant"></p>
                    <p className="compName">{selectedRecipe.recipe[2].component}</p>
                </div>
                <div className="recipeYield">
                    <div>
                        <span>YIELDS:</span> 1 {selectedRecipe.name}
                    </div>
                    
                    {currentlyCraftable.find(x => x.name === selectedRecipe.name) 
                        ? <button className="craftButton" onClick={e => craftSelectedItem(e)}>CRAFT</button>
                        : <button className="craftButtonNA">CRAFT</button> 
                    }
                </div>
            </div>
        )
    }

    const simpleDroneRecipe = () => {

        var charScrapMetal = inventory.filter(x => x.name === 'Scrap Metal').length;
        var charSpareParts = inventory.filter(x => x.name === 'Spare Parts').length;

        var charDroneComp3 = 0;

        if(selectedRecipe.recipe.length > 2){
            if(selectedRecipe.recipe[2].component === "Camera"){
            charDroneComp3 = inventory.filter(x => x.name === 'Camera').length;
        } else if(selectedRecipe.recipe[2].component === "Pistol"){
            charDroneComp3 = inventory.filter(x => x.type === "Pistol").length;
        }}

        return(
            <div className="recipeVisual">
                <img src={drone_bg} alt="" className="recipeBG droneBG" />
                <div className={charSpareParts > 0 ? "recipeComponent simpleDroneComp1" : "recipeComponentNA simpleDroneComp1"}>
                    <p className="compQuant"></p>
                    <p className="compName">Spare Parts</p>
                </div>
                <div className={charScrapMetal > 0 ? "recipeComponent simpleDroneComp2" : "recipeComponentNA simpleDroneComp2"}>
                    <p className="compQuant"></p>
                    <p className="compName">Scrap Metal</p>
                </div>
                {selectedRecipe.recipe.length < 3 ?
                    (<div className={charScrapMetal > 1 ? "recipeComponent simpleDroneComp3" : "recipeComponentNA simpleDroneComp3"}>
                        <p className="compQuant"></p>
                        <p className="compName">Scrap Metal</p>
                    </div>)
                    :
                    (<div className={charDroneComp3 > 0 ? "recipeComponent simpleDroneComp3" : "recipeComponentNA simpleDroneComp3"}>
                        <p className="compQuant"></p>
                        <p className="compName">{selectedRecipe.recipe[2].component}</p>
                    </div>)
                }
                <div className="recipeYield">
                    <div>
                        <span>YIELDS:</span> 1 {selectedRecipe.name}
                    </div>
                    {currentlyCraftable.find(x => x.name === selectedRecipe.name) 
                        ? <button className="craftButton" onClick={e => craftSelectedItem(e)}>CRAFT</button>
                        : <button className="craftButtonNA">CRAFT</button> 
                    }
                </div>
            </div>
        )
    }

    const simpleSerumRecipe = () => {

        var recipeSpecificComp = selectedRecipe.recipe.find(r => r.component != "Empty Vial").component;

        var charSpecComp = inventory.filter(x => x.name === recipeSpecificComp).length;
        var charEmptyVial = inventory.filter(x => x.name === 'Empty Vial').length;

        return(
            <div className="recipeVisual">
                <img src={serum_bg} alt="" className="recipeBG serumBG" />
                <div className={charSpecComp > 0 ? "recipeComponent simpleSerumComp1" : "recipeComponentNA simpleSerumComp1"}>
                    <p className="compQuant"></p>
                    <p className="compName">{recipeSpecificComp}</p>
                </div>
                <div className={charEmptyVial > 0 ? "recipeComponent simpleSerumComp2" : "recipeComponentNA simpleSerumComp2"}>
                    <p className="compQuant"></p>
                    <p className="compName">Empty Vial</p>
                </div>
                <div className={charEmptyVial > 1 ? "recipeComponent simpleSerumComp3" : "recipeComponentNA simpleSerumComp3"}>
                    <p className="compQuant"></p>
                    <p className="compName">Empty Vial</p>
                </div>
                <div className={charEmptyVial > 2 ? "recipeComponent simpleSerumComp4" : "recipeComponentNA simpleSerumComp4"}>
                    <p className="compQuant"></p>
                    <p className="compName">Empty Vial</p>
                </div>
                <div className="recipeYield">
                    <div>
                        <span>YIELDS: </span>{Math.max(Math.min(charEmptyVial, 3), 1)} {selectedRecipe.name}
                    </div>
                    {currentlyCraftable.find(x => x.name === selectedRecipe.name) 
                        ? <button className="craftButton" onClick={e => craftSelectedItem(e)}>CRAFT</button>
                        : <button className="craftButtonNA">CRAFT</button> 
                    }
                    </div>
            </div>
        )
    }

    const lockpickRecipe = () => {

        var charSpareParts = inventory.filter(x => x.name === 'Spare Parts').length;

        return(
            <div className="recipeVisual">
                <img src={lockpick_bg} alt="" className="recipeBG lockpickBG" />
                <div className={charSpareParts > 0 ? "recipeComponent simpleGrenComp2" : "recipeComponentNA simpleGrenComp2"}>
                    <p className="compQuant"></p>
                    <p className="compName">Spare Parts</p>
                </div>
                <div className="recipeYield">
                    <div>
                        <span>YIELDS: 1 Lockpick</span>
                    </div>
                    {currentlyCraftable.find(x => x.name === selectedRecipe.name) 
                        ? <button className="craftButton" onClick={e => craftSelectedItem(e)}>CRAFT</button>
                        : <button className="craftButtonNA">CRAFT</button> 
                    }
                </div>
            </div>
        )
    }

    const showSelectedRecipe = () => {

        switch(selectedRecipe.type){
            case "Mod":
                return simpleModRecipe();
            case "Grenade":
                return simpleGrenadeRecipe();
            case "Drone":
                return simpleDroneRecipe();
            case "Serum":
                return simpleSerumRecipe();
            case "Misc":
                if(selectedRecipe.subtype === "Lockpick"){
                    return lockpickRecipe();
                }
            default:
                return <div className="recipeVisual"></div>
        };
    }

    const craftSelectedItem = (event) => {

        //Since number of serums craftable is dynamic, we need to know how many we're using
        var charEmptyVial = inventory.filter(x => x.name === 'Empty Vial').length;

        var newCap = 0;

        switch(selectedRecipe.type){
            case "Grenade":
                var finalRecipe = selectedRecipe;

                //Grenade buffs (need to test)
                if(character.explosivesLevel >= 30 && character.explosivesExpertise){
                    finalRecipe.aoe += 1;
                    finalRecipe.numDice += 1;
                }

                inventory.splice(inventory.findIndex(x => x.name === "Explosive Compound"), 1);
                inventory.splice(inventory.findIndex(x => x.name === "Scrap Metal"), 1);
                inventory.splice(inventory.findIndex(x => x.name === "Spare Parts"), 1);
                inventory.splice(inventory.findIndex(x => x.name === "Spare Parts"), 1);
                inventory.push(finalRecipe);
                inventory.push(finalRecipe);
                break;
            case "Serum":
                inventory.splice(inventory.findIndex(x => x.name === selectedRecipe.recipe.find(r => r.component !== "Empty Vial").component), 1);
                for(var i=0; i < Math.min(charEmptyVial,3);i++){
                    inventory.splice(inventory.findIndex(x => x.name === "Empty Vial"), 1);
                    inventory.push(selectedRecipe);
                }
                
                break;
            case "Drone":
                if(selectedRecipe.recipe.length < 3){
                    inventory.splice(inventory.findIndex(x => x.name === "Scrap Metal"), 1);
                    inventory.splice(inventory.findIndex(x => x.name === "Scrap Metal"), 1);
                    inventory.splice(inventory.findIndex(x => x.name === "Spare Parts"), 1);
                    inventory.push(selectedRecipe);
                    break;
                } else {
                    var recipeSpecComp = selectedRecipe.recipe.find(r => r.component !== "Scrap Metal" && r.component !== "Spare Parts").component;
                    if(recipeSpecComp === "Pistol"){
                        inventory.splice(inventory.findIndex(x => x.type === recipeSpecComp), 1);
                    } else {
                        
                        inventory.splice(inventory.findIndex(x => x.name === recipeSpecComp), 1);
                        
                    }
                    inventory.splice(inventory.findIndex(x => x.name === "Scrap Metal"), 1);
                    inventory.splice(inventory.findIndex(x => x.name === "Spare Parts"), 1);
                    inventory.push(selectedRecipe);
                    break;
                }
            case "Misc":
                if(selectedRecipe.subtype==="Lockpick"){
                    inventory.splice(inventory.findIndex(x => x.name === "Spare Parts"), 1);
                    inventory.push(selectedRecipe);
                }
                break;
            default:
                break;
        }

        for(var i=0; i < inventory.length; i++){
            newCap += parseFloat(inventory[i].weight.$numberDecimal)
        }

        saveCharacter({
            ...character,
            "inventory": inventory,
            "currentCapacity": newCap
        });

        closeStore();
    }

    const showHideRecipePanel = () => e => {
        if(showRecipePanel){
            toggleRecipePanel(false);
        } else {
            toggleRecipePanel(true);
        }

        console.log(showRecipePanel);
    }

    return (
        <Fragment>
            
            <div className="craftingWrapper">
                <div className="craftingContainer">
                    <div className="craftingHeader">
                    
                        <h1>Craft Items</h1>
                        {/*<div className="craftableCheck">
                            <input type="checkbox" />
                            <label htmlFor="">Show Only Craftable</label>
                        </div>*/}
                        <div className="recipeButton" onClick={showHideRecipePanel(true)}>RECIPES</div>
                        <div className='closeCraftingModal' onClick={e => closeStore()(e)}>X</div>
                    </div>
                    <div className="recipeContainer">
                        {showSelectedRecipe()}
                        
                    </div>
                </div>
                <div className={showRecipePanel ? "recipeList" : "recipeList hidden"}>
                    <select name="" id="" onChange={e => updateFilter(e)}>
                        <option value="all">All</option>
                        <option value="grenades">Grenades</option>
                        <option value="serums">Serums</option>
                        <option value="mods">Tech</option>
                        <option value="other">Other</option>
                    </select>
                    <div id="recipeListContainer">
                        {createRecipeList()}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

CraftingModal.propTypes = {
    item: PropTypes.object.isRequired,
    character: PropTypes.object.isRequired,
    saveCharacter: PropTypes.func.isRequired,
    closeStore: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    item: state.item,
    character: state.character
});

export default connect(mapStateToProps, {saveCharacter})(CraftingModal);