import React, { Fragment} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveCharacter } from '../../../actions/character';
import { allLanguages } from '../../../resources/arrays';

const CharCreationVitals = ({
    currentState,
    saveCharacter
}) => {

    const {
        race,
        name,
        gender,
        height,
        physique,
        languages,
        alignment
    } = currentState;

    const changeNameInfo = (race) => {
        switch(race) {
            case 'Human':
            case 'Cyborg':
                return <span>come from any modern Earthly source. <a href="https://www.fantasynamegenerators.com/" target="_blank" rel="noopener noreferrer" style={{color: '#d61fb5'}}>Click Here</a></span>;
            case 'Quexi':
                return <span>are based on ancient Etruscan names. <a href="https://www.fantasynamegenerators.com/etruscan-names.php" target="_blank" rel="noopener noreferrer" style={{color: '#d61fb5'}}>Click Here</a></span>;
            case 'Aerzha':
                return <span>are based on ancient Edo names. <a href="https://www.fantasynamegenerators.com/edo-japanese-names.php" target="_blank" rel="noopener noreferrer" style={{color: '#d61fb5'}}>Click Here</a></span>;
            case 'Muhmin':
                return <span>are based on more modern Amazigh/Berber names. <a href="https://www.fantasynamegenerators.com/amazigh-names.php" target="_blank" rel="noopener noreferrer" style={{color: '#d61fb5'}}>Click Here</a></span>;
            case "Koz'aid":
                return <span>are based on several sources of Native American names. <a href="https://www.fantasynamegenerators.com/native_american_names.php" target="_blank" rel="noopener noreferrer" style={{color: '#d61fb5'}}>Click Here</a></span>;
            case 'Veith':
                return <span>are based on more modern Samoan names. <a href="https://www.fantasynamegenerators.com/samoan-names.php" target="_blank" rel="noopener noreferrer" style={{color: '#d61fb5'}}>Click Here</a></span>;
            case "Ts'tsen":
                return <span>are based on ancient Assyrian names. <a href="https://www.fantasynamegenerators.com/assyrian-names.php" target="_blank" rel="noopener noreferrer" style={{color: '#d61fb5'}}>Click Here</a></span>;
            default:
                return
        }
    }

    const currentLanguages = languages => {
        const langs = languages.map((l, index) => 
            <div key={index} onClick={removeLanguage(index)}>{l}</div>
        )

        return langs;
    }

    const updateLanguageDropdown = languages => {
        var allLangs = allLanguages.filter((l) => !languages.includes(l));

        const langOptions = allLangs.map((l, index) => 
            <option key={index} onClick={(e) => addLanguage(languages)(e)}>{l}</option>
        )

        return langOptions;
    }

    const onChange = e => {
        saveCharacter({...currentState, [e.target.name]: e.target.value});
    }

    const addLanguage = e => {

        saveCharacter({
            ...currentState,
            'languages': [...languages, e.target.value]
        });
    }

    const removeLanguage = ind => (e) => {

        languages.splice(ind, 1);

        saveCharacter({
            ...currentState,
            'languages': languages
        });
    }

    const updateField = (field, value) => (e) => {
        saveCharacter({
            ...currentState,
            [field]: value
        });
    }


    return ( <Fragment>
        <div className="char-creation-vitals-panel">
            <h1>NAME & GENDER</h1>
            <div className="vital-item">
                <div className="vital-desc">
                    <p>You are welcome to give your character any name you want. If you would like to try and fit within canonical game system, try and use a name that is appropriate for your chosen race.</p>
                    <p>Your race is <span>{race}</span>. Names for this race {changeNameInfo(race)} to use an online name generator to find a random name for your character.</p>
                    <p>Feel free to write in any gender you would like for your character.</p>
                </div>
                <div className="vital-info">
                    <label>Name</label><input type="text" id="char-name" name="name" onChange={onChange} value={name}/><br />
                    <label>Gender</label><input type="text" name="gender" onChange={onChange} value={gender}/>
                </div>
            </div>
            <h1>HEIGHT & PHYSIQUE</h1>
            <div className="vital-item">
                <div className="vital-desc">
                    <p>Height and physique do not have any significant in-game effects, but add a layer of depth to your character. Choose a height and physique from the dropdowns.</p>
                    <p>When affected by gravity, your character will also have a "Weight" stat calculated from your height and physique. This changes based on the amount of gravitational force they are under and can affect interactions.</p>
                </div>
                <div className="vital-info">
                    <label>Height (in cm)</label>
                    <select id="height-and-physique" className="hmp" name="height" onChange={onChange} defaultValue={!height ? '-- pick one --' : height}>
                        <option disabled selected style={{display:'none'}}> -- pick one --</option>
                        <option>Diminutive</option>
                        <option>Short</option>
                        <option>Average</option>
                        <option>Tall</option>
                        <option>Towering</option>
                    </select>
                    <label>Physique</label>
                    <select className="hmp" name="physique" onChange={onChange} defaultValue={!physique ? '-- pick one --' : physique}>
                        <option disabled selected style={{display:'none'}}> -- pick one --</option>
                        <option>Scrawny</option>
                        <option>Slender</option>
                        <option>Fit</option>
                        <option>Muscular</option>
                        <option>Overweight</option>
                        <option>Obese</option>
                    </select>
                </div>
            </div>
            <h1>Languages</h1>
            <div className="vital-item">
                <div className="vital-desc">
                    <p>Languages allow you to communicate with NPCs that you encounter. You are automatically assigned Uni and your race's base language. Beyond that, you should only add languages that make sense to your character.</p>
                </div>
                <div className="vital-info">
                    <div className="languages">
                        {currentLanguages(languages)}
                        <select value='Add a language' onChange={addLanguage}>
                            <option disabled selected style={{display:'none'}}>Add a language</option>
                            {updateLanguageDropdown(languages)}
                        </select>
                    </div>
                </div>
            </div>
            <h1>Alignment</h1>
            <div className="alignment" id="alignment">
                <p>Your allignment is how your character treats morals and ethics. A lawful character will try to stick to a set of rules, be they good or evil. A chaotic character will prefer to bend or break the rules, either for the common good or for their own benefit.</p>
                <p>Choose the alignment that best suits your character:</p>
                <div>
                    <div onClick={(e) => updateField('alignment', 'Lawful Good')(e)} className={alignment==="Lawful Good" ? 'selected-align' : ''}>Lawful<br />Good</div>
                    <div onClick={(e) => updateField('alignment', 'Lawful Neutral')(e)} className={alignment==="Lawful Neutral" ? 'selected-align' : ''}>Lawful<br />Neutral</div>
                    <div onClick={(e) => updateField('alignment', 'Lawful Evil')(e)} className={alignment==="Lawful Evil" ? 'selected-align' : ''}>Lawful<br />Evil</div>
                    <div onClick={(e) => updateField('alignment', 'Neutral Good')(e)} className={alignment==="Neutral Good" ? 'selected-align' : ''}>Neutral<br />Good</div>
                    <div onClick={(e) => updateField('alignment', 'True Neutral')(e)} className={alignment==="True Neutral" ? 'selected-align' : ''}>True<br />Neutral</div>
                    <div onClick={(e) => updateField('alignment', 'Neutral Evil')(e)} className={alignment==="Neutral Evil" ? 'selected-align' : ''}>Neutral<br />Evil</div>
                    <div onClick={(e) => updateField('alignment', 'Chaotic Good')(e)} className={alignment==="Chaotic Good" ? 'selected-align' : ''}>Chaotic<br />Good</div>
                    <div onClick={(e) => updateField('alignment', 'Chaotic Neutral')(e)} className={alignment==="Chaotic Neutral" ? 'selected-align' : ''}>Chaotic<br />Neutral</div>
                    <div onClick={(e) => updateField('alignment', 'Chaotic Evil')(e)} className={alignment==="Chaotic Evil" ? 'selected-align' : ''}>Chaotic<br />Evil</div>
                </div>
            </div>
        </div>
    </Fragment>
    )
}

CharCreationVitals.propTypes = {
    saveCharacter: PropTypes.func.isRequired,
    currentState: PropTypes.object.isRequired
};

export default connect(null, {saveCharacter})(CharCreationVitals);
