import React, { Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { skills } from '../../resources/skills';

import { getPrebuiltCharacters, createCharacter } from '../../actions/character';

//Components
import PrebuiltCharItem from './PrebuiltCharItem';

const PrebuiltList = ({
    character,
    getPrebuiltCharacters,
    createCharacter
}) => {

    useEffect(() => {
        getPrebuiltCharacters();
    }, [getPrebuiltCharacters])

    var [confirmModal, toggleConfirmModal] = useState(false);

    const openConfirmModal = (e) => {
        toggleConfirmModal(true);
    }

    const closeModals = (e) => {
        toggleConfirmModal(false);
    }

    const submitPrebuilt = (e) => {
        openConfirmModal();
        createCharacter(character);
    }
    
    return (
        <Fragment>
            {!character.loading && (
            <div className="dash-char-list mary-2 pad-1">
                <h1 className="text-blue">The Ace of Spades</h1>
                {character.characters.map(character => (
                    <PrebuiltCharItem key={character._id} character={character} />
                    )
                )}
            </div>
            )}
        </Fragment>
    )
}

PrebuiltList.propTypes = {
    character: PropTypes.object.isRequired,
    getPrebuiltCharacters: PropTypes.func.isRequired,
    createCharacter: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    character: state.character
});

export default connect(mapStateToProps, {getPrebuiltCharacters, createCharacter})(PrebuiltList);