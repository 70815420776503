export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Auth actions
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

//Character Actions
export const GET_MY_CHARACTERS = 'GET_MY_CHARACTERS';
export const GET_CHARACTER = 'GET_CHARACTER';
export const CREATE_CHARACTER = 'CREATE_CHARACTER';
export const CHARACTER_ERROR = 'CHARACTER_ERROR';
export const SAVE_CHARACTER = 'SAVE_CHARACTER';
export const UPDATE_CHARACTER = 'UPDATE_CHARACTER';
export const DELETE_CHARACTER = 'DELETE_CHARACTER';
export const CLEAR_CHARACTER = 'CLEAR_CHARACTER';
export const GET_PREBUILTS = 'GET_PREBUILTS';

//Skill Actions
export const GET_SKILLS = 'GET_SKILLS';
export const SKILL_ERROR = 'SKILL_ERROR';

//Item actions
export const GET_ALL_ITEMS = 'GET_ALL_ITEMS';
export const ITEM_ERROR = 'ITEM_ERROR';

//Campaign Actions
export const CAMPAIGN_ERROR = 'CAMPAIGN_ERROR';
export const GET_MY_CAMPAIGNS = 'GET_MY_CAMPAIGNS';
export const GET_CAMPAIGN = 'GET_CAMPAIGN';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const SAVE_CAMPAIGN = 'SAVE_CAMPAIGN';
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';