import React, { Fragment, useState} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { saveCharacter } from '../../../actions/character';

const LevelUp = ({
    character: {
        character
    },
    currentState,
    saveCharacter,
    closeModal,
    species
}) => {

    var [atr1, updateAttr1] = useState('');
    var [atr2, updateAttr2] = useState(''); 

    const {
        languages,
        inventory
    } = currentState;

    

    

    const updateAttributeButtons = (race, atr1, atr2) => {

        const abbrs = ['STR', 'CON', 'DEX', 'INT', 'WIS', 'CHA'];

        const attrButtons = abbrs.map((a, index) => 
            <button className='unselected' key={index} value={a} onClick={(e) => setAttributeVar(atr1, atr2, race)(e)}>{abbrs[index]}</button>
        )

        return attrButtons;
    }

    const setAttributeVar = (atr1, atr2, race) => (e) => {

        if(race === 'Cyborg'){
            if(atr1 === e.target.value){
                updateAttr1('');
                e.target.className = 'unselected';
            }
            else if(atr1 === ''){
                updateAttr1(e.target.value);
                e.target.className = 'selected';
            } 
            else return;
        } 
        else if(race === 'Human'){
            if(atr1 === e.target.value){
                console.log('Attribute 1 already selected');
                updateAttr1('');
                e.target.className = 'unselected';
            }
            else if(atr2 === e.target.value){
                updateAttr2('');
                e.target.className = 'unselected';
            }
            else if(atr1 === ''){
                updateAttr1(e.target.value);
                e.target.className = 'selected';
            }
            else if(atr2 === ''){
                updateAttr2(e.target.value);
                e.target.className = 'selected';
            }
        }
    }

    const chooseSpecies = (lang, att1, att2, att3, skill1, skill2, skill3) => (e) => {

        if(e.target.value === 'Human'){
            if(atr1 === '' || atr2 === ''){
                alert('You must first choose two attributes to increase if you are choosing to be a Human');
            }
            else {
                saveCharacter({
                    ...currentState,
                    'race': e.target.value,
                    'languages': [...languages, lang],
                    [att1]: 11,
                    [att2]: 11,
                    'skillPoints': 5
                });
            }
            
        } else if(e.target.value === 'Cyborg'){
            if(atr1 === ''){
                alert('You must first choose an attribute to increase if you are choosing to be a Cyborg');
            }
            else {
                saveCharacter({
                    ...currentState,
                    'race': e.target.value,
                    'languages': [...languages, lang],
                    [att1]: 12,
                    'techLevel': 5,
                    'skillPoints': 2
                });
            }
        } else if(e.target.value === 'Muhmin'){
            saveCharacter({
                ...currentState,
                'race': e.target.value,
                'languages': [...languages, lang],
                [att1]: 12,
                [att2]: 11,
                [att3]: 9,
                [skill1]: 10,
                [skill2]: 5,
                [skill3]: 5,
                'skillPoints': 1,
                'resistances': ['Cold'],
                'TARG': 11
            });
        } else if(e.target.value === 'Aerzha'){
            saveCharacter({
                ...currentState,
                'race': e.target.value,
                'languages': [...languages, lang],
                [att1]: 12,
                [att2]: 11,
                [att3]: 9,
                [skill1]: 10,
                [skill2]: 5,
                [skill3]: 5,
                'skillPoints': 1,
                'TARG': 13
            });
        } else if(e.target.value === "Koz'aid"){
            saveCharacter({
                ...currentState,
                'race': e.target.value,
                'languages': [...languages, lang],
                [att1]: 12,
                [att2]: 11,
                [att3]: 9,
                [skill1]: 10,
                [skill2]: 5,
                [skill3]: 5,
                'skillPoints': 1,
                'TARG': 11,
                'inventory': [...inventory,
                    {
                        'name': "Koz'Aid Body Carapace",
                        'classification': "Armor",
                        'type': "Body",
                        'subtype': "Heavy Armor",
                        'requirement': "None",
                        'description': "Protective shell that Koz'Aid grow naturally. May be molted and replaced with typical Body Armor.",
                        'value': 0,
                        'rarity': "Unique",
                        'weight': 5,
                        'modSlots': 3,
                        'dis': 0,
                        'kin': 15,
                        'equipped': "Body"
                    },
                    {
                        'name': "Koz'Aid Leg Carapace",
                        'classification': "Armor",
                        'type': "Boots",
                        'subtype': "Heavy Armor",
                        'requirement': "None",
                        'description': "Protective shell that Koz'Aid grow naturally. May be molted and replaced with typical Boots.",
                        'value': 0,
                        'rarity': "Unique",
                        'weight': 3,
                        'modSlots': 1,
                        'dis': 0,
                        'kin': 5,
                        'equipped': "Feet"
                    },
                    {
                        'name': "Koz'Aid Head Carapace",
                        'classification': "Armor",
                        'type': "Helmet",
                        'subtype': "Heavy Armor",
                        'requirement': "None",
                        'description': "Protective shell that Koz'Aid grow naturally. May be molted and replaced with typical Helmet.",
                        'value': 0,
                        'rarity': "Unique",
                        'weight': 2,
                        'modSlots': 1,
                        'dis': 0,
                        'kin': 5,
                        'equipped': "Head"
                    }
                ]
            });
        } else if(e.target.value === 'Veith'){
            saveCharacter({
                ...currentState,
                'race': e.target.value,
                'languages': [...languages, lang],
                [att1]: 12,
                [att2]: 11,
                [att3]: 9,
                [skill1]: 10,
                [skill2]: 5,
                [skill3]: 5,
                'skillPoints': 1,
                'rechargeAvailable': 1
            });
        } else {
            saveCharacter({
                ...currentState,
                'race': e.target.value,
                'languages': [...languages, lang],
                [att1]: 12,
                [att2]: 11,
                [att3]: 9,
                [skill1]: 10,
                [skill2]: 5,
                [skill3]: 5,
                'skillPoints': 1
            });
        }
    }

    const humanInfo = (
        <div className="speciesInfoContainer">
            <div>
                <h2>Appearance</h2>
                <p>Average height and frame. They are covered in smooth skin ranging from pale pinkish to dark brown. They grow hair mostly on the top of their heads, which can be shades of blonde, red, brown, or black.</p>
            </div>
            <div>
                <h2>Homeworld</h2>
                <p>Earth</p>
            </div>
            <div>
                <h2>Behavior</h2>
                <p>There is great variation in the behavior of humans compared to other species. Some may be docile while others may be aggressive. However, most have individualistic tendencies.</p>
            </div>
            <div>
                <h2>Galactic Role</h2>
                <p>Humans are newer to space travel, but are very ambitious. They don’t excel in any specific area, but are capable of adapting and learning quickly in almost any scenario.</p>
            </div>
            <div>
                <h2>Attributes</h2>
                <p>+1 to two of your choice
                    
                </p>
                
            </div>
            <div className="attribute-buttons">
                {/*updateAttributeButtons(selectedTab, atr1, atr2)*/}
            </div>
            <div>
                <h2>Skills</h2>
                <p>+5 to five of your choice</p>
            </div>
            <div>
                <h2>Unique Trait</h2>
                <p>None</p>
            </div>
        </div>
    )

    const cyborgInfo = (
        <div className="speciesInfoContainer">
            <div>
                <h2>Appearance</h2>
                <p>They resemble humans, but have mechanical augmentations ranging from subtle and unnoticeable to having whole parts of their body be unmistakably synthetic.</p>
            </div>
            <div>
                <h2>Homeworld</h2>
                <p>Earth, though they tend to be less patriotic than other humans.</p>
            </div>
            <div>
                <h2>Behavior</h2>
                <p>Cyborgs tend to be more reclusive and individualistic than humans, especially around the other non-Human races. They tend to become very focused on their work, striving for the highest quality possible.</p>
            </div>
            <div>
                <h2>Galactic Role</h2>
                <p>Prior to contact with other space-bound races, Cyborgism was on the rise among humans and was seen as a possible next step in human evolution. However, the animosity from the other races, who view mechanical augmentation to be "unnatural" has made life much harder for Cyborgs. That said, no one questions the fact that their communes create products of the highest quality.</p>
            </div>
            <div>
                <h2>Attributes</h2>
                <p>+2 to one of your choice</p>
            </div>
            <div className="attribute-buttons">
                {/*updateAttributeButtons(selectedTab, atr1)*/}
            </div>
            <div>
                <h2>Skills</h2>
                <p>+10 to two of your choice and +5 to Tech.</p>
            </div>
            <div>
                <h2>Unique Trait</h2>
                <p>You have a special, non-combat action called Cybernetic Repair. When you are at a workbench outside of battle, you may heal yourself depending on the result of a Tech skill roll. On a 0-8, you deal 1d4 damage to yourself. On a 9-14, nothing happens. On a 14+, you heal for 20% of your max health, rounded down.</p>
            </div>
        </div>
    )

    const quexiInfo = (
        <div className="speciesInfoContainer">
           <div>
                <h2>Appearance</h2>
                <p>The Quexi are tall and slender. They are hairless and have a waxy skin that ranges from nearly white to yellow and orange.</p>
            </div>
            <div>
                <h2>Homeworld</h2>
                <p>Quexond</p>
            </div>
            <div>
                <h2>Behavior</h2>
                <p>Quexi are extremely intelligent but also very individualistic. Their tendencies and early spaceflight have led them to prefer space-bound over planetary habitation. They are greatly driven by technological advancement and innovation.</p>
            </div>
            <div>
                <h2>Galactic Role</h2>
                <p>Quexi are very scientifically and technologically focused, making them excellent engineers and scientists. Their bodies are used to shifts in gravity, which have made them particularly adept at using Grav stims.</p>
            </div>
            <div>
                <h2>Attributes</h2>
                <p>+2 INT, +1 DEX, -1 STR</p>
            </div>
            <div>
                <h2>Skills</h2>
                <p>+10 Tech, +5 Grav, +5 Pistol, and +5 to one of your choice.</p>
            </div>
            <div>
                <h2>Unique Trait</h2>
                <p>They do not have disadvantage on rolls when affected by the Weightless status effect.</p>
            </div>
        </div>
    )

    const aerzhaInfo = (
        <div className="speciesInfoContainer">
           <div>
                <h2>Appearance</h2>
                <p>Aerhza are the smallest of the space-bound races, most are under 1.5m (5 feet) tall. They have a light violet skin, small antlers, and typically grow their dark hair into long braids. The patterns they weave into their braids carry cultural significance for them.</p>
            </div>
            <div>
                <h2>Homeworld</h2>
                <p>Enneon</p>
            </div>
            <div>
                <h2>Behavior</h2>
                <p>The Aerhza are master rhetoricians, whether that be artistic or political. They enjoy congregating between themselves and other species, and typically seek to control the emotions of the room. Their culture is deeply rooted in traditions and honor.</p>
            </div>
            <div>
                <h2>Galactic Role</h2>
                <p>They are acclaimed diplomats and have been the driving force behind the IPF. Though they typically attempt diplomacy through rhetoric and negotiation, their history shows them to be formidable warriors when the need arises.</p>
            </div>
            <div>
                <h2>Attributes</h2>
                <p>+2 CHA, +1 INT, -1 DEX</p>
            </div>
            <div>
                <h2>Skills</h2>
                <p>+10 Negotiation, +5 Blade, +5 Psyche, and +5 to one of your choice.</p>
            </div>
            <div>
                <h2>Unique Trait</h2>
                <p>Once per target, you may reroll a Negotiation check. Base TARGET is 13.</p>
            </div>  
        </div>
    )

    const muhminInfo = (
        <div className="speciesInfoContainer">
           <div>
                <h2>Appearance</h2>
                <p>Muhmin are large, hairy, and cat-like. Some chieftains have even been up to 2.4m tall (about 8 feet). They are often equipped with dark goggles to protect their eyes from brighter environments than their homeworld.</p>
            </div>
            <div>
                <h2>Homeworld</h2>
                <p>Yizke</p>
            </div>
            <div>
                <h2>Behavior</h2>
                <p>The difficult conditions brought about by their tidally-locked homeworld have forced the Muhmin to constantly fight for survival. Because of this, they are aggressive and extremely skilled warriors.</p>
            </div>
            <div>
                <h2>Galactic Role</h2>
                <p>The decades-long war between the Muhmin and Quexi led to a strong stigma that Muhmin are nothing but aggressive savages. Though they have been trying to change this, Muhmin are still typically given roles as warriors and mercenaries.</p>
            </div>
            <div>
                <h2>Attributes</h2>
                <p>+2 STR, +1 DEX, -1 CHA</p>
            </div>
            <div>
                <h2>Skills</h2>
                <p>+10 Athletics, +5 Assault Weapons, +5 Unarmed, and +5 to one of your choice.</p>
            </div>
            <div>
                <h2>Unique Trait</h2>
                <p>Able to see clearly in dim lighting and resistant to cold. Base TARGET is 11.</p>
            </div>
        </div>
    )

    const kozaidInfo = (
        <div className="speciesInfoContainer">
           <div>
                <h2>Appearance</h2>
                <p>The arthropodal Koz’aid have large height and build. The females tend to be slightly larger than the males. They grow dark-red carapace over their skin.</p>
            </div>
            <div>
                <h2>Homeworld</h2>
                <p>Abara</p>
            </div>
            <div>
                <h2>Behavior</h2>
                <p>The Koz'aid are a strongly peaceful and communal species. They have strong bonds to their families, tribes, and traditions. They prefer simple living and hard work to fancy gadgetry.</p>
            </div>
            <div>
                <h2>Galactic Role</h2>
                <p>Until recently, the Koz'aid were the servants of the Ts'tsen. Though grateful for their new freedom, they have found it difficult to dissociate themselves from manual labor. They are also the least likely to be fluent in Uni, which has made it difficult for them to gain any influence within the IPF.</p>
            </div>
            <div>
                <h2>Attributes</h2>
                <p>+2 CON, +1 STR, -1 WIS</p>
            </div>
            <div>
                <h2>Skills</h2>
                <p>+10 Armor, +5 Observation, +5 Blunt, and +5 to one of your choice</p>
            </div>
            <div>
                <h2>Unique Trait</h2>
                <p>Koz'aid start out with a unique set of heavy armor, their own carapace. Base TARGET is 11.</p>
            </div>
        </div>
    )

    const veithInfo = (
        <div className="speciesInfoContainer">
           <div>
                <h2>Appearance</h2>
                <p>They have a similar size as humans, though tend to be more slender. Veith have small, smooth, bluish-green scales covering their bodies. Instead of hair, they have short, tentacle-like appendages on their heads. These serve no purpose outside of the water, so they are typically pulled back into a ponytail of sorts.</p>
            </div>
            <div>
                <h2>Homeworld</h2>
                <p>Veith</p>
            </div>
            <div>
                <h2>Behavior</h2>
                <p>The Veith are a strange and mystical race that value creativity and the arts. Their lifestyles are amphibious, though they cannot breath underwater. Their desire for adrenaline makes them especially good pilots and stim users.</p>
            </div>
            <div>
                <h2>Galactic Role</h2>
                <p>The Veith have been travelling their galaxy for centuries, and their history is surprisingly peaceful. However, they have not been very forthcoming with the other races about their history and have been avoiding making any large commitments to the IPF.</p>
            </div>
            <div>
                <h2>Attributes</h2>
                <p>+2 WIS, +1 CON, -1 INT</p>
            </div>
            <div>
                <h2>Skills</h2>
                <p>+10 Energy, +5 Fluid, +5 Vehicles, and +5 to one of your choice</p>
            </div>
            <div>
                <h2>Unique Trait</h2>
                <p>Normal movement underwater. They have a natural "battery" that can be used to charge stims. It automatically recharges once per day in sunlight.</p>
            </div>
        </div>
    )

    const tstsenInfo = (
        <div className="speciesInfoContainer">
           <div>
                <h2>Appearance</h2>
                <p>The Ts'tsen have medium height and build. They are bug-like with thin, hard, grey skin and large black eyes. They value being dressed professionally and avoid flamboyance.</p>
            </div>
            <div>
                <h2>Homeworld</h2>
                <p>Ix'aal</p>
            </div>
            <div>
                <h2>Behavior</h2>
                <p>Ts'tsen are quick-witted and enjoy high-stakes business and other endeavors. Everything is a competition to them, and sometimes you have to break the rules to win. Their homeworld is rife with towering metropolises and business reign supreme.</p>
            </div>
            <div>
                <h2>Galactic Role</h2>
                <p>Though new to the IPF, the Ts'tsen quickly became key to business and trade between the two galaxies. They have formed alliances with all the other space-bound races, but the other races are realizing that they need to read the fine-print first.</p>
            </div>
            <div>
                <h2>Attributes</h2>
                <p>+2 DEX, +1 CHA, -1 CON</p>
            </div>
            <div>
                <h2>Skills</h2>
                <p>+10 Espionage, +5 Long Range, +5 Wave, and +5 to one of your choice.</p>
            </div>
            <div>
                <h2>Unique Trait</h2>
                <p>Once per target, you can bribe to gain an automatic success on a Negotiation check. The GM sets the price. They can see well in dim lighting.</p>
            </div>
        </div>
    )

    const returnSpeciesInfo = () => {
        switch(species){
            case "Human":
                return humanInfo
            case "Cyborg":
                return cyborgInfo
            case "Quexi":
                return quexiInfo
            case "Aerzha":
                return aerzhaInfo
            case "Muhmin":
                return muhminInfo
            case "Koz'aid":
                return kozaidInfo
            case "Veith":
                return veithInfo
            case "Ts'tsen":
                return tstsenInfo
            default:
                return (
                    <div>Hello World</div>
                )
        }
    }

    return (
        <Fragment>
            <div className="levelUpWrapper">
                <div className='closeLvlUpModal' onClick={e => closeModal()(e)}>X</div>
                <div>{returnSpeciesInfo()}</div>
                <div className="speciesButtons">
                    <div className="levelUpConfirm" onClick={e => closeModal()(e)}>Cancel</div>
                    <div className="levelUpConfirm" onClick={e => closeModal()(e)}>Select</div>
                </div>
                
            </div>
            
        </Fragment>
    )
}

LevelUp.propTypes = {
    character: PropTypes.object.isRequired,
    saveCharacter: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    character: state.character
});

export default connect(mapStateToProps, {saveCharacter})(LevelUp);