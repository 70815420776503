import React, { Fragment, useState, useEffect} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { generateAttackObject, attackSituations } from '../../../utils/calculations';

const AttackModal = ({
    character: {
        character
    },
    closeModal,
    attackType,
    attackSource
}) => {

    var [showSitchPanel, toggleSitchPanel] = useState(false);

    const openSitchPanel = (e) => {
        toggleSitchPanel(true);
    }

    const {
        inventory
    } = character;

    const initialAttackInfo = generateAttackObject(attackType, attackSource, character);

    var [attackInfo, updateAttackInfo] = useState(initialAttackInfo);

    var initialInputState = [];

    for(var i=0; i<attackInfo.length;i++){
        var inputObject = {
            hitDie: 0,
            hitDie2: 0,
            hitResult: '',
            hitAdj: 0,
            advantageType: 'none',
            sprayDice: 0,
            sprayPenalty: 0,
            sprayMult: null,
            grouping: false,
            attackMult: 1,
            damageDice: Array(attackInfo[i].numDice),
            totalDam: 0
        };

        initialInputState.push(inputObject);
    }

   
    var [attackInputData, updateAttackInputState] = useState(initialInputState);
    var [currentTab, updateCurrentTab] = useState(0);

    var sprayDieSize = 0;

    if(attackInfo[0] !== undefined){
        if(attackInfo[0].weaponType === "Assault Weapon") {
            if(attackInfo[0].skillLevel === 50 && attackInfo[0].skillExpertise === "Special Ops"){
                sprayDieSize = 8;
            } else {
                sprayDieSize = 6;
            }
        }
    }

    const situationInitialState = attackSituations(attackInfo, character);

    var [sitchState, updateSitchState] = useState(situationInitialState);

    const updateSituations = () => e => {

        var ind = e.target.id;

        var newSitchState = sitchState;

        if(newSitchState[ind].active){
            newSitchState[ind].active = false;
        } else {
            newSitchState[ind].active = true;
        }

        updateSitchState([...newSitchState]);
    }

    const populateSituations = () => {

        const situationList = [];

        var advSitch = (<div id={0} className={sitchState[0].active ? "advSitch active" : "advSitch"} onClick={(e => updateSituations()(e))}>{sitchState[0].name}</div>);
        situationList.push(advSitch);

        var disadvSitch = (<div id={1} className={sitchState[1].active ? "disadvSitch active" : "disadvSitch"} onClick={(e => updateSituations()(e))}>{sitchState[1].name}</div>);
        situationList.push(disadvSitch);

        for(var i=2; i<sitchState.length;i++){
            var newSitch = (
                <div id={i} className={sitchState[i].active ? "sitch active" : "sitch"} onClick={(e => updateSituations()(e))}>{sitchState[i].name}</div>
            );
            situationList.push(newSitch);
        }

        return situationList;
    }

    const calcSprayMult = (attackNum, dieResult) => {

        var newAttackInputData = attackInputData;

        var finalDieResult = parseInt(dieResult) + attackInputData[attackNum].sprayPenalty;

        //Set mult based on perks
        var dieMult = 1;

        if(finalDieResult <= 1){

            dieMult = 0;

            if(attackInfo[attackNum].skillLevel >= 40 && attackInfo[attackNum].skillExpertise === 'Special Ops'){
                dieMult = 0.5;
            }
        }

        if(finalDieResult >= 2 && finalDieResult <= 3){
            dieMult = 0.5;
        }

        if(finalDieResult >= 4 && finalDieResult <= 5){
            dieMult = 1;
        }

        if(finalDieResult >= 6){
            dieMult = 2;
        }

        newAttackInputData[attackNum].sprayDice = dieResult;
        newAttackInputData[attackNum].sprayMult = dieMult;

        return newAttackInputData;
    }

    const updateAttackInput = (attackNum) => (e) => {

        var newAttackInputData = attackInputData;

        var totalDamage = 0;

        switch(e.target.name){
            case 'hitDie':
                if(e.target.value === ""){
                    newAttackInputData[attackNum].hitDie = 0;
                } else {
                    newAttackInputData[attackNum].hitDie = e.target.value;
                }
                break;
            case 'hitDie2':
                if(e.target.value === ""){
                    newAttackInputData[attackNum].hitDie = 0;
                } else {
                    newAttackInputData[attackNum].hitDie2 = e.target.value;
                }
                break;
            case 'damDie':
                if(e.target.value === ""){
                    newAttackInputData[attackNum].damageDice[e.target.id] = 0;
                } else {
                    newAttackInputData[attackNum].damageDice[e.target.id] = parseInt(e.target.value);
                    
                }

                for(var i=0; i < newAttackInputData[attackNum].damageDice.length; i++){
                    if(newAttackInputData[attackNum].damageDice[i] && i !== e.target.id){
                        totalDamage += newAttackInputData[attackNum].damageDice[i];
                    }
                }

                newAttackInputData[attackNum].totalDam = totalDamage;
                break;
            case 'sprayDie':
                newAttackInputData = calcSprayMult(attackNum, e.target.value, false);
            default:
                break;
        }

        //Update the attack data object
        updateAttackInputState([...newAttackInputData]);
    }

    const updateHitResult = (attackNum, result) => (e) => {
        var newAttackInputData = attackInputData;
        
        newAttackInputData[attackNum].hitResult = result;

        if(result === 'Miss'){
            newAttackInputData[attackNum].totalDam = 0;
        }

        //Handle Assault Weapon spray penalty calculation
        if(attackInfo[attackNum].weaponType === 'Assault Weapon'){

            if(attackNum > 0 && attackInputData[attackNum-1].grouping === true){
                newAttackInputData[attackNum].sprayDice = newAttackInputData[attackNum-1].sprayDice
            }
            
            var penalty = Math.max(0 - attackNum, -3);

            //Change penalty based on attackNum and perks
            if(attackInfo[attackNum].skillLevel === 50 && attackInfo[attackNum].skillExpertise === 'Bullet Hell'){
                penalty = Math.max(0 - Math.ceil(attackNum/2), -3);
            }

            newAttackInputData[attackNum].sprayPenalty = penalty;
        }

        //Update the attack data object
        updateAttackInputState([...newAttackInputData]);
    }

    const updateGrouping = (attackNum) => (e) => {

        var newAttackInputData = attackInputData;

        for(var i=attackNum;i<newAttackInputData.length;i++){
            newAttackInputData[i].grouping = e.target.checked;
        }

        updateAttackInputState([...newAttackInputData]);
    }

    const generateAttackTabs = () => {
        var tabs = attackInfo.map((att, i) => 
            <div className={currentTab === i ? 'selected' : ''} onClick={e => updateCurrentTab(i)}>Attack {i + 1}</div>
        )

        return tabs;
    }

    const generateDamDice = (index) => {

        const ddElements = [];

        var numRolls = attackInfo[index].numDice;

        if(sitchState.find(x => x.name == "Sharpen")){if(sitchState.find(x => x.name == "Sharpen").active){ numRolls = numRolls + 1;}}

        for(var i = 0; i < numRolls; i++){

            var newDamDie = (
                <div className="attackModalRow">
                    Roll a d{attackInfo[index].diceSize} for damage:
                    <input type="number" name="damDie" id={i} min={0} max="20" onChange={e => updateAttackInput(index)(e)}/>
                </div>
            );

            ddElements.push(newDamDie);
        }

        return ddElements;
    }

    const generateAttackForms = () => {

        var advantageType = 'none';

        //Changes to hit roll
        var hitChange = 0;

        //Situation: Advantage
        if(sitchState.find(x => x.name == "Advantage").active){ 
            advantageType = 'advantage';
        }

        //Situation: Disadvantage
        if(sitchState.find(x => x.name == "Disadvantage").active){ 
            advantageType = 'disadvantage';
        }

        //Situation: Dim lighting
        if(sitchState.find(x => x.name == "Dim Lighting").active){ hitChange = hitChange - 4;}

        //Situation: Darkness
        if(sitchState.find(x => x.name == "Darkness").active){ 
            advantageType = 'disadvantage';
        }

        //Situation: Sprinting
        if(sitchState.find(x => x.name == "Sprinting")){
            if(sitchState.find(x => x.name == "Sprinting").active || sitchState.find(x => x.name == "Swimming").active || sitchState.find(x => x.name == "Flying").active){ 
                advantageType = 'disadvantage'
            }
        }

        // Situation: Heavy Strike (hit penalty)
        if(sitchState.find(x => x.name == "Heavy Strike")){ if (sitchState.find(x => x.name == "Heavy Strike").active && (attackInfo[0].skillExpertise != "Bruiser" || attackInfo[0].skillLevel < 35)){ hitChange = hitChange - 4;}}

        // Situation: Out of Cover (pistols and assault weapons)
        if(attackInfo[0].weaponType == 'Assault Weapon' || attackInfo[0].weaponType == 'Pistol'){
            if(sitchState.find(x => x.name == "Out of Cover")){ if (sitchState.find(x => x.name == "Out of Cover").active){ hitChange = hitChange - 4;}}
        }

        // Situation: Out of Cover (long range)
        if(attackInfo[0].weaponType == 'Long Range' && (attackInfo[0].skillExpertise != 'Ranger' || attackInfo[0].skillLevel < 30 || character.inventory.find(x => x.equipped === "Main Hand").subtype == 'Tripod Rifle')){
            if(sitchState.find(x => x.name == "Out of Cover")){ 
                if (sitchState.find(x => x.name == "Out of Cover").active){ 
                    advantageType = 'disadvantage'
                }
            }
        }

        // Situation: Out of Cover (long range with Hunter perk)
        if(attackInfo[0].weaponType == 'Long Range' && attackInfo[0].skillExpertise == 'Ranger' && attackInfo[0].skillLevel >= 30 && character.inventory.find(x => x.equipped === "Main Hand").subtype !== 'Tripod Rifle'){
            if(sitchState.find(x => x.name == "Out of Cover")){ if (sitchState.find(x => x.name == "Out of Cover").active){ hitChange = hitChange - 4;}}
        }
        
        // Situation: Focused Breathing
        if(sitchState.find(x => x.name == "Focused Breathing")){ if (sitchState.find(x => x.name == "Focused Breathing").active){ hitChange = hitChange + 4;}}
        

        // Updates to damage modifier
        var modCalc = parseInt(attackInfo[0].skillMod);
        
        if(attackInfo[0].weaponType == "Unarmed" && character.athleticsExpertise == "Bodybuilder" && character.athleticsLevel == 50){
            modCalc = modCalc + parseInt(character.athleticsMod);
        }


        // Choosing which hit die to use on based on Advantage/Disadvantage
        var finalHitDie = parseInt(attackInputData[currentTab].hitDie);

        if(advantageType == 'advantage'){
            finalHitDie = Math.max(parseInt(attackInputData[currentTab].hitDie), parseInt(attackInputData[currentTab].hitDie2));
        }

        if(advantageType == 'disadvantage'){
            finalHitDie = Math.min(parseInt(attackInputData[currentTab].hitDie), parseInt(attackInputData[currentTab].hitDie2));
        }

        // Final hit calculation
        var hitCalc = finalHitDie + hitChange + parseInt(attackInfo[currentTab].skillMod);



        // Multiplier updates
        var newMult = 1.0;

        //First is spray mult
        if(attackInputData[currentTab].sprayMult){ newMult = parseFloat(attackInputData[currentTab].sprayMult); }

        //Critical roll
        if(finalHitDie >= attackInfo[currentTab].critRequirement){
            newMult = newMult + 1.0;
        }

        // Situation: Sneak Attack
        if(sitchState.find(x => x.name == "Sneak Attack").active){ newMult = newMult + 0.5;}

        // Situation: Damage to KIN
        if(sitchState.find(x => x.name == "Damage to KIN")){ if(sitchState.find(x => x.name == "Damage to KIN").active){ newMult = newMult + 0.5;}}

        // Situation: Heavy Strike (multiplier increase)
        if(sitchState.find(x => x.name == "Heavy Strike")){ if (sitchState.find(x => x.name == "Heavy Strike").active){ newMult = newMult + 1.0;}}
        
        // JSX for attack form
        const attacks = attackInfo.map((att, i) => 
            <div key={i} id={i} className={currentTab === i ? "attackModalWrapper" : "hidden"}>
                <div className="attackModalRow">
                    <span>Roll a d20 to hit:</span>
                    <div>
                        <input type="number" name="hitDie" min={0} max={20} onChange={e => updateAttackInput(i)(e)}/>
                        {advantageType !== 'none' && <input className={advantageType == 'advantage' ? 'greenBord' : 'redBord'} type="number" name="hitDie2" min={0} max={20} onChange={e => updateAttackInput(i)(e)}/>}
                        <p className={attackInfo[i].skillMod + hitChange > attackInfo[i].skillMod ? 'greenBord' : attackInfo[i].skillMod + hitChange < attackInfo[i].skillMod ? 'redBord' : ''}>{
                            attackInfo[i].skillMod + hitChange > -1 
                            ? "+" + (parseInt(attackInfo[i].skillMod) + hitChange) 
                            : parseInt(attackInfo[i].skillMod) + hitChange
                        }</p>
                        <p className="result">{hitCalc}</p>
                    </div>
                </div>
                <div className="hitButs">
                    <div name="hit" className="hitBut hit" onClick={e => updateHitResult(i,'Hit')(e)}>HIT</div>
                    <div className="hitBut miss" onClick={e => updateHitResult(i,'Miss')(e)}>MISS</div>
                </div>
                { attackInputData[i].hitResult === "Hit" && attackInfo[i].weaponType === "Assault Weapon" &&
                    <div className="attackModalRow">
                        Roll a d{sprayDieSize} Spray roll:
                        <div>
                            <input 
                                type="number" 
                                name="sprayDie" 
                                min={0} 
                                max={sprayDieSize} 
                                onChange={e => updateAttackInput(i)(e)} 
                                value={i>0 && attackInputData[i-1].grouping === true ? attackInputData[i-1].sprayDice : null}
                            />
                            <p>{attackInputData[i].sprayPenalty}</p>
                            {attackInfo[i].skillLevel >= 30 && attackInfo[i].skillExpertise === "Special Ops" &&
                                <div>
                                    Grouping:
                                    <input 
                                        type="checkbox" 
                                        name="grouping" 
                                        onChange={e => updateGrouping(i)(e)}
                                        disabled={i>0 && attackInputData[i-1].grouping === true ? true : false}
                                        checked={attackInputData[i].grouping === true && true}
                                    />
                                </div>
                            }
                        </div>
                        
                    </div>
                }
                {
                    (attackInputData[i].hitResult === "Miss" || attackInputData[i].sprayMult === 0) &&
                        <div className="attackModalRow">
                            <div className="missRow">
                                ATTACK MISSED!
                            </div>
                        </div>
                }
                { attackInputData[i].hitResult === "Hit" && (sprayDieSize === 0 || attackInputData[i].sprayMult > 0) &&
                    generateDamDice(i)
                }
                { attackInputData[i].hitResult === "Hit" &&
                    <div className="attackModalTotalRow">
                        <p>Attack {i+1} Damage: </p><p>{newMult != 1 && "("}{attackInputData[i].totalDam}{modCalc > -1 ? "+" + modCalc : modCalc}{newMult != 1 && ") * " + newMult}</p> = <p>{Math.max(Math.floor((attackInputData[i].totalDam + modCalc) * newMult), 0)}</p>
                    </div>
                }
            </div>
        );

        return attacks;
    }

    const calculateTotalDamage = () => {

        var modCalc = parseInt(attackInfo[0].skillMod);
        
        if(attackInfo[0].weaponType == "Unarmed" && character.athleticsExpertise == "Bodybuilder" && character.athleticsLevel == 50){
            modCalc = modCalc + parseInt(character.athleticsMod);
        }
        
        var advantageType = 'none';

        var finalDamage = 0;

        for(var i=0; i<attackInputData.length; i++){

            // Choosing which hit die to use on based on Advantage/Disadvantage
            var finalHitDie = parseInt(attackInputData[i].hitDie);

            if(advantageType == 'advantage'){
                finalHitDie = Math.max(parseInt(attackInputData[i].hitDie), parseInt(attackInputData[i].hitDie2));
            }

            if(advantageType == 'disadvantage'){
                finalHitDie = Math.min(parseInt(attackInputData[i].hitDie), parseInt(attackInputData[i].hitDie2));
            }

            // Multiplier updates
            var newMult = 1.0;

            //First is spray mult
            if(attackInputData[i].sprayMult){ newMult = parseFloat(attackInputData[i].sprayMult); }

            //Critical roll
            if(finalHitDie >= attackInfo[i].critRequirement){
                newMult = newMult + 1.0;
            }

            // Situation: Sneak Attack
            if(sitchState.find(x => x.name == "Sneak Attack").active){ newMult = newMult + 0.5;}

            // Situation: Damage to KIN
            if(sitchState.find(x => x.name == "Damage to KIN")){ if(sitchState.find(x => x.name == "Damage to KIN").active){ newMult = newMult + 0.5;}}

            // Situation: Heavy Strike (multiplier increase)
            if(sitchState.find(x => x.name == "Heavy Strike")){ if (sitchState.find(x => x.name == "Heavy Strike").active){ newMult = newMult + 1.0;}}

            if(attackInputData[i].totalDam > 0){
                finalDamage += Math.floor((attackInputData[i].totalDam + modCalc)*newMult);
            }

            
        }

        finalDamage = Math.max(0, finalDamage);

        return finalDamage;
    }

    const closeAttackModal = () => e => {
        closeModal();
    }

    const showHideSitchPanel = () => e => {
        if(showSitchPanel){
            toggleSitchPanel(false);
        } else {
            toggleSitchPanel(true);
        }
    }

    return (
        <Fragment>
            <div className="attackPanel">
                <div className="attackPanelTop">
                    <h1 className="mar-1">{attackType} Attack</h1>
                    <div className="sitchButton" onClick={showHideSitchPanel(true)}>SITUATIONS</div>
                    <div className='closeAttackModal' onClick={e => closeAttackModal()(e)}>X</div>
                </div>
                
                <div className="attackTabContainer">
                    {generateAttackTabs()}
                </div>
                
                {generateAttackForms()}
                <div className="totalDamageRow">
                    Total Damage: {calculateTotalDamage()}
                </div>
            </div>
            <div className={showSitchPanel ? "sitchPanel" : "sitchPanel hidden"}>
                <h2>SITUATIONS</h2>
                {populateSituations()}
            </div>
        </Fragment>
    )
}

AttackModal.propTypes = {
    character: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    attackType: PropTypes.string.isRequired,
    attackSource: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    character: state.character
});

export default connect(mapStateToProps)(AttackModal);