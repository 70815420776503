import {
    GET_SKILLS,
    SKILL_ERROR
} from '../actions/types';

const initialState = {
    skills: [],
    loading: true,
    error: {}
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_SKILLS:
            return {
                ...state,
                skills: payload,
                loading: false
            };
        case SKILL_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}