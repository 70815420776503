import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCurrentCampaign } from '../../actions/campaign';

const EditCampaign = ({
    campaign: {campaign},
    match,
    getCurrentCampaign
}) => {

    useEffect(() => {
        getCurrentCampaign(match.params.id);
    }, [getCurrentCampaign, match.params.id]);

    if(campaign){
        console.log(campaign.campaignName);
    }
    

    return !campaign ? (
        <Fragment></Fragment>
    ) : (
        <Fragment>
            <div className="editCampaignContainer">
                <div className="campInfo">
                    <h1>{campaign.campaignName}</h1>
                    <p>{campaign.campaignDescription}</p>
                    <div>
                        <p>Privacy: <span>{campaign.privacy}</span></p>
                        <p>Max Players: <span>{campaign.maxPlayers}</span></p>
                        <p>Progression: <span>{campaign.progressionMethod}</span></p>
                        <button>EDIT</button>
                    </div>
                </div>
                <div className="campItem">
                    <div className="campItemButs">
                        <button>INVITE PLAYER</button>
                    </div>
                    <h1>Manage Players</h1>
                    <div>
                        <p>You haven't invited any players yet!</p>
                    </div>
                </div>
                <div className="campItem">
                    <div className="campItemButs">
                        <button>NEW NPC</button>
                    </div>
                    <h1>Manage NPCs</h1>
                    <div>
                        <p>You haven't added any NPCs yet!</p>
                    </div>
                </div>
                <div className="campItem">
                    <div className="campItemButs">
                        <Link to={`/campaign/edit/areas/${match.params.id}`} className="btn">NEW AREA</Link>
                    </div>
                    <h1>Manage Areas</h1>
                    <div>
                        <p>You haven't added any Areas yet!</p>
                    </div>
                </div>
                <div className="campItem">
                    <div className="campItemButs">
                        <button>NEW ENCOUNTER</button>
                    </div>
                    <h1>Manage Encounters</h1>
                    <div>
                        <p>You haven't added any Encounters yet!</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

EditCampaign.propTypes = {
    getCurrentCampaign: PropTypes.func.isRequired,
    campaign: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    campaign: state.campaign
});

export default connect(mapStateToProps, {getCurrentCampaign})(EditCampaign);
