import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createCampaign } from '../../actions/campaign';

import Modal from 'react-modal';

const DashboardActions = ({
    createCampaign,
    campaign: { campaign },
    history
}) => {

    var [createCampModal, toggleCreateCampModal] = useState(false);

    const initialState = {
        campaignCode : "",
        players : [],
        campaignName : "",
        campaignDescription : "",
        privacy : "Private",
        maxPlayers : 6,
        progressionMethod : "Narrative",
        playerLevel : 1,
        areas : [],
        npcs : [],
        icons : [],
        encounters : [],
        musicTracks : [],
        online : false,
        currentArea : "",
        canvasState : {},
        inEncounter : false,
        turnOrder : [],
        currentTurn : ""
    }

    var [currentState, updateCurrentState] = useState(initialState);

    const {
        campaignName,
        campaignDescription,
        privacy,
        maxPlayers,
        progressionMethod,
        playerLevel
    } = currentState;

    const openCreateCampModal = (e) => {
        toggleCreateCampModal(true);
    }
    
    const closeModals = (e) => {
        updateCurrentState(initialState);
        toggleCreateCampModal(false);
    }

    const onChange = e => {
        updateCurrentState({
            ...currentState,
            [e.target.name]: e.target.value
        });
    }

    const createNewCampaign = () => {
        if( currentState.campaignName === ""){
            alert('Please give your campaign a name');
            return;
        }

        createCampaign(currentState, history);

        closeModals();
    }

    return (
        <div className="dash-buttons mary-1 padx-2">
            <Link className="btn" to="/create-character-options" state={{build: ""}}>Create a Character</Link>
            {/*<a href="#" onClick={openCreateCampModal} className="btn">Create a Campaign</a>
            <a href="#" onClick={() => console.log('Join a campaign!')} className="btn">Join a Campaign</a>
            <Modal
                isOpen={createCampModal}
                onRequestClose={closeModals}
                className="campaignModal"
                overlayClassName="campaignOverlay"
            >
                <h1>Create a New Campaign</h1>
                <div>
                    <div className="newCampForm">
                        <div>
                            <h2>Campaign Name</h2>
                        </div>
                        <div>
                            <input type="text" name="campaignName" value={campaignName} onChange={onChange}  />
                        </div>
                    </div>
                    <div className="newCampForm">
                        <div>
                            <h2>Campaign Description</h2>
                        </div>
                        <div>
                            <textarea type="text" name="campaignDescription" value={campaignDescription} onChange={onChange}  />
                        </div>
                    </div>
                    <div className="newCampForm">
                        <div>
                            <h2>Access</h2>
                            <p>Do you want only people with an access code to join the campaign, or do you want it open to the public?</p>
                        </div>
                        <div>
                            <button>Private</button>
                            <button>Public</button>
                        </div>
                    </div>
                    <div className="newCampForm">
                        <div>
                            <h2>Maximum Players</h2>
                            <p>How many players, not including the GM, should be involved with this campaign? Only matters for Public campaigns to ensure a player cap is reached.</p>
                        </div>
                        <div>
                        <select id="maxPlayers" className="maxPlayers" name="maxPlayers" defaultValue={8}>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <option>13</option>
                            <option>14</option>
                            <option>15</option>
                            <option>16</option>
                        </select>
                        </div>
                    </div>
                    <div className="newCampForm">
                        <div>
                            <h2>Progression Method</h2>
                            <p>Field information</p>
                        </div>
                        <div>
                            <button>Narrative</button>
                            <button>Combat</button>
                        </div>
                    </div>
                    <div className="newCampForm">
                        <div>
                            <h2>Starting Level</h2>
                            <p>What level should characters start out at?</p>
                        </div>
                        <div>
                            <select id="startingLevel" className="startingLevel" name="startingLevel" defaultValue={1}>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                                <option>11</option>
                                <option>12</option>
                                <option>13</option>
                                <option>14</option>
                                <option>15</option>
                                <option>16</option>
                                <option>17</option>
                                <option>18</option>
                                <option>19</option>
                                <option>20</option>
                            </select>
                        </div>
                    </div>
                    <div className="newCampButtons">
                        <button onClick={createNewCampaign} className="newCampConfirm">CREATE</button>
                        <button onClick={closeModals} className="newCampCancel">CANCEL</button>
                    </div>
                </div>
            </Modal>*/}
        </div>
        
    )
}

DashboardActions.propTypes = {
    createCampaign: PropTypes.func.isRequired,
    campaign: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    campaign: state.campaign
});

export default connect(mapStateToProps, {createCampaign})(DashboardActions);
