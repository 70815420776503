import React, { useEffect, useState, Fragment} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveCharacter } from '../../../actions/character';


import Modal from 'react-modal';
import ItemStore from '../modals/ItemStore';
import CraftingModal from '../modals/CraftingModal';

const CharEditInventory = ({
    item: {
        items
    },
    character: {
        character
    },
    saveCharacter
}) => {

    var [showBuyModal, toggleBuyModal] = useState(false);
    var [showCraftingModal, toggleCraftingModal] = useState(false);
    var [offhandModal, toggleOffhandModal] = useState({"open": false, "item": {}});

    const showInventory = () => {
        var stackables = character.inventory.filter(i => i.stackable);
        var nonstackables = character.inventory.filter(i => !i.stackable);

        stackables = [...new Map(stackables.map(i => [i['name'], i])).values()];

        stackables = stackables.map((item, index) =>
            <div key={item.name + "" + index} className="itemContainer">
                <div className="itemSubcontainer">
                    <p className="itemName">{item.name}</p>
                    <p className="itemType">{item.type}</p>
                    <p className="itemType">{item.subtype}</p>
                </div>
                <div className="itemDesc">
                    <div><p>{item.description}</p></div>
                </div>
                <div className="quantMod">Qty: <span>{character.inventory.filter(i => i.name === item.name).length}</span></div>
                <div className="inventoryOptions">
                    <div>
                        <button name="sell" onClick={e => removeItem(item)(e)}>Sell</button>
                        <button name="drop" onClick={e => removeItem(item)(e)}>Drop</button>
                    </div>
                </div>
                
            </div>
        );

        nonstackables = nonstackables.map((item, index) =>
            <div key={item.name + "" + index} className={`itemContainer + ${item.equipped && "equipped"}`}>
                <div className="itemSubcontainer">
                    <p className="itemName">{item.name}</p>
                    <p className="itemType">{item.type}</p>
                    <p className="itemType">{item.subtype}</p>
                </div>
                <div className="itemDesc">
                    {item.classification === "Armor" ?
                        <div>
                            <p>DIS: {item.dis}</p>
                            <p>KIN: {item.kin}</p>
                            {(item.type === "Gloves" || item.type === "Combat Gloves" || item.type === "Power Gaunlet") && <p>Damage: {item.numDice}d{item.diceSize}</p>}
                            <p>Mods: 0/{item.modSlots}</p></div>
                    : item.classification === "Weapon" ?
                        <div>
                            <p>Damage: {item.numDice}d{item.diceSize}</p>
                            {(item.type === "Assault Weapon" || item.type === "Long Range" || item.type === "Pistol") &&
                            <div>
                                <p>Range: {item.range}m</p>
                                <p>Mag: {item.currentAmmo}/{item.magSize}</p>
                            </div>
                            }
                            <p>Mods: {item.equippedMods.length}/{item.modSlots}</p>
                        </div>
                    : <div><p>{item.description}</p></div>
                    }
                </div>
                <div className="inventoryOptions">
                    {(item.classification === "Weapon" || "Armor") && 
                        <div>
                            {!item.equipped ? 
                                <button onClick={e => {
                                        item.subtype === "Sidearm" || item.subtype === "Bolt Pistol" || item.subtype === "Hand Cannon" || item.subtype === "Dagger" || item.subtype === "Short Blade" || item.subtype === "Baton" || item.subtype === "Mace" ?
                                        openOffhandModal(item)(e)
                                    :
                                        equipItem(character.inventory.findIndex(i => i == item), item)(e)
                                    }
                                }>Equip</button>
                                :
                                <button onClick={e => unequipItem(item.equipped)(e)}>Unequip</button>
                            }
                        </div>
                    }
                    <div>
                        <button name="sell" onClick={e => removeItem(item)(e)}>Sell</button>
                        <button name="drop" onClick={e => removeItem(item)(e)}>Drop</button>
                    </div>
                </div>
            </div>
        );

        const displayedItems = [...stackables, ...nonstackables];

        return displayedItems;
    }

    const removeItem = (item) => (e) => {
        
        character.inventory.splice(character.inventory.findIndex(x => x.name === item.name),1);

        if(e.target.name === "sell"){
            saveCharacter({
                ...character,
                "inventory": character.inventory,
                "funds": character.funds + item.value,
                "currentCapacity": character.currentCapacity - parseFloat(item.weight.$numberDecimal)
            });
        } else {
            saveCharacter({
                ...character,
                "inventory": character.inventory,
                "currentCapacity": character.currentCapacity - parseFloat(item.weight.$numberDecimal)
            });
        }


    }

    const equipItem = (ind, item, offhand = false) => (e) => {

        closeModals();

        switch(item.type){
            case "Helmet":
                if(character.inventory.findIndex(i => i.equipped === "Head") > -1){
                    character.inventory[character.inventory.findIndex(i => i.equipped === "Head")].equipped = "";
                };
                character.inventory[ind].equipped = "Head";
                break;
            case "Body":
                if(character.inventory.findIndex(i => i.equipped === "Body") > -1){
                    character.inventory[character.inventory.findIndex(i => i.equipped === "Body")].equipped = "";
                };
                character.inventory[ind].equipped = "Body";
                break;
            case "Gloves":
            case "Combat Gloves":

                if(character.inventory.findIndex(i => i.equipped === "Hands") > -1){
                    character.inventory[character.inventory.findIndex(i => i.equipped === "Hands")].equipped = "";
                };
                character.inventory[ind].equipped = "Hands";
                break;
            case "Boots":
                if(character.inventory.findIndex(i => i.equipped === "Feet") > -1){
                    character.inventory[character.inventory.findIndex(i => i.equipped === "Feet")].equipped = "";
                };
                character.inventory[ind].equipped = "Feet";
                break;
            case "Shield":
                break;
            case "Assault Weapon":
            case "Explosives":
            case "Long Range":
                if(character.inventory.findIndex(i => i.equipped === "Main Hand") > -1){
                    character.inventory[character.inventory.findIndex(i => i.equipped === "Main Hand")].equipped = "";
                };
                if(character.inventory.findIndex(i => i.equipped === "Off Hand") > -1){
                    character.inventory[character.inventory.findIndex(i => i.equipped === "Off Hand")].equipped = "";
                };
                character.inventory[ind].equipped = "Main Hand";
                break;
            case "Blade":
                if(item.subtype === "Long Blade"){
                    if(character.inventory.findIndex(i => i.equipped === "Main Hand") > -1){
                        character.inventory[character.inventory.findIndex(i => i.equipped === "Main Hand")].equipped = "";
                    };
                    if(character.inventory.findIndex(i => i.equipped === "Off Hand") > -1){
                        character.inventory[character.inventory.findIndex(i => i.equipped === "Off Hand")].equipped = "";
                    };
                    character.inventory[ind].equipped = "Main Hand";
                } else if(offhand){
                    if(character.inventory.findIndex(i => i.equipped === "Off Hand") > -1){
                        character.inventory[character.inventory.findIndex(i => i.equipped === "Off Hand")].equipped = "";
                    };
                    character.inventory[ind].equipped = "Off Hand";
                } else {
                    if(character.inventory.findIndex(i => i.equipped === "Main Hand") > -1){
                        character.inventory[character.inventory.findIndex(i => i.equipped === "Main Hand")].equipped = "";
                    };
                    character.inventory[ind].equipped = "Main Hand";
                }
                break;
            case "Blunt":
                if(item.subtype === "Warhammer"){
                    if(character.inventory.findIndex(i => i.equipped === "Main Hand") > -1){
                        character.inventory[character.inventory.findIndex(i => i.equipped === "Main Hand")].equipped = "";
                    };
                    if(character.inventory.findIndex(i => i.equipped === "Off Hand") > -1){
                        character.inventory[character.inventory.findIndex(i => i.equipped === "Off Hand")].equipped = "";
                    };
                    character.inventory[ind].equipped = "Main Hand";
                } else if(offhand){
                    if(character.inventory.findIndex(i => i.equipped === "Off Hand") > -1){
                        character.inventory[character.inventory.findIndex(i => i.equipped === "Off Hand")].equipped = "";
                    };
                    character.inventory[ind].equipped = "Off Hand";
                } else {
                    if(character.inventory.findIndex(i => i.equipped === "Main Hand") > -1){
                        character.inventory[character.inventory.findIndex(i => i.equipped === "Main Hand")].equipped = "";
                    };
                    character.inventory[ind].equipped = "Main Hand";
                }
                break;
            case "Pistol": {
                if(offhand){
                    if(character.inventory.findIndex(i => i.equipped === "Off Hand") > -1){
                        character.inventory[character.inventory.findIndex(i => i.equipped === "Off Hand")].equipped = "";
                    };
                    character.inventory[ind].equipped = "Off Hand";
                } else {
                    if(character.inventory.findIndex(i => i.equipped === "Main Hand") > -1){
                        character.inventory[character.inventory.findIndex(i => i.equipped === "Main Hand")].equipped = "";
                    };
                    character.inventory[ind].equipped = "Main Hand";
                }
                break;
            }
            default:
                break;
        }

        var recalcDIS = 0;
        var recalcKIN = 0;

        for(var i=0; i < character.inventory.length; i++){
            if(character.inventory[i].classification === 'Armor' && character.inventory[i].equipped !== ''){
                recalcDIS += character.inventory[i].dis;
                recalcKIN += character.inventory[i].kin;
            }
        }

        saveCharacter({
            ...character,
            "currentDIS": recalcDIS,
            "baseDIS": recalcDIS,
            "currentKIN": recalcKIN,
            "baseKIN": recalcKIN,
            "inventory": character.inventory
        });
    }

    const unequipItem = (slot) => (e) => {
        character.inventory[character.inventory.findIndex(i => i.equipped === slot)].equipped = "";

        var recalcDIS = 0;
        var recalcKIN = 0;

        for(var i=0; i < character.inventory.length; i++){
            if(character.inventory[i].classification === 'Armor' && character.inventory[i].equipped !== ''){
                recalcDIS += character.inventory[i].dis;
                recalcKIN += character.inventory[i].kin;
            }
        }

        saveCharacter({
            ...character,
            "currentDIS": recalcDIS,
            "baseDIS": recalcDIS,
            "currentKIN": recalcKIN,
            "baseKIN": recalcKIN,
            "inventory": character.inventory
        });
    }

    const openBuyModal = (e) => {
        toggleBuyModal(true);
    }

    const openCraftingModal = (e) => {
        toggleCraftingModal(true);
    }

    const openOffhandModal = (item) => (e) => {
        toggleOffhandModal({"open": true, "item": item});
    }

    const closeModals = (e) => {
        toggleBuyModal(false);
        toggleCraftingModal(false);
        toggleOffhandModal({"open": false, "item": {}});
    }

    const {
        funds,
        currentCapacity,
        maxCapacity
    } = character;

    const onChange = e => {
        saveCharacter({
            ...character,
            [e.target.name]: e.target.value
        }, items);
    }

    return (
        <Fragment>
            <div className="char-sheet-inventory-panel">
                <div className="inventory-top-container">
                    <div className="inventory-top-subcontainer">
                        <div className="funds-container">
                            <h1>FUNDS</h1>
                            <input type="number" name="funds" value={funds} onChange={onChange}/>
                        </div>
                        <div className="capacity-container">
                            <h1>CARRYING CAPACITY</h1>
                            
                            <div className="capacity-border">
                                <div className="capacity-fill" style={{width: currentCapacity < maxCapacity ? currentCapacity/maxCapacity*300 + 'px' : 300 + 'px'}}></div>
                                <input type="number" className={currentCapacity > maxCapacity && 'text-danger'} name='currentCapacity' value={currentCapacity}/>/<input type="number" name='maxCapacity' value={maxCapacity} onChange={onChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="action-container">
                        <h1>ACTIONS</h1>
                        <div className="action-button-container">
                            <button name="buy" onClick={openBuyModal}>GET ITEMS</button>
                            <button name="craft" onClick={openCraftingModal}>CRAFTING</button>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={showBuyModal}
                    onRequestClose={closeModals}
                    className="itemStore"
                    overlayClassName="itemStoreOverlay"
                >
                    <ItemStore closeStore={closeModals} />
                </Modal>
                <Modal
                    isOpen={showCraftingModal}
                    onRequestClose={closeModals}
                    className="itemStore"
                    overlayClassName="itemStoreOverlay"
                >
                    <CraftingModal closeStore={closeModals} />
                </Modal>
                <Modal
                    isOpen={offhandModal.open}
                    onRequestClose={closeModals}
                    className="offhandModal"
                    overlayClassName="offhandOverlay"
                >
                    <h1>Choose where to equip this item:</h1>
                    <div>
                        <button onClick={e => equipItem(character.inventory.findIndex(i => i == offhandModal.item), offhandModal.item)(e)}>Main Hand</button>
                        <button onClick={e => equipItem(character.inventory.findIndex(i => i == offhandModal.item), offhandModal.item, true)(e)}>Off Hand</button>
                    </div>
                </Modal>
                <h1 className="lead text-blue">INVENTORY</h1>
                <div className="itemList">
                    {showInventory()}
                </div>
                
            </div>
        </Fragment>
    )
}

CharEditInventory.propTypes = {
    character: PropTypes.object.isRequired,
    saveCharacter: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    character: state.character,
    item: state.item
});

export default connect(mapStateToProps, {saveCharacter})(CharEditInventory);
