import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Components
import PrivateRoutes from './components/routing/PrivateRoute';
import Navbar from './components/layout/Navbar';
import Landing from './components/layout/Landing';
import Alert from './components/layout/Alert';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import PasswordReset from './components/auth/PasswordReset';
import Dashboard from './components/dashboard/Dashboard';
import UpdateProfile from './components/profile/UpdateProfile';
import CharCreationOptions from './components/dashboard/CharCreationOptions';
import CreateCharacter from './components/character/creation/CreateCharacter';
import PrebuiltList from './components/dashboard/PrebuiltList';
import ViewCharacter from './components/character/view/ViewCharacter';
import EditCharacter from './components/character/edit/EditCharacter';
import EditCampaign from './components/campaign/EditCampaign';
import EditCampaignAreas from './components/campaign/EditCampaignAreas';
import EditMap from './components/campaign/EditMap';
import WikiHome from './components/wiki/WikiHome';
import CreateWikiPage from './components/wiki/CreateWikiPage';

//Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import './App.css';


if(localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => { 

  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  
  return(
  <Provider store={store}>
  <Router>
    <Fragment>
      <Navbar />
      <section className="container">
        <Alert />
        <Routes>
          <Route exact path="/" element={<Landing/>} />
          <Route exact path="/register" element={<Register/>} />
          <Route exact path="/login" element={<Login/>} />
          <Route exact path="/reset/:token" element={<PasswordReset/>} />
          <Route element={<PrivateRoutes />}>
            <Route exact path="/dashboard" element={<Dashboard/>} />
            <Route exact path="/update-profile" element={<UpdateProfile/>} />
            <Route exact path="/create-character-options" element={<CharCreationOptions/>} />
            <Route exact path="/create-character" element={<CreateCharacter/>} />
            <Route exact path="/prebuilt-characters" element={<PrebuiltList/>} />
            <Route exact path="/character/view/:id" element={<ViewCharacter/>} />
            <Route exact path="/character/edit/:id" element={<EditCharacter/>} />
            <Route exact path="/campaign/edit/:id" element={<EditCampaign/>} />
            <Route exact path="/campaign/edit/areas/:id" element={<EditCampaignAreas/>} />
            <Route exact path="/campaign/edit/areas/editMap/:id" element={<EditMap/>} />
            <Route exact path="/wiki" element={<WikiHome/>} />
            <Route exact path="/wiki/create-new-page" element={<CreateWikiPage/>} />
          </Route>
          
        </Routes>
      </section>
    </Fragment>
  </Router>
  </Provider>
  
)};

/* 
Old Private Routes
<PrivateRoute exact path="/dashboard" element={<Dashboard/>} />
<PrivateRoute exact path="/update-profile" element={<UpdateProfile/>} />
<PrivateRoute exact path="/create-character" element={<CreateCharacter/>} />
<PrivateRoute exact path="/prebuilt-characters" element={<PrebuiltList/>} />
<PrivateRoute exact path="/character/view/:id" element={<ViewCharacter/>} />
<PrivateRoute exact path="/character/edit/:id" element={<EditCharacter/>} />
<PrivateRoute exact path="/campaign/edit/:id" element={<EditCampaign/>} />
<PrivateRoute exact path="/campaign/edit/areas/:id" element={<EditCampaignAreas/>} />
<PrivateRoute exact path="/campaign/edit/areas/editMap/:id" element={<EditMap/>} />
*/

export default App;
