import React, { Fragment, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//Actions
import {
    createCharacter
} from '../../../actions/character';


//Components
import CreateCharSpecies from './CreateCharSpecies';
import CharCreationVitals from './CharCreationVitals';
import CharCreationBackground from './CharCreationBackground';
import CharCreationSkills from './CharCreationSkills';
import CharCreationAttributes from './CharCreationAttributes';

const initialState = {
    name: "",
    race: "",
    level: 1,
    gender: "",
    height: "",
    physique: "",
    languages: ["Uni"],
    alignment: "",
    personality: "",
    motivation: "",
    fears: "",
    backstory: "",
    attributePoints: 0,
    STR: 10,
    CON: 10,
    DEX: 10,
    INT: 10,
    WIS: 10,
    CHA: 10,
    initiative: 0,
    passivePerception: 0,
    movement: 10,
    currentHP: 10,
    baseHP: 10,
    currentDIS: 0,
    baseDIS: 0,
    currentKIN: 0,
    baseKIN: 0,
    TARG: 10,
    EVA: 6,
    statusEffects: [],
    weaknesses: [],
    resistances: [],
    immunities: [],
    magazines: 0,
    head: {
        name: '',
        armorType: '',
        dis: 0,
        kin: 0,
        effects: '',
        modSlots: 0,
        mod1: '',
        mod2: '',
        mod3: ''
    },
    chest: {
        name: '',
        armorType: '',
        dis: 0,
        kin: 0,
        effects: '',
        modSlots: 0,
        mod1: '',
        mod2: '',
        mod3: ''
    },
    hands: {
        name: '',
        armorType: '',
        dis: 0,
        kin: 0,
        effects: '',
        modSlots: 0,
        mod1: '',
        mod2: '',
        mod3: ''
    },
    feet: {
        name: '',
        armorType: '',
        dis: 0,
        kin: 0,
        effects: '',
        modSlots: 0,
        mod1: '',
        mod2: '',
        mod3: ''
    },
    shield: {
        name: '',
        armorType: '',
        dis: 0,
        kin: 0,
        effects: '',
        modSlots: 0,
        mod1: '',
        mod2: '',
        mod3: ''
    },
    mainHand: {
        name: '',
        weaponClass: '',
        weaponType: '',
        dd: '',
        rng: 0,
        currentAmmo: 0,
        maxAmmo: 0,
        effects: '',
        modSlots: 0,
        mod1: '',
        mod2: '',
        mod3: ''
    },
    offHand: {
        name: '',
        weaponClass: '',
        weaponType: '',
        dd: '',
        rng: 0,
        currentAmmo: 0,
        maxAmmo: 0,
        effects: '',
        modSlots: 0,
        mod1: '',
        mod2: '',
        mod3: ''
    },
    rechargeAvailable: 0,
    psycheStim: { name: '', totalCharges: 0 },
    psycheCharges: 0,
    energyStim: { name: '', totalCharges: 0 },
    energyCharges: 0,
    fluidStim: { name: '', totalCharges: 0 },
    fluidCharges: 0,
    waveStim: { name: '', totalCharges: 0 },
    waveCharges: 0,
    gravStim: { name: '', totalCharges: 0 },
    gravCharges: 0,
    skillPoints: 0,
    armorLevel: 0,
    armorMod: -2,
    armorExpertise: "Light Armor",
    assaultWeaponLevel: 0,
    assaultWeaponMod: -2,
    assaultWeaponExpertise: "Bullet Hell",
    astrobiologyLevel: 0,
    astrobiologyMod: -2,
    astrobiologyExpertise: "Botanist",
    athleticsLevel: 0,
    athleticsMod: -2,
    athleticsExpertise: "Sprinter",
    bladeLevel: 0,
    bladeMod: -2,
    bladeExpertise: "Fencer",
    bluntLevel: 0,
    bluntMod: -2,
    bluntExpertise: "Bruiser",
    energyLevel: 0,
    energyMod: -2,
    energyExpertise: "Fire",
    espionageLevel: 0,
    espionageMod: -2,
    espionageExpertise: "Operative",
    explosivesLevel: 0,
    explosivesMod: -2,
    explosivesExpertise: "Pyromaniac",
    fluidLevel: 0,
    fluidMod: -2,
    fluidExpertise: "Flood",
    gravLevel: 0,
    gravMod: -2,
    gravExpertise: "Singularity",
    longRangeLevel: 0,
    longRangeMod: -2,
    longRangeExpertise: "Sniper",
    negotiationLevel: 0,
    negotiationMod: -2,
    negotiationExpertise: "Hustler",
    observationLevel: 0,
    observationMod: -2,
    observationExpertise: "Insight",
    pistolLevel: 0,
    pistolMod: -2,
    pistolExpertise: "Duelist",
    psycheLevel: 0,
    psycheMod: -2,
    psycheExpertise: "Mentalist",
    techLevel: 0,
    techMod: -2,
    techExpertise: "Hacker",
    unarmedLevel: 0,
    unarmedMod: -2,
    unarmedExpertise: "Brawler",
    vehiclesLevel: 0,
    vehiclesMod: -2,
    vehiclesExpertise: "Driver",
    waveLevel: 0,
    waveMod: -2,
    waveExpertise: "Light",
    funds: 0,
    currentCapacity: 0,
    maxCapacity: 30,
    inventory: []
}

const CreateCharacter = ({
    createCharacter,
    character: { character },
    history
}) => {

    const [tabIndex, setTabIndex] = useState(0);

    const changeTab = index => {
        if (character && index === 0) {
            var conf = window.confirm('Going back to the species screen will reset your character. Is this what you want?');
            if (conf) {
                window.location.reload();
            } else return;
        }

        if (!character && index !== 0) {
            alert("Please select a species before proceeding");
        } else {
            setTabIndex(index);
            window.scrollTo(0, 0);
        }
    };

    const submitCharacter = () => {

        if (!character || !character.race) {
            alert("You haven't even started yet! Maybe try building a character first.");
            return;
        }

        if (!character.name) {
            alert("Name is a required field");
            changeTab(1);
            setTimeout(() => document.getElementById('char-name').scrollIntoView(), 200);
            return;
        }

        if (!character.height || !character.physique) {
            alert("Height and physique are both required");
            changeTab(1);
            setTimeout(() => document.getElementById('height-and-physique').scrollIntoView(), 200);
            return;
        }

        if (!character.alignment) {
            alert("Please select an alignment for your character.");
            changeTab(1);
            setTimeout(() => document.getElementById('alignment').scrollIntoView(), 200);
            return;
        }

        if (character.attributePoints > 0) {
            alert("You still have unspent attribute points.");
            changeTab(3);
            return;
        }

        if (character.skillPoints > 0) {
            alert("You still have unspent skill points.");
            changeTab(4);
            return;
        }

        createCharacter(character, history);
    }

    return (
        <Fragment>
            <Tabs selectedIndex={tabIndex} onSelect={index => changeTab(index)}>
                <TabList className="creation-menu">
                    <Tab className="creation-menu-tab">Species</Tab>
                    <Tab className="creation-menu-tab">Vitals</Tab>
                    <Tab className="creation-menu-tab">Background</Tab>
                    <Tab className="creation-menu-tab">Attributes</Tab>
                    <Tab className="creation-menu-tab">Skills</Tab>
                </TabList>
                <TabPanel>
                    <CreateCharSpecies currentState={initialState} raceSelected={changeTab} />
                </TabPanel>
                <TabPanel>
                    <CharCreationVitals currentState={character} />
                </TabPanel>
                <TabPanel>
                    <CharCreationBackground currentState={character} />
                </TabPanel>
                <TabPanel>
                    <CharCreationAttributes currentState={character} />
                </TabPanel>
                <TabPanel>
                    <CharCreationSkills currentState={character} />
                </TabPanel>
            </Tabs>
            <div className="char-creation-footer">
                {tabIndex !== 0 ? (
                    <button onClick={() => changeTab(tabIndex - 1)}>PREV</button>
                ) : <div></div>}
                <button className="submit" onClick={submitCharacter}>SUBMIT CHARACTER</button>
                {tabIndex !== 4 ? (
                    <button onClick={() => changeTab(tabIndex + 1)}>NEXT</button>
                ) : <div></div>}
            </div>
        </Fragment>


    );

};

CreateCharacter.propTypes = {
    createCharacter: PropTypes.func.isRequired,
    character: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    character: state.character
});

export default connect(mapStateToProps, { createCharacter })(CreateCharacter);
