import React, { Fragment} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveCharacter } from '../../../actions/character';

const CharCreationBackground = ({
    currentState,
    saveCharacter
}) => {

    const {
        personality,
        motivation,
        fears,
        backstory
    } = currentState;

    const onChange = e => {
        saveCharacter({...currentState, [e.target.name]: e.target.value});
    }

    return ( <Fragment>
        <div className="char-creation-background-panel">
            <h1>PERSONALITY</h1>
            <textarea name="personality" cols="30" rows="3" onChange={onChange}>{personality}</textarea>
            <h1>MOTIVATION</h1>
            <textarea name="motivation" cols="30" rows="3" onChange={onChange}>{motivation}</textarea>
            <h1>FEARS</h1>
            <textarea name="fears" cols="30" rows="3" onChange={onChange}>{fears}</textarea>
            <h1>BACKSTORY</h1>
            <textarea name="backstory"cols="30" rows="10" onChange={onChange}>{backstory}</textarea>
        </div>
    </Fragment>
    )
}

CharCreationBackground.propTypes = {
    saveCharacter: PropTypes.func.isRequired,
    currentState: PropTypes.object.isRequired
};

export default connect(null, {saveCharacter})(CharCreationBackground);
