export class Square {
    constructor(index, x, y, squareSize){
        //Base stats
        this.index = index;
        this.x = x;
        this.y = y;
        this.centerX = x * squareSize + squareSize/2;
        this.centerY = y * squareSize + squareSize/2;

        //Pathfinding stuff
        this.neighbors = [];
        this.parent = null;
        this.moveNode = false;
        this.G_score = 0;
        this.cost = 1;

        //Other attributes
        this.occupied = false;
        this.terrainType = "easy";
    }
};