import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteCampaign } from '../../actions/campaign';

const DashCampItem = ({
    campaign,
    user
}) => {

    console.log(campaign.campaignName, user);

    return (
        <div className="dash-char-item">
            <div className="dash-info">
                {campaign.campaignName}
            </div>
            <div className="dash-char-buttons">
                <Link to={`/campaign/edit/${campaign._id}`} className="char-link">EDIT</Link>
            </div>
        </div>
    )
}

DashCampItem.propTypes = {
    campaign: PropTypes.object.isRequired,
    deleteCampaign: PropTypes.func.isRequired
}

export default connect(null, {deleteCampaign})(DashCampItem);
