import React, { Fragment} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { calculateSkillModifiers} from '../../../utils/calculations';
import { skills } from '../../../resources/skills';

const CharViewSkills = ({
    character: {
        character
    }
}) => {

    const {
        STR,
        DEX,
        CON,
        INT,
        WIS,
        CHA,
        armorLevel,
        armorExpertise,
        assaultWeaponLevel,
        assaultWeaponExpertise,
        astrobiologyLevel,
        astrobiologyExpertise,
        athleticsLevel,
        athleticsExpertise,
        bladeLevel,
        bladeExpertise,
        bluntLevel,
        bluntExpertise,
        energyLevel,
        energyExpertise,
        espionageLevel,
        espionageExpertise,
        explosivesLevel,
        explosivesExpertise,
        fluidLevel,
        fluidExpertise,
        gravLevel,
        gravExpertise,
        longRangeLevel,
        longRangeExpertise,
        negotiationLevel,
        negotiationExpertise,
        observationLevel,
        observationExpertise,
        pistolLevel,
        pistolExpertise,
        psycheLevel,
        psycheExpertise,
        techLevel,
        techExpertise,
        unarmedLevel,
        unarmedExpertise,
        vehiclesLevel,
        vehiclesExpertise,
        waveLevel,
        waveExpertise
    } = character;

    let charPropArray = Object.keys(character);
    charPropArray = charPropArray.slice(charPropArray.length - 60);

    const levelArray = [
        armorLevel,
        assaultWeaponLevel,
        astrobiologyLevel,
        athleticsLevel,
        bladeLevel,
        bluntLevel,
        energyLevel,
        espionageLevel,
        explosivesLevel,
        fluidLevel,
        gravLevel,
        longRangeLevel,
        negotiationLevel,
        observationLevel,
        pistolLevel,
        psycheLevel,
        techLevel,
        unarmedLevel,
        vehiclesLevel,
        waveLevel
    ];

    

    const expertiseArray = [
        armorExpertise,
        assaultWeaponExpertise,
        astrobiologyExpertise,
        athleticsExpertise,
        bladeExpertise,
        bluntExpertise,
        energyExpertise,
        espionageExpertise,
        explosivesExpertise,
        fluidExpertise,
        gravExpertise,
        longRangeExpertise,
        negotiationExpertise,
        observationExpertise,
        pistolExpertise,
        psycheExpertise,
        techExpertise,
        unarmedExpertise,
        vehiclesExpertise,
        waveExpertise
    ];

    const modArray = calculateSkillModifiers(
        skills, 
        levelArray, 
        {
            'STR': STR,
            'DEX': DEX,
            'CON': CON,
            'INT': INT,
            'WIS': WIS,
            'CHA': CHA
        },
        expertiseArray
    );

    const createSkillTable = skills => {

        const skillRows = skills.map((s, index) => 
            <div key={index} id={index} className='skill-row'  >
                <div className="skill-name">{s.baseSkill.name}</div>
                <div className="skill-mod">
                    <div name={charPropArray[1 + 3*index]}>{modArray[index]}</div>
                </div>
                <div className="skill-att">{s.baseSkill.main_attr}</div>
                <div className="skill-level">
                    <div className="skill-level-value">{levelArray[index]}</div>
                </div>
                
                <div className="skill-level-chart">
                    <div data-for={`basePerkInfo${s.baseSkill.name}`} data-tip='0' className={levelArray[index] > 0 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 1 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 2 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 3 ? "skill-mark filled" : "skill-mark"}></div>
                    <div data-for={`basePerkInfo${s.baseSkill.name}`} data-tip='1' className={levelArray[index] > 4 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 5 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 6 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 7 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 8 ? "skill-mark filled" : "skill-mark"}></div>
                    <div data-for={`basePerkInfo${s.baseSkill.name}`} data-tip='2' className={levelArray[index] > 9 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 10 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 11 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 12 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 13 ? "skill-mark filled" : "skill-mark"}></div>
                    <div data-for={`basePerkInfo${s.baseSkill.name}`} data-tip='3' className={levelArray[index] > 14 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 15 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 16 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 17 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 18 ? "skill-mark filled" : "skill-mark"}></div>
                    <div data-for={`basePerkInfo${s.baseSkill.name}`} data-tip='4' className={levelArray[index] > 19 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 20 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 21 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 22 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 23 ? "skill-mark filled" : "skill-mark"}></div>
                    <div data-for={`expPerkInfo${s.baseSkill.name}`} data-tip='0' className={levelArray[index] > 24 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 25 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 26 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 27 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 28 ? "skill-mark filled" : "skill-mark"}></div>
                    <div data-for={`expPerkInfo${s.baseSkill.name}`} data-tip='1' className={levelArray[index] > 29 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 30 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 31 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 32 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 33 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 34 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 35 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 36 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 37 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 38 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 39 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 40 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 41 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 42 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 43 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 44 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 45 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 46 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 47 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 48 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 49 ? "perk filled" : "perk"}></div>
                    <ReactTooltip 
                        id={`basePerkInfo${s.baseSkill.name}`}
                        className='skillTooltip'
                        place='right'
                        effect='solid'
                        getContent={(dataTip) => 
                            <Fragment>
                                <h1>{dataTip && s.baseSkill.perks[dataTip].perk_name}</h1>
                                <div><p>Perk Description</p>{dataTip && s.baseSkill.perks[dataTip].pk_desc}</div>
                                <div><p>Action Description</p>{dataTip && s.baseSkill.perks[dataTip].action_desc && s.baseSkill.perks[dataTip].action_desc}</div>
                                <div><p>Action Type</p>{dataTip && s.baseSkill.perks[dataTip].action_type && s.baseSkill.perks[dataTip].action_type}</div>
                            </Fragment>
                            
                        } 
                    />
                    <ReactTooltip 
                        id={`expPerkInfo${s.baseSkill.name}`}
                        className='skillTooltip'
                        place='right'
                        effect='solid'
                        getContent={(dataTip) =>
                            <Fragment>
                                <h1>{dataTip ? (expertiseArray[index] === s.expertise1.name ? s.expertise1.perks[dataTip].perk_name : s.expertise2.perks[dataTip].perk_name) : ''}</h1>
                                <div><p>Perk Description</p>{dataTip ? (expertiseArray[index] === s.expertise1.name ? s.expertise1.perks[dataTip].pk_desc : s.expertise2.perks[dataTip].pk_desc) : ''}</div>
                                <div>
                                    <p>Action Description</p>{ dataTip ? (expertiseArray[index] === s.expertise1.name ? s.expertise1.perks[dataTip].action_desc : s.expertise2.perks[dataTip].action_desc) : ''}
                                </div>
                                <div><p>Action Type</p>{dataTip ? (expertiseArray[index] === s.expertise1.name ? s.expertise1.perks[dataTip].action_type : s.expertise2.perks[dataTip].action_type) : ''}</div>
                            </Fragment>
                        } 
                    />
                </div>
                <div className="expertise_container">
                    <select name={charPropArray[2 + index*3]} className="expertise_select" defaultValue={expertiseArray[index]}>
                        <option>{s.expertise1.name}</option>
                        <option>{s.expertise2.name}</option>
                    </select>   
                </div>
            </div>
        )

        return skillRows;
    };

    return ( 
        <Fragment>
            <div className="char-sheet-skill-panel">
                <div className="skill-header">
                    <div className="skill-name">SKILL<br />NAME</div>
                    <div className="skill-mod">MOD</div>
                    <div className="skill-att">MAIN ATTR.</div>
                    <div className="skill-level">LEVEL</div>
                    <div className="skill-level-chart">PERK CHART</div>
                    <div className="expertise_container">EXPERTISE</div>
                </div>
                {createSkillTable(skills)}
            </div>
        </Fragment>
    );
};

CharViewSkills.propTypes = {
    character: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    character: state.character
});

export default connect(mapStateToProps)(CharViewSkills);
