import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { marked } from 'marked';

const CreateWikiPage = ({
    
}) => {

    var [currentEntry, updateCurrentEntry] = useState("");

    const onChange = e => {
        var newInput = e.target.value;

        updateCurrentEntry(newInput);
    }

    const rawMarkup = entry => {
        let rawMarkup = marked(entry);
        return { __html: rawMarkup };
    }

    return (
        <Fragment>
            <div className="wikiCreateContainer">
                <div className="wikiCreateSubcontainer">
                    <label className="text-blue" for="wikiMarkdownEditor">Enter Markdown Here:</label>
                    <textarea rows="50" className="wikiMarkdownEditor" name="wikiMarkdownEditor" onChange={onChange} />
                </div>
                <div className="wikiCreateSubcontainer">
                    <div className="wikiMarkdownViewer" dangerouslySetInnerHTML={rawMarkup(currentEntry)}></div>
                </div>
            </div>
        </Fragment>
    )
}

CreateWikiPage.propTypes = {
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, {})(CreateWikiPage);
