import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

//Actions
import { getMyCharacters } from '../../actions/character';
import { getMyCampaigns } from '../../actions/campaign';

//Components
import DashboardActions from  './DashboardActions';
import DashCharItem from './DashCharItem';
import DashCampItem from './DashCampItem';


const Dashboard = ({
    auth: {user, loading},
    getMyCharacters,
    getMyCampaigns,
    character,
    campaign
}) => {
    
    //Updated the auth reducer to run User loaded after auth success
    useEffect(() => {
        if(user && !loading){
            if(character.loading || character.character){
                getMyCharacters();
            }
            if(campaign.loading || campaign.campaign){
                console.log('here');;
                getMyCampaigns();
            }
        }
    }, [getMyCharacters, getMyCampaigns, character, campaign, user, loading]);

    console.log(process.env.ENVIRONMENT)

    return character.loading || character.character || campaign.loading || campaign.campaign ? (
        <Fragment>

        </Fragment>
    ) : (
        <Fragment>
            <h1 className="large text-light2 mar-2">Welcome {user && user.username} </h1>
            <DashboardActions />
            {!campaign.loading && (
                <div className="dash-char-list mary-2 pad-1">
                    <h2 className="text-light2">Your Campaigns</h2>
                    {campaign.campaigns.length > 0 ? (
                        campaign.campaigns.map(campaign => (
                            <DashCampItem key={campaign._id} campaign={campaign} user={user} />
                        ))
                    ) : (
                        <h4 className="text-orange">You are not involved with any campaigns yet!</h4>
                    )}
                </div>
            )}
            {!character.loading && (
                <div className="dash-char-list mary-2 pad-1">
                    <h2 className="text-light2">Your Characters</h2>
                    {character.characters.length > 0 ? (
                        character.characters.map(character => (
                            <DashCharItem key={character._id} character={character} />
                        ))
                    ) : (
                        <h4 className="text-orange">You have not created any characters yet!</h4>
                    )}
                </div>
            )}
            
        </Fragment>
    );
};

Dashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    getMyCharacters: PropTypes.func.isRequired,
    getMyCampaigns: PropTypes.func.isRequired,
    character: PropTypes.object.isRequired,
    campaign: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    character: state.character,
    campaign: state.campaign
});

export default connect(mapStateToProps, {getMyCharacters, getMyCampaigns})(Dashboard);
