import {
    CAMPAIGN_ERROR,
    GET_MY_CAMPAIGNS,
    GET_CAMPAIGN,
    CREATE_CAMPAIGN,
    SAVE_CAMPAIGN,
    UPDATE_CAMPAIGN,
    DELETE_CAMPAIGN
} from '../actions/types';

const initialState = {
    campaign: null,
    campaigns: [],
    loading: true,
    error: {}
}

function campaignReducer(state = initialState, action) {

    const { type, payload } = action;
    
    switch(type){
        case SAVE_CAMPAIGN:
        case CREATE_CAMPAIGN:
        case GET_CAMPAIGN:
        case UPDATE_CAMPAIGN:
            return {
                ...state,
                campaign: payload,
                campaigns: [],
                loading: false
            };
        case GET_MY_CAMPAIGNS:
            return {
                ...state,
                campaigns: payload,
                campaign: null,
                loading: false
            };
        case DELETE_CAMPAIGN:
            return {
                ...state,
                campaigns: state.campaigns.filter((camp) => camp._id !== payload),
                loading: false
            };
        case CAMPAIGN_ERROR:
            return {
                campaign: null,
                campaigns: [],
                loading: false,
                error: payload
            };
        
        default:
            return state;
    }
}

export default campaignReducer;