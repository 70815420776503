export const skills = [
    {
        "baseSkill": {
            "name": "Armor",
            "main_attr": "CON",
            "description": "Boosting defense and adding utility to equipped armor.",
            "perks": [
                {
                    "perk_name": "Protected",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0."
                },
                {
                    "perk_name": "Geared Up",
                    "lvl_req": 5,
                    "pk_desc": "Gain +3 base DIS and base KIN when wearing full armor."
                },
                {
                    "perk_name": "Endurance Training",
                    "lvl_req": 10,
                    "pk_desc": "Carrying capacity increased by 10kg."
                },
                {
                    "perk_name": "Reflective Barrier",
                    "lvl_req": 15,
                    "pk_desc": "Laser fire must hit you at a steeper angle giving you a permanent +1 to TARG."
                },
                {
                    "perk_name": "Protective Coating",
                    "lvl_req": 20,
                    "pk_desc": "When wearing a full set of armor, gain +1 to all saving throws involving or following physical damage. For example, fall damage."
                }
            ]
        },
        "expertise1": {
            "name": "Light Armor",
            "main_attr": "DEX",
            "description": "Better movement and evasion with light armor equipped.",
            "perks": [
                {
                    "perk_name": "Light Armor Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Armor skill modifier now includes your Dexterity modifier as well."
                },
                {
                    "perk_name": "Slim and Sleek",
                    "lvl_req": 30,
                    "pk_desc": "Permanent +1 to TARG and +2 to EVA."
                },
                {
                    "perk_name": "Lightweight Materials",
                    "lvl_req": 35,
                    "pk_desc": "Equipped light armor no longer counts towards your carrying capacity."
                },
                {
                    "perk_name": "Camo Armor",
                    "lvl_req": 40,
                    "pk_desc": "+2 to Espionage checks."
                },
                {
                    "perk_name": "Powered Joints",
                    "lvl_req": 45,
                    "pk_desc": "Maximum movement increased by 2m."
                },
                {
                    "perk_name": "Piezoelectric Barrier",
                    "lvl_req": 50,
                    "pk_desc": "During your turn, you gain 2 DIS for every 5m moved."
                }
            ]
        },
        "expertise2": {
            "name": "Heavy Armor",
            "main_attr": "STR",
            "description": "Increasing AC and mitigating damage.",
            "perks": [
                {
                    "perk_name": "Heavy Armor Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Armor skill modifier now includes your Strength modifier as well."
                },
                {
                    "perk_name": "Ergonomic Joints",
                    "lvl_req": 30,
                    "pk_desc": "Heavy Armor no longer affects movement or Athletics checks."
                },
                {
                    "perk_name": "Magnetic Bracing",
                    "lvl_req": 35,
                    "pk_desc": "Carrying capacity increased by 20kg."
                },
                {
                    "perk_name": "Tenacious Defense",
                    "lvl_req": 40,
                    "pk_desc": "Every piece of equipped Heavy Armor, except shields, gives you an additional 2 DIS and KIN."
                },
                {
                    "perk_name": "Safe and Secure",
                    "lvl_req": 45,
                    "pk_desc": "You have advantage on all CON saving throws when wearing full Heavy Armor, not including shields."
                },
                {
                    "perk_name": "Kinetic Reflection",
                    "lvl_req": 50,
                    "pk_desc": "When your KIN is greater than 0, half all melee damage you receive is also dealt to the attacker."
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Assault Weapons",
            "main_attr": "STR",
            "description": "High damage output possibility, but is limited by range and accuracy.",
            "perks": [
                {
                    "perk_name": "Assault Weapon User",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0."
                },
                {
                    "perk_name": "Itchy Trigger Finger",
                    "lvl_req": 5,
                    "pk_desc": "When performing a ranged attack with an Assault Rifle, number of attacks is now 2."
                },
                {
                    "perk_name": "Assault Rifles",
                    "lvl_req": 10,
                    "pk_desc": "Damage die with assault weapons is now d6. Can use assault rifles without penalty."
                },
                {
                    "perk_name": "Extended Clips",
                    "lvl_req": 15,
                    "pk_desc": "All assault weapon magazine sizes are increased by 2."
                },
                {
                    "perk_name": "Chain Guns",
                    "lvl_req": 20,
                    "pk_desc": "Damage die with assault weapons is now d8. Can use chain guns without penalty."
                }
            ]
        },
        "expertise1": {
            "name": "Bullet Hell",
            "main_attr": "DEX",
            "description": "More attacks, but no improvements to the Spray roll.",
            "perks": [
                {
                    "perk_name": "Bullet Hell Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Assault Weapons skill modifier now includes your Dexterity modifier as well."
                },
                {
                    "perk_name": "Trigger Happy",
                    "lvl_req": 30,
                    "pk_desc": "When performing a ranged attack with an Assault Rifle, number of attacks is now 3."
                },
                {
                    "perk_name": "Spray and Pray",
                    "lvl_req": 35,
                    "pk_desc": "When performing a ranged attack with an Assault Rifle, number of attacks is now 4. Maximum spray penalty is -3."
                },
                {
                    "perk_name": "Cover Fire",
                    "lvl_req": 40,
                    "pk_desc": "When an enemy is behind cover, you can forfeit two of your attacks this turn to provide covering fire and force them to stay behind cover during their next turn. Uses two ammo."
                },
                {
                    "perk_name": "Ammo Drums",
                    "lvl_req": 45,
                    "pk_desc": "Magazine size for all assault weapons increased by 8."
                },
                {
                    "perk_name": "Computerized Capacitors",
                    "lvl_req": 50,
                    "pk_desc": "Number of attacks per Ranged attack is now 6. Spray penalty is applied every two attacks instead of every attack."
                }
            ]
        },
        "expertise2": {
            "name": "Special Ops",
            "main_attr": "WIS",
            "description": "Improving Spray rolls.",
            "perks": [
                {
                    "perk_name": "Special Ops Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Assault Weapons skill modifier now includes your Wisdom modifier as well."
                },
                {
                    "perk_name": "Grouping",
                    "lvl_req": 30,
                    "pk_desc": "After a Spray roll, you can choose to keep that result for all future Spray rolls during this turn. Otherwise, apply the usual penalty."
                },
                {
                    "perk_name": "Burst Fire",
                    "lvl_req": 35,
                    "pk_desc": "Number of attacks per Ranged attack is now 3. Reloading an SMG or Assault Rifle may now be performed with a minor action."
                },
                {
                    "perk_name": "Close Enough",
                    "lvl_req": 40,
                    "pk_desc": "A 0 on a spray roll now deals half damage instead of no damage."
                },
                {
                    "perk_name": "High-Power Pulses",
                    "lvl_req": 45,
                    "pk_desc": "Range of all assault weapons increased by 10m."
                },
                {
                    "perk_name": "Surgical Precision",
                    "lvl_req": 50,
                    "pk_desc": "Spray die is now a d8. A result of 6-8 deals double damage."
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Astrobiology",
            "main_attr": "INT",
            "description": "The study of unfamiliar plants and animals",
            "perks": [
                {
                    "perk_name": "Identify",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0. No longer at disadvantage when performing a scientific Glean Information action."
                },
                {
                    "perk_name": "Healing Herbs",
                    "lvl_req": 5,
                    "pk_desc": "You can now perform the Healing Herbs action.",
                    "action_desc": "May use healing herbs in your inventory to heal a lifeform for 1d6 plus your skill modifier.",
                    "action_type": "Non-Combat"
                },
                {
                    "perk_name": "Chemist",
                    "lvl_req": 10,
                    "pk_desc": "May now craft basic serums. Base skill mod is now +1."
                },
                {
                    "perk_name": "Calm Lifeform",
                    "lvl_req": 15,
                    "pk_desc": "You can now perform the Calm Lifeform action.",
                    "action_desc": "Roll against a nearby hostile creature or plant's INT. On a success, they will cease to be aggressive towards the party.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Field Guide",
                    "lvl_req": 20,
                    "pk_desc": "You collect double components from plant and animal sources."
                }
            ]
        },
        "expertise1": {
            "name": "Botanist",
            "main_attr": "CON",
            "description": "Harnessing plants for serums and battle.",
            "perks": [
                {
                    "perk_name": "Botanist Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Astrobiology skill modifier now includes your Constitution modifier as well."
                },
                {
                    "perk_name": "Snaring Vines",
                    "lvl_req": 30,
                    "pk_desc": "You plant snaring vine starts nearby. The next lifeform (hostile or friendly) to step there will be snared by the vines. Each turn, they are unable to unable to move until they succeed a STR saving throw of 12 plus your skill mod."
                },
                {
                    "perk_name": "Alchemist",
                    "lvl_req": 35,
                    "pk_desc": "May now craft advanced serums."
                },
                {
                    "perk_name": "Thorns",
                    "lvl_req": 40,
                    "pk_desc": "Snaring Vines now deals poison damage every turn that a creature is trapped in them. The damage is 2d6 + your skill modifier."
                },
                {
                    "perk_name": "Neurotoxic Spores",
                    "lvl_req": 45,
                    "pk_desc": "You can now perform the Neurotoxic Spores action.",
                    "action_desc": "You throw out a vial that breaks on a surface and creates a 3m wide cloud that hovers in the air. All creatures initially caught in the cloud or who pass through it later must make a CON saving throw of 14+ your skill mod. On a failure, they are paralyzed for two turns. The cloud dissapates after 1 minute. After an enemy has receovered, they are immune to the effects.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Distillation",
                    "lvl_req": 50,
                    "pk_desc": "All serums you create have double potency."
                }
            ]
        },
        "expertise2": {
            "name": "Zoologist",
            "main_attr": "CHA",
            "description": "Interactions with non-sentient creatures.",
            "perks": [
                {
                    "perk_name": "Zoologist Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Astrobiology skill modifier now includes your Charisma modifier as well."
                },
                {
                    "perk_name": "Companion Creature",
                    "lvl_req": 30,
                    "pk_desc": "You can now perform the Companion Creature action.",
                    "action_desc": "Roll against a nearby non-hostile creature's WIS. On a success, creature will follow the player until dismissed or killed. They will no engage in combat, except in self-defense.",
                    "action_type": "Non-Combat"
                },
                {
                    "perk_name": "Trained to Kill",
                    "lvl_req": 35,
                    "pk_desc": "Companion creatures will now engage in combat. Creatures that are typically aggressive will do so willingly, but non-aggressive creatures have to fail an INT saving throw of 12+ your skill mod each encounter you want them to join."
                },
                {
                    "perk_name": "Creature Armor",
                    "lvl_req": 40,
                    "pk_desc": "You can now craft creature armor using scrap metal. The armor provides 15 KIN and cannot be modded."
                },
                {
                    "perk_name": "Animal Lover",
                    "lvl_req": 45,
                    "pk_desc": "You may now have two companion creatures simultaneously."
                },
                {
                    "perk_name": "More Than a Pet",
                    "lvl_req": 50,
                    "pk_desc": "Creature armor now have a single mod slot for an armor mod."
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Athletics",
            "main_attr": "STR",
            "description": "Running, jumping, climbing, lifting, etc.",
            "perks": [
                {
                    "perk_name": "Athlete",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0."
                },
                {
                    "perk_name": "Routine Cardio",
                    "lvl_req": 5,
                    "pk_desc": "Your maximum HP increases by 4 every level instead of 2."
                },
                {
                    "perk_name": "Kip Up",
                    "lvl_req": 10,
                    "pk_desc": "Base skill mod is now 1. Getting up uses 5m of movement instead of 10m."
                },
                
                {
                    "perk_name": "Improved Flexibility",
                    "lvl_req": 15,
                    "pk_desc": "Gain a permanent +2 to EVA."
                },
                {
                    "perk_name": "Leg Day",
                    "lvl_req": 20,
                    "pk_desc": "Movement increased by 3m."
                }
            ]
        },
        "expertise1": {
            "name": "Sprinter",
            "main_attr": "DEX",
            "description": "Greater movement and evasion.",
            "perks": [
                {
                    "perk_name": "Sprinter Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Athletics skill modifier now includes your Dexterity modifier as well."
                },
                {
                    "perk_name": "Nimble",
                    "lvl_req": 30,
                    "pk_desc": "Enemies do not get an attack of opportunity when you move past or away from them."
                },
                {
                    "perk_name": "Cross Country Training",
                    "lvl_req": 35,
                    "pk_desc": "Maximum movement increased by 3. Additional +2 to TARG when moving at least 12m in a turn."
                },
                {
                    "perk_name": "Lateral Quickness",
                    "lvl_req": 40,
                    "pk_desc": "EVA is increased by half of your Athletics modifier, rounded up."
                },
                {
                    "perk_name": "Reflex Training",
                    "lvl_req": 45,
                    "pk_desc": "For any STR or DEX saving throws, you now use your Athletics modifier."
                },
                {
                    "perk_name": "Leaping Attack",
                    "lvl_req": 50,
                    "pk_desc": "You can now perform the Leaping Attack action.",
                    "action_desc": "You leap to a space within 3m of your current position and then immediately perform a Melee Attack. This can be performed even if all your movement is used up this turn.",
                    "action_type": "Major"
                }
            ]
        },
        "expertise2": {
            "name": "Bodybuilder",
            "main_attr": "CON",
            "description": "More strength and HP",
            "perks": [
                {
                    "perk_name": "Bodybuilder Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Athletics skill modifier now includes your CON modifier as well."
                },
                {
                    "perk_name": "Shove",
                    "lvl_req": 30,
                    "pk_desc": "You can now perform the Shove action.",
                    "action_desc": "Roll against an adjacent target's STR. On a success, you shove them up to 2m away and they are knocked prone.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Olympian",
                    "lvl_req": 35,
                    "pk_desc": "Your maximum HP increases by 6 every level instead of 4. Carrying capacity increased by 20kg."
                },
                {
                    "perk_name": "Full Nelson",
                    "lvl_req": 40,
                    "pk_desc": "You can now perform the Full Nelson action.",
                    "action_desc": "A target within melee range makes a STR saving throw of 12+ your skill mod. On a failure, you bring your arms up under their arms and behind their head. Until they can escape, their movement is 0 and they cannot perform any non-stim attacks or other actions that require the use of their arms. You also cannot use your arms while holding them, but your teammates have advantage to hit them with any attack.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Gym Rat",
                    "lvl_req": 45,
                    "pk_desc": "For any STR or CON saving throws, now use your Athletics modifier."
                },
                {
                    "perk_name": "Super Strength",
                    "lvl_req": 50,
                    "pk_desc": "When landing a hit with a melee attack, you now add your Athletics modifier to the damage output, in addition to your Unarmed modifier. Note: this does not affect modifier to hit."
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Blade",
            "main_attr": "DEX",
            "description": "Melee attacks with a blade weapon, dealing bleed damage when enemy has no armor.",
            "perks": [
                {
                    "perk_name": "Blade User",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0."
                },
                {
                    "perk_name": "Sharpen",
                    "lvl_req": 5,
                    "pk_desc": "You can now perform the Sharpen action.",
                    "action_desc": "During a rest, you may choose to use a four-hour period sharpening a blade. For 1 day, that blade rolls an additional die of damage when it attacks.",
                    "action_type": "Rest"
                },
                {
                    "perk_name": "Short Blades",
                    "lvl_req": 10,
                    "pk_desc": "Damage die is now a d6. Able to use short blades without penalty."
                },
                {
                    "perk_name": "Counter Strike",
                    "lvl_req": 15,
                    "pk_desc": "If an enemy misses a melee attack against you, roll against their DEX. On a success, you get an attack of opportunity."
                },
                {
                    "perk_name": "Long Blades",
                    "lvl_req": 20,
                    "pk_desc": "Damage die is now a d8. Able to use long blades without penalty."
                }
            ]
        },
        "expertise1": {
            "name": "Fencer",
            "main_attr": "CHA",
            "description": "Single blade wielding, focusing on evasion and reading the opponent.",
            "perks": [
                {
                    "perk_name": "Fencer Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Blade skill modifier now includes your Charisma modifier as well."
                },
                {
                    "perk_name": "Parry",
                    "lvl_req": 30,
                    "pk_desc": "Your EVA is increased by 3 when wielding a single blade."
                },
                {
                    "perk_name": "Calculated Strikes",
                    "lvl_req": 35,
                    "pk_desc": "Critical Hit requirement is lowered by 2."
                },
                {
                    "perk_name": "Backstab",
                    "lvl_req": 40,
                    "pk_desc": "When attacking an enemy who doesn't notice you, you ignore their KIN and can deal a stack of bleed."
                },
                {
                    "perk_name": "Riposte",
                    "lvl_req": 45,
                    "pk_desc": "Successful counter attacks deal double damage."
                },
                {
                    "perk_name": "En garde",
                    "lvl_req": 50,
                    "pk_desc": "You can now perform the En Garde action.",
                    "action_desc": "When in melee range of a single, sentient opponent, you can choose to roll + skill mod against their CHA. On a success, you have advantage on all actions against this target and +4 EVA until another combatant attacks you or combat with this target ends.",
                    "action_type": "Minor"
                }
            ]
        },
        "expertise2": {
            "name": "Dual-Wielder",
            "main_attr": "INT",
            "description": "Wielding two blades to optimize damage output.",
            "perks": [
                {
                    "perk_name": "Dual-Wielder Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Blade skill modifier now includes your INT modifier as well."
                },
                {
                    "perk_name": "Swift Strikes",
                    "lvl_req": 30,
                    "pk_desc": "May attack twice with a blade in your main hand. Only add a stack of Bleeding once per turn."
                },
                {
                    "perk_name": "Ambidexterous",
                    "lvl_req": 35,
                    "pk_desc": "No penalties for using a melee weapon in your off-hand."
                },
                {
                    "perk_name": "Nito-ryu",
                    "lvl_req": 40,
                    "pk_desc": "When two blades are equipped, Counter Strike deals full Melee Attack damage with the blade equipped in your main hand."
                },
                {
                    "perk_name": "The Best Defense...",
                    "lvl_req": 45,
                    "pk_desc": "EVA increased by 4 with two blades equipped."
                },
                {
                    "perk_name": "Whirl of Blades",
                    "lvl_req": 50,
                    "pk_desc": "May attack twice with a blade in your offhand. May add two stacks of Bleeding per turn."
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Blunt",
            "main_attr": "STR",
            "description": "Melee attacks with a blunt weapon, highly effective against armor.",
            "perks": [
                {
                    "perk_name": "Blunt User",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0."
                },
                {
                    "perk_name": "Heavy Strike",
                    "lvl_req": 5,
                    "pk_desc": "You can now perform the Heavy Strike action.",
                    "action_desc": "Once per encounter, you may summon your energy for a huge strike against an enemy with -4 to hit. On a sucessful hit, damage multiplier increased by 1.0 and the enemy is automatically staggered for a turn.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Maces",
                    "lvl_req": 10,
                    "pk_desc": "Damage die is now a d6. Able to use maces without penalty."
                },
                {
                    "perk_name": "Weighted Ends",
                    "lvl_req": 15,
                    "pk_desc": "Damage against KIN increased by 50%, rounded down."
                },
                {
                    "perk_name": "Warhammers",
                    "lvl_req": 20,
                    "pk_desc": "Damage die is now a d8. Able to use warhammers without penalty."
                }
            ]
        },
        "expertise1": {
            "name": "Bruiser",
            "main_attr": "CON",
            "description": "High damage output.",
            "perks": [
                {
                    "perk_name": "Bruiser Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Blunt skill modifier now includes your Constitution modifier as well."
                },
                {
                    "perk_name": "Sweeping Blow",
                    "lvl_req": 30,
                    "pk_desc": "You can now perform the Sweeping Blow action.",
                    "action_desc": "You swing your blunt weapon in an arc in around you. Roll to hit each enemy within melee range, but each subsequent enemy receives an additional -2 to hit.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Controlled Momentum",
                    "lvl_req": 35,
                    "pk_desc": "Heavy Strike no longer has a penalty to hit."
                },
                {
                    "perk_name": "Hit Harder",
                    "lvl_req": 40,
                    "pk_desc": "Any time an opponent rolls a saving throw to prevent being stunned by your attacks, the DC is now 12+ your skil mod."
                },
                {
                    "perk_name": "Mauler",
                    "lvl_req": 45,
                    "pk_desc": "When using warhammers, your melee range is increased by 1."
                },
                {
                    "perk_name": "Go Beserk",
                    "lvl_req": 50,
                    "pk_desc": "After successfully performing a Heavy Strike, roll a d20 plus your Athletics and Blunt modifiers. On a 16+, you regain a Heavy Strike action this encounter."
                }
            ]
        },
        "expertise2": {
            "name": "Warder",
            "main_attr": "INT",
            "description": "Deflecting and staggering.",
            "perks": [
                {
                    "perk_name": "Warder Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Blunt skill modifier now includes your INT modifier as well."
                },
                {
                    "perk_name": "Shield Bash",
                    "lvl_req": 30,
                    "pk_desc": "You can now perform the Shield Bash action.",
                    "action_desc": "If shield is equipped, may attack with it for 1d8 damage. Roll against enemy's CON, on a success they are staggered for a turn.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Reinforced Shields",
                    "lvl_req": 35,
                    "pk_desc": "Gain +5 DIS and KIN with shield equipped."
                },
                {
                    "perk_name": "Targeted Blow",
                    "lvl_req": 40,
                    "pk_desc": "You can now perform the Targeted Blow action.",
                    "action_desc": "You attack a nearby enemy with -4 to hit. On a successful hit, you deal normal melee attack damage and you give a status effect to your opponent depending on where you hit them. Legs = Crippled - Leg, Arm = Crippled - Arms, Head = Concussed.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Kinetic Dampening",
                    "lvl_req": 45,
                    "pk_desc": "Gain +4 EVA with Shield equipped."
                },
                {
                    "perk_name": "Refuge",
                    "lvl_req": 50,
                    "pk_desc": "When you have a shield equipped, you may act as full cover for your teammates. They cannot be targetted wityh ranged attacks and any melee attackers must attack you first."
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Energy",
            "main_attr": "WIS",
            "description": "Harnessing nearby energy sources to wield the power of fire and lightning.",
            "perks": [
                {
                    "perk_name": "Ignite",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0. You can now perform the Ignite Action",
                    "action_desc": "Pick a 1m sphere of space you can see within 10m to ignite with fire. Though this does no damage outright, nearby lifeforms may take 1d4 burn damage for up to 3 turns. Does NOT use a charge.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Bolt",
                    "lvl_req": 5,
                    "pk_desc": "You can now perform the Bolt action.",
                    "action_desc": "Fire a bolt of electricity that deals 2d6 damage. You must roll to hit a ranged attack on your target. On a miss, this attack deals no damage.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Hot Blooded",
                    "lvl_req": 10,
                    "pk_desc": "You take half damage from all heat and electrical sources."
                },
                {
                    "perk_name": "Energize",
                    "lvl_req": 15,
                    "pk_desc": "You can now perform the Energize action.",
                    "action_desc": "You focus in place on absorbing heat or electrical from a specific nearby source. Gain a stack of Energize, up to 3 stacks. The next Energy based action you take is energized making it more powerful and effective based on the number of stacks of Energize you use. For attacks, this means adding another damage die per stack of Energize.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Discharge",
                    "lvl_req": 20,
                    "pk_desc": "You can now perform the Discharge action.",
                    "action_desc": "You use all your current Energize stacks to send out a sphere of energy in all directions from yourself. Per stack, the radius increases by 1m and damage increases by 1d6. So 4 stacks of Energize would deal 4d6 damage in a 4m range.",
                    "action_type": "Major"
                }
            ]
        },
        "expertise1": {
            "name": "Fire",
            "main_attr": "STR",
            "description": "Area of effect and burn damage.",
            "perks": [
                {
                    "perk_name": "Fire Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Energy skill modifier now includes your Strength modifier as well. Discharge deals specifically fire damage and causes burn."
                },
                {
                    "perk_name": "Flamethrower",
                    "lvl_req": 30,
                    "pk_desc": "You can now perform the Flamethrower action.",
                    "action_desc": "You shoot a jet of flame from your hand up, hitting all targets in a line up to 8m away and dealing 2d8 damage. All hit targets are Burned for 3 turns.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Fireball",
                    "lvl_req": 35,
                    "pk_desc": "You can now perform the Fireball action.",
                    "action_desc": "Throw a ball of fire that deals 4d6 fire damage and ignites the space it lands on and all surrounding spaces (or a 3x3 area).",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Blue Flame",
                    "lvl_req": 40,
                    "pk_desc": "Burn damage is increased to 1d8 damage and lasts for five turns instead of three."
                },
                {
                    "perk_name": "Inferno",
                    "lvl_req": 45,
                    "pk_desc": "Flamethrower now produces two jets of flame with a single charge. You can either combine them to create one large jet 3m wide and 12m long that deals 4d8 damage, or you can shoot jets in different directions with the normal Flamethrower range and damage."
                },
                {
                    "perk_name": "Flame Construct",
                    "lvl_req": 50,
                    "pk_desc": "You can now perform the Flame Construct action.",
                    "action_desc": "You summon a construct of flame (you describe what it looks like) that will remain with you for 1 hour. This construct can perform Ignite and Flamethrower (with 6 charges to do so), has movement of 8, and levitates. It has 20 HP, 0 KIN, and 0 DIS and is immune to all energy-based damage. Flame Construct is unaffected by Energize stacks.",
                    "action_type": "Major"
                }
            ]
        },
        "expertise2": {
            "name": "Lightning",
            "main_attr": "CON",
            "description": "Instant damage and utility.",
            "perks": [
                {
                    "perk_name": "Lightning Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Energy skill modifier now includes your Constitution modifier as well. Discharge deals specifically lightning damage."
                },
                {
                    "perk_name": "Recharge",
                    "lvl_req": 30,
                    "pk_desc": "You can now perform the Recharge action.",
                    "action_desc": "You can use stacks of Energize as if they were a battery, recharging a single stim by 1 per stack of Energize used.",
                    "action_type": "Minor"
                },
                {
                    "perk_name": "Thunderbolt",
                    "lvl_req": 35,
                    "pk_desc": "Bolt now deals 4d6 damage."
                },
                {
                    "perk_name": "Disrupt",
                    "lvl_req": 40,
                    "pk_desc": "You can now perform the Disrupt action.",
                    "action_desc": "You infuse an mechanical enemy or object with DC energy. As an attack, use a charge and this deals 2d6 damage. An enemy must roll a CON saving throw of 14+ your skill mod. On a failure, they are stunned for 3 turns. For other objects, it will disrupt their electric flow, either lowering computerized defenses or perhaps exploding.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Chain Lightning",
                    "lvl_req": 45,
                    "pk_desc": "When you hit a target with bolt, you may now choose up to three other targets, within 10m of the original target, to hit with Bolt at half damage."
                },
                {
                    "perk_name": "Storm Construct",
                    "lvl_req": 50,
                    "pk_desc": "You can now perform the Storm Construct action.",
                    "action_desc": "You summon a construct of lightning (you describe what it looks like) that will remain with you for 1 hour. This construct can perform level 35 Bolt (with 6 charges to do so) and has movement of 12. It has 20 HP, 0 KIN, and 0 DIS and is resistant to all energy-based damage. All melee attacks against it are hit with 2d6 electric damage.",
                    "action_type": "Major"
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Espionage",
            "main_attr": "CHA",
            "description": "Being sneaky to increase damage, infiltrate, and collect information.",
            "perks": [
                {
                    "perk_name": "Sneaky",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0. May craft lockpicks from spare parts."
                },
                {
                    "perk_name": "Twinkle Toes",
                    "lvl_req": 5,
                    "pk_desc": "Your EVA and TARG are permanently increased by 1."
                },
                {
                    "perk_name": "Klepto",
                    "lvl_req": 10,
                    "pk_desc": "Base skill mod is now 1. Gain a +2 bonus to Espionage skill checks when trying to pick a mechanical lock or trying to pickpocket someone."
                },
                {
                    "perk_name": "Decoy",
                    "lvl_req": 15,
                    "pk_desc": "You can now perform the Decoy action.",
                    "action_desc": "You now have a holographic image projector in your inventory with a single charge that can be recharged with a battery. You create a holographic image of yourself and program it to perform a specific action on repeat (e.g. walk, sit, say a single phrase, etc.). Will distract nearby intelligent lifeforms until something dirupts the believablility (e.g. being attacked, running into a wall, saying the same phrase over and over).",
                    "action_type": "Non-Combat"
                },
                {
                    "perk_name": "Out of Nowhere",
                    "lvl_req": 20,
                    "pk_desc": "Successful sneak attacks now add 1.0 to your attack multiplier."
                }
            ]
        },
        "expertise1": {
            "name": "Operative",
            "main_attr": "WIS",
            "description": "More effective deception and information gathering.",
            "perks": [
                {
                    "perk_name": "Operative Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Epionage skill modifier now includes your Wisdom modifier as well."
                },
                {
                    "perk_name": "Blending In",
                    "lvl_req": 30,
                    "pk_desc": "When disguised, global suspicion has no effect on you and you gain +2 to Negotiation."
                },
                {
                    "perk_name": "Dossier",
                    "lvl_req": 35,
                    "pk_desc": "You can now perform the Dossier action.",
                    "action_desc": "You may use a rest period to familiarize yourself on a topic. Pick a skill to research, gain +2 in that skill for all non-combat rolls for 24 hours.",
                    "action_type": "Rest"
                },
                {
                    "perk_name": "Innocent Bystander",
                    "lvl_req": 40,
                    "pk_desc": "If you enter combat while successfully disguised, you will not be targetted by enemies until you attack them."
                },
                {
                    "perk_name": "Draw Their Attention",
                    "lvl_req": 45,
                    "pk_desc": "While you are disguised, your teammates all have advantage to Sneaking."
                },
                {
                    "perk_name": "Body Double",
                    "lvl_req": 50,
                    "pk_desc": "While in diguise, you have advantage on the Identify, Calm Lifeform, Companion Creature, Sneak, Coax, Haggle, Calm Sentient, Taunt, Bribe, Adulate, Follow Me, Ctrl + F, Hack, and Hijack actions."
                }
            ]
        },
        "expertise2": {
            "name": "Thief",
            "main_attr": "DEX",
            "description": "Avoiding notice and striking from the shadows.",
            "perks": [
                {
                    "perk_name": "Thief Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Espionage skill modifier now includes your Dexterity modifier as well."
                },
                {
                    "perk_name": "In Plain Sight",
                    "lvl_req": 30,
                    "pk_desc": "You can now perform Sneak in combat, but you must be behind cover and must pass the skill check. Does not work when engaged."
                },
                {
                    "perk_name": "Nimble Thief",
                    "lvl_req": 35,
                    "pk_desc": "Successful sneak attacks now add 1.5 to your attack multiplier."
                },
                {
                    "perk_name": "In the Shadows",
                    "lvl_req": 40,
                    "pk_desc": "Global suspicion no longer affects your ability to Sneak."
                },
                {
                    "perk_name": "Knife in the Back",
                    "lvl_req": 45,
                    "pk_desc": "Performing a sneak attack when behind the enemy increases your attack multiplier by an additional 0.5, on top of the normal sneak attack increase."
                },
                {
                    "perk_name": "Ninja",
                    "lvl_req": 50,
                    "pk_desc": "After performing a Sneak Attack, if you killed the enemy you attacked, you remain in sneak mode. Otherwise, the enemy you attacked must roll another Observation check of 10+ you skill mod to find you. On a failure, you remain in sneak mode. This only works if you move to a hiding spot after the Sneak Attack."
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Explosives",
            "main_attr": "CON",
            "description": "Boom",
            "perks": [
                {
                    "perk_name": "Explosives User",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0."
                },
                {
                    "perk_name": "Grenadier",
                    "lvl_req": 5,
                    "pk_desc": "Can now craft simple grenades."
                },
                {
                    "perk_name": "Trip Wire",
                    "lvl_req": 10,
                    "pk_desc": "You can now perform the Trip Wire action.",
                    "action_desc": "You can use a single explosive ammo from your inventory as a trap, having it go off when anything gets within 2m of it. It will go unnoticed by hostiles unless they pass an Observation check of 10+ your skill mod.",
                    "action_type": "Non-Combat"
                },
                {
                    "perk_name": "Jury-Rigger",
                    "lvl_req": 15,
                    "pk_desc": "The likelihood of looting grenade components is doubled."
                },
                {
                    "perk_name": "Padded Armor",
                    "lvl_req": 20,
                    "pk_desc": "You are resistant to Explosive damage when wearing full armor."
                }
            ]
        },
        "expertise1": {
            "name": "Pyromaniac",
            "main_attr": "DEX",
            "description": "More booms",
            "perks": [
                {
                    "perk_name": "Pyromaniac Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Explosives skill modifier now includes your Dexterity modifier as well."
                },
                {
                    "perk_name": "Unstable Reaction",
                    "lvl_req": 30,
                    "pk_desc": "All grenades you craft gain an additional die of damage and their AOE is increaed by 1m."
                },
                {
                    "perk_name": "Fire in the Hole!",
                    "lvl_req": 35,
                    "pk_desc": "Any of your teammates who would be hit by one of your grenades may now perform an Athletics check to try and avoid damage. On a <9 they receive full damage and stay where they are. On a 9-15, they receive half damage and must move two spaces away from the grenade. On a 16+, they take no damage and move two spaces away from the grenade."
                },
                {
                    "perk_name": "Grenade Launchers",
                    "lvl_req": 40,
                    "pk_desc": "May now use grenade launchers without penalty."
                },
                {
                    "perk_name": "Concussive Force",
                    "lvl_req": 45,
                    "pk_desc": "Every enemy within range of your grenade's explosion rolls a STR saving throw of 10+ your skill mod. IF they fail, they are knocked prone."
                },
                {
                    "perk_name": "Demolition Man",
                    "lvl_req": 50,
                    "pk_desc": "You can now throw or launch two grenades with one action"
                }
            ]
        },
        "expertise2": {
            "name": "Chemist",
            "main_attr": "INT",
            "description": "Focuses on status effects and elemental damage.",
            "perks": [
                {
                    "perk_name": "Chemist Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Explosives skill modifier now includes your INT modifier as well."
                },
                {
                    "perk_name": "Rocket Science",
                    "lvl_req": 30,
                    "pk_desc": "May craft advanced grenades."
                },
                {
                    "perk_name": "Remote Detonation",
                    "lvl_req": 35,
                    "pk_desc": "You can choose whether traps should be set off by proximity or by remote detonation. Remote Detonation is now a minor action that you can use as a reaction outside your turn."
                },
                {
                    "perk_name": "Pressurized Chemicals",
                    "lvl_req": 40,
                    "pk_desc": "Every type of elemental damage your grenade has attached to it also deals 1d6 damage of that element type on top of the usual effects."
                },
                {
                    "perk_name": "Master Trapper",
                    "lvl_req": 45,
                    "pk_desc": "Enemies have disadvantage on Observation checks when looking for traps you set."
                },
                {
                    "perk_name": "Compounding Compounds",
                    "lvl_req": 50,
                    "pk_desc": "You may craft grenades with two elemental effects. You can choose which effect happens first, though keep in mind that some elements offset other elements."
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Fluid",
            "main_attr": "WIS",
            "description": "Manipulation of liquids and changing matter states.",
            "perks": [
                {
                    "perk_name": "Hydrate",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0. You can now perform the Hydrate Action",
                    "action_desc": "You gather water from the air around you or a nearby source and shoot it in a jet up to 10m. It does not cause damage, but can be used to douse fires, water plants, or refill water containers. Does not use a charge.",
                    "action_type": "Non-Combat"
                },
                {
                    "perk_name": "Displace Liquid",
                    "lvl_req": 5,
                    "pk_desc": "You can now perform the Displace Liquid action.",
                    "action_desc": "You can manipulate a 1m radius sphere of nearby liquid within 10m of your current position. This could be used to create an air-bubble or to bombard a target with the fluid. If used as an attack, you deal 1d8 + skill mod physical damage.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Freezing Touch",
                    "lvl_req": 10,
                    "pk_desc": "You can now perform the Freezing Touch action.",
                    "action_desc": "You touch an object to freeze it solid. For objects larger than 25kg, you must use a charge. In combat, target must make a CON saving throw of 12+ your modifier. On a fail, they are frozen.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Fog",
                    "lvl_req": 15,
                    "pk_desc": "You can now perform the Fog action. You also only take half damage from cold sources.",
                    "action_desc": "You cause atmospheric liquid to condense and create a fog that covers a cube 20m wide and long and 5m tall.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Jet",
                    "lvl_req": 20,
                    "pk_desc": "May use a charge to have Hydrate deal 2d6 + skill modifier damage."
                }
            ]
        },
        "expertise1": {
            "name": "Flood",
            "main_attr": "STR",
            "description": "Utilizing and attacking with liquids.",
            "perks": [
                {
                    "perk_name": "Flood Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Fluid skill modifier now includes your Strength modifier as well."
                },
                {
                    "perk_name": "Part the Waters",
                    "lvl_req": 30,
                    "pk_desc": "Displace Liquid works on a 2m radius and deals 2d8 + mod damage when used as an attack."
                },
                {
                    "perk_name": "Hydrodynamic",
                    "lvl_req": 35,
                    "pk_desc": "Your movement underwater is is doubled, and you can breath underwater."
                },
                {
                    "perk_name": "Tidal Wave",
                    "lvl_req": 40,
                    "pk_desc": "You can now perform the Tidal Wave action.",
                    "action_desc": "You create a wave of water 5m wide that travels 8m in a straight line. The wave deals 2d8 + your skill mod damage to all creatures caught in it. All creatures also perform a STR check of 12+ your skill mod. On a failure, they are pushed back 4m and are knocked prone.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Water Spout",
                    "lvl_req": 45,
                    "pk_desc": "Displace Water now works on a 4m radius sphere. With concentration you can hold the water in place, trapping something inside and giving it the Underwater status effect."
                },
                {
                    "perk_name": "Water Construct",
                    "lvl_req": 50,
                    "pk_desc": "You can now perform the Water Construct action.",
                    "action_desc": "You summon a construct of water (you describe what it looks like) that will remain with you for 1 hour. This construct can perform level 20 Hydrate and level 30 Displace Liquid (with 6 charges to do so) and has movement of 8. It has 25 HP, 0 KIN, and 0 DIS and is immune to all physical damage.",
                    "action_type": "Major"
                }
            ]
        },
        "expertise2": {
            "name": "Ice",
            "main_attr": "CON",
            "description": "Barriers and Status Effects",
            "perks": [
                {
                    "perk_name": "Ice Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Fluid skill modifier now includes your Constitution modifier as well."
                },
                {
                    "perk_name": "Ice Shield",
                    "lvl_req": 30,
                    "pk_desc": "You can now perform the Ice Wall action.",
                    "action_desc": "You can gather and freeze atmospheric and nearby water into a slab of ice about 15cm thick (or 6 inches) as a wall that is 2m wide and 2m tall. This wall grants full cover and has 15 HP.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Ice Gear",
                    "lvl_req": 30,
                    "pk_desc": "You can now perform the Ice Gear action.",
                    "action_desc": "For either yourself or a teammate, you create an armor coating or a weapon that is made entirely of ice. The armor coating can go over your current armor and grants you an amount of KIN equal to 10+ your Fluid and Armor mods. The weapon is either a Blade or Blunt weapon that deals 2d6 + that respective skill mod damage.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Precipitation",
                    "lvl_req": 40,
                    "pk_desc": "You can now perform the Precipitation action.",
                    "action_desc": "You can change the surrounding weather to be raining or dry. If the outside temperature is low enough, the rain will be snow instead.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Black Ice",
                    "lvl_req": 45,
                    "pk_desc": "Your movement is not affected by ice and snow. You can now perform the Black Ice action.",
                    "action_desc": "You turn the ground in a 10m circle into slick ice. All creatures' ground movements are halved while on ice. Prior to moving each creature on the ice must make an Athletics check. If they roll less than 12, they don't move and are knocked prone.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Ice Construct",
                    "lvl_req": 50,
                    "pk_desc": "You can now perform the Ice Construct action.",
                    "action_desc": "You summon a construct of ice (you describe what it looks like) that will remain with you for 1 hour. This construct can perform Freezing Touch (with 3 charges to do so) and has movement of 6. It has 20 HP, 10 KIN, and 10 DIS. It can transform its main hand into either a blade or a blunt weapon that deals 3d6 damage when it hits.",
                    "action_type": "Major"
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Gravity",
            "main_attr": "WIS",
            "description": "Changing gravitational forces on self or others.",
            "perks": [
                {
                    "perk_name": "Move Object",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0. You can now perform the Move Object action",
                    "action_desc": "You can levitate and move an object weighing under 20kg to a location of your choice. If used as an attack, you use a charge and can deal 1d6 + skill mod damage.",
                    "action_type": "Non-Combat"
                },
                {
                    "perk_name": "Levitate Self",
                    "lvl_req": 5,
                    "pk_desc": "You can now perform the Levitate Self action.",
                    "action_desc": "You levitate yourself up to 1m off the ground. Your movement is limited to 5m per turn while levitating. Ends after 5 minutes or if you use another Gravity skill.",
                    "action_type": "Minor"
                },
                {
                    "perk_name": "Slow Momentum",
                    "lvl_req": 10,
                    "pk_desc": "You can now perform the Slow Momentum action.",
                    "action_desc": "Able to slow down the movement of an object/creature weighing under 150kg by 5m per turn. If a creature is brought to a full stop, it must make a STR saving throw of 10+ your skill mod to try and break your control.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Telekinesis",
                    "lvl_req": 15,
                    "pk_desc": "Move Object now works on objects up to 50kg, and deals 2d8 damage as an attack. Also, Slow Momentum works on objects up to 250kg."
                },
                {
                    "perk_name": "Deflect",
                    "lvl_req": 20,
                    "pk_desc": "You can now perform the Deflect action.",
                    "action_desc": "You empower one of your hands to redirect laser fire, giving you +4 TARG for 5 turns or until you use another Gravity skill. You must have one hand free to do this.",
                    "action_type": "Major"
                }
            ]
        },
        "expertise1": {
            "name": "Singularity",
            "main_attr": "STR",
            "description": "Moving objects and other lifeforms.",
            "perks": [
                {
                    "perk_name": "Singularity Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Gravity skill modifier now includes your Strength modifier as well."
                },
                {
                    "perk_name": "Stasis Field",
                    "lvl_req": 30,
                    "pk_desc": "You can now perform the Stasis Field action.",
                    "action_desc": "Target rolls a STR saving throw of 12+ skill mod. On a failure, they are lifted 2m into the air in a stasis field. They cannot move for two turns, and all attacks have advantage to hit against them.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Gravity Well",
                    "lvl_req": 35,
                    "pk_desc": "You can now perform the Gravity Well action.",
                    "action_desc": "You magnify the effects of gravity in a 6m radius around you. All creatures caught in the gravity well have their max carrying capacity cut in half, except you. If this is performed in a weightless environment, then this creates a well of normal gravity.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Human Hovercar",
                    "lvl_req": 40,
                    "pk_desc": "Levitate Self now affects all creatures within a 3m radius of the caster. However, they cannot move and will only move when the caster does."
                },
                {
                    "perk_name": "Tractor Beam",
                    "lvl_req": 45,
                    "pk_desc": "Move object works on objects up to 100kg and deals 4d8 + skill mod damage as an attack."
                },
                {
                    "perk_name": "Superminiscule Blackhole",
                    "lvl_req": 50,
                    "pk_desc": "Stasis Field now traps all targets within 3m of where it was cast and hoists them 5m into the air for two turns. When the spell ends, they all take 1d4 fall damage when they hit the ground."
                }
            ]
        },
        "expertise2": {
            "name": "Skyrunner",
            "main_attr": "DEX",
            "description": "Affecting gravity on self",
            "perks": [
                {
                    "perk_name": "Skyrunner Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Gravity skill modifier now includes your Dexterity modifier as well."
                },
                {
                    "perk_name": "Flight",
                    "lvl_req": 30,
                    "pk_desc": "Levitate Self is replaced with the Flight action.",
                    "action_desc": "On a single charge, you gain 5 minutes of flight. Your movement in combat is not lowered when flying. Be aware, you may take cold or hypoxic damage if you fly too high.",
                    "action_type": "Minor"
                },
                {
                    "perk_name": "Aerodynamic",
                    "lvl_req": 35,
                    "pk_desc": "Your movement when flying is increased by 3m. Your TARG is +2 while flying."
                },
                {
                    "perk_name": "Reflect",
                    "lvl_req": 40,
                    "pk_desc": "During Deflect, any gunfire that misses you is reflected back to the shooter for 1d6 damage."
                },
                {
                    "perk_name": "Aerial Marksman",
                    "lvl_req": 45,
                    "pk_desc": "Rather than receiving disadvantage on ranged attacks when flying, it is now only a -2 penalty."
                },
                {
                    "perk_name": "Supersonic",
                    "lvl_req": 50,
                    "pk_desc": "Your movement when flying is now double your base movement. TARG increases by 2 for every 7m moved. Also, successful melee attacks receive an extra die of damage for every 10m moved while flying prior to the attack."
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Long Range",
            "main_attr": "INT",
            "description": "Attacking from a distance and taking advantage of critical hits.",
            "perks": [
                {
                    "perk_name": "Long Range Gun User",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0."
                },
                {
                    "perk_name": "Focused Breathing",
                    "lvl_req": 5,
                    "pk_desc": "You can now perform the Focused Breathing action.",
                    "action_desc": "If you haven't moved yet, you can forfeit your movement this turn to gain an extra +4 to hit on a ranged attack.",
                    "action_type": "Minor"
                },
                {
                    "perk_name": "Sniper Rifle",
                    "lvl_req": 10,
                    "pk_desc": "Damage die is now a d6. Able to use Sniper Rifles."
                },
                {
                    "perk_name": "Wind Compensation",
                    "lvl_req": 15,
                    "pk_desc": "Critical hit requirement with Long Range guns is lowered by 2."
                },
                {
                    "perk_name": "Tripod Rifles",
                    "lvl_req": 20,
                    "pk_desc": "Damage die is now a d8. Able to use Tripod Rifles."
                }
            ]
        },
        "expertise1": {
            "name": "Sniper",
            "main_attr": "WIS",
            "description": "Low mobility, but improved citical hits.",
            "perks": [
                {
                    "perk_name": "Sniper Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Long Range skill modifier now includes your Wisdom modifier as well."
                },
                {
                    "perk_name": "Bullseye",
                    "lvl_req": 30,
                    "pk_desc": "Crit requirement lowered by 3."
                },
                {
                    "perk_name": "Sitting Duck",
                    "lvl_req": 35,
                    "pk_desc": "You have advantage to hit targets who used no movement their last turn or who currently have a status effect preventing them from moving."
                },
                {
                    "perk_name": "AI Assisted Aim",
                    "lvl_req": 40,
                    "pk_desc": "Crit requirement lowered by 3 when using tripod rifles."
                },
                {
                    "perk_name": "Assassain",
                    "lvl_req": 45,
                    "pk_desc": "Any hit when in Sneak mode is automatically a critical hit."
                },
                {
                    "perk_name": "Target Neutralized",
                    "lvl_req": 50,
                    "pk_desc": "Critical Hit requirement lowered by 2. Critial hits stun opponents for one turn."
                }
            ]
        },
        "expertise2": {
            "name": "Ranger",
            "main_attr": "DEX",
            "description": "More mobility, but lower emphasis on crits.",
            "perks": [
                {
                    "perk_name": "Ranger Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Long Range skill modifier now includes your DEX modifier as well."
                },
                {
                    "perk_name": "Hunter",
                    "lvl_req": 30,
                    "pk_desc": "Can now use Hunting Rifles and Sniper Rifles out of cover like any other gun. Reloading a Hunting Rifle or Sniper Rifle now only requires using a minor action."
                },
                {
                    "perk_name": "Swing Through",
                    "lvl_req": 35,
                    "pk_desc": "Gain +2 to hit an enemy who used at least 7m of movement on their last turn."
                },
                {
                    "perk_name": "Double Tap",
                    "lvl_req": 40,
                    "pk_desc": "When performing a ranged attack with a Long Range gun, the number of attacks is now 2. The second attack has -4 to hit."
                },
                {
                    "perk_name": "Low Recoil",
                    "lvl_req": 45,
                    "pk_desc": "Crit requirement lowered by 3."
                },
                {
                    "perk_name": "Big Game Hunter",
                    "lvl_req": 50,
                    "pk_desc": "Your second attack is no longer penalized. Long Range attacks deal an extra die of damage to any creatures that weigh more than 400kg."
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Negotiation",
            "main_attr": "CHA",
            "description": "Interactions with sentient lifeforms.",
            "perks": [
                {
                    "perk_name": "Coax",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0. No longer at disadvantage when performing a social Glean Information action."
                },
                {
                    "perk_name": "Haggle",
                    "lvl_req": 5,
                    "pk_desc": "You can now perform the Haggle action.",
                    "action_desc": "Once per character per day, you may roll plus skill mod against a character's CHA. On a success, you can buy from them at only +10% the base price and sell to them at only -10% the base price.",
                    "action_type": "Non-Combat"
                },
                {
                    "perk_name": "Silver Tongue",
                    "lvl_req": 10,
                    "pk_desc": "Base skill mod is now 1."
                },
                {
                    "perk_name": "Calm Sentient",
                    "lvl_req": 15,
                    "pk_desc": "You may now perform the Calm Sentient action.",
                    "action_desc": "Roll against a nearby hostile target's INT. On a success, they will cease to be aggressive towards your party. Works on a single sentient target whose language you speak. They can become hostile again any time after.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Pantomime",
                    "lvl_req": 20,
                    "pk_desc": "Base skill mod is now 2. No longer at disadvantage for skill checks where you don't speak the language."
                }
            ]
        },
        "expertise1": {
            "name": "Hustler",
            "main_attr": "WIS",
            "description": "Lying and selling items at a higher price.",
            "perks": [
                {
                    "perk_name": "Hustler Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Negotiation skill modifier now includes your Wisdom modifier as well."
                },
                {
                    "perk_name": "Sweet Little Lies",
                    "lvl_req": 30,
                    "pk_desc": "You have advantage on all Negotiation actions if it is done with a clever lie or deception."
                },
                {
                    "perk_name": "Taunt",
                    "lvl_req": 35,
                    "pk_desc": "You may now perform the Taunt action.",
                    "action_desc": "You taunt a target to throw them off mentally. That target rolls a CHA saving throw of 12+ your skill mod. On a failure, they receive a -2 penalty to their INT, WIS, and CHA modifiers. In exchange, they receive a +1 increase to their STR, DEX, and CON modifiers. Lasts for 1 day.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Bribe",
                    "lvl_req": 40,
                    "pk_desc": "You may now perform the Bribe action.",
                    "action_desc": "You offer a target money or some other material good in exchange for automatically succeeding all Negotiation checks with that target for 1 day. Alternatively, you may be able to bribe enemies to disengage or look the other way to lower global suspicion. GM must agree on how much is fair.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Wheel and Deal",
                    "lvl_req": 45,
                    "pk_desc": "On a successful Haggle, you can now sell items for their listed price."
                },
                {
                    "perk_name": "I Shall Taunt You a Second Time!",
                    "lvl_req": 50,
                    "pk_desc": "Taunt no longer boosts a target's physical attributes. You can attempt to use Haggle and Bribe with object that have no inherit value (such as basic crafting components)."
                }
            ]
        },
        "expertise2": {
            "name": "Charmer",
            "main_attr": "INT",
            "description": "Pursuading and buying items at a lower price.",
            "perks": [
                {
                    "perk_name": "Charmer Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Negotiation skill modifier now includes your Intelligence modifier as well."
                },
                {
                    "perk_name": "Trustworthy",
                    "lvl_req": 30,
                    "pk_desc": "You have advantage on all Negotiation actions when you tell the blatant truth first."
                },
                {
                    "perk_name": "Adulate",
                    "lvl_req": 35,
                    "pk_desc": "You may now perform the Adlate action.",
                    "action_desc": "You compliment a target to dampen their physical resolve. That target rolls a CHA saving throw of 12+ your skill mod. On a failure, they receive a -2 penalty to their STR, DEX, and CON modifiers. In exchange, they receive a +1 increase to their INT, WIS, and CHA modifiers. Lasts for 1 day.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Follow Me",
                    "lvl_req": 40,
                    "pk_desc": "You may now perform the Follow Me action.",
                    "action_desc": "Roll against a non-hostile NPC's CHA. On a success, they will feel compelled to follow you around for at least a day, even into dangerous situations. However, they will not engage in combat unless directly threatened.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Bargain Shopper",
                    "lvl_req": 45,
                    "pk_desc": "On a successful Haggle, you can now buy items for their listed price."
                },
                {
                    "perk_name": "There's Still Some Good In You",
                    "lvl_req": 50,
                    "pk_desc": "You may use Follow Me on non-leader or non-boss intelligent hostiles. They will join your party and engage in combat with you. Lasts for at least a day."
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Observation",
            "main_attr": "INT",
            "description": "Gleaning information from your situation and surroundings.",
            "perks": [
                {
                    "perk_name": "Investigate",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0. No longer at disadvantage when performing an investigational Glean Information action."
                },
                {
                    "perk_name": "Keen-Eyed",
                    "lvl_req": 5,
                    "pk_desc": "All loot rates increase slightly."
                },
                {
                    "perk_name": "Perfect Vision",
                    "lvl_req": 10,
                    "pk_desc": "Base skill mod is now 1."
                },
                {
                    "perk_name": "Thinking Ahead",
                    "lvl_req": 15,
                    "pk_desc": "You may now perform the Thinking Ahead action.",
                    "action_desc": "Roll plus skill mod to think about future possibilities based on a specific or hypothetical lifeform. Depending on the result the GM will give you insight into what could happen in the future in the format of 'If I were….then I would….'. Example: 'If I were the guard of this building, I wouldn't be too suspicious of the elevator running.'",
                    "action_type": "Non-Combat"
                },
                {
                    "perk_name": "Weak Spot",
                    "lvl_req": 20,
                    "pk_desc": "You may now perform the Weak Spot action.",
                    "action_desc": "During a Rest, you may roll plus skill mod to think about future possibilities based on a specific or hypothetical lifeform. Depending on the result the GM will give you insight into possible weaknesses of such a lifeform.",
                    "action_type": "Rest"
                }
            ]
        },
        "expertise1": {
            "name": "Insight",
            "main_attr": "CHA",
            "description": "Picking up the bigger picture in conversations or situations.",
            "perks": [
                {
                    "perk_name": "Insight Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Observation skill modifier now includes your Charisma modifier as well."
                },
                {
                    "perk_name": "Empathize",
                    "lvl_req": 30,
                    "pk_desc": "You may now perform the Empathize action.",
                    "action_desc": "Once per day, you gain unique insight into an intelligent lifeform's motivations, giving you advantage on any non-combat rolls related to interactions with that lifeform.",
                    "action_type": "Non-Combat"
                },
                {
                    "perk_name": "Hiding Something",
                    "lvl_req": 35,
                    "pk_desc": "You may now perform the Hiding Something action.",
                    "action_desc": "While in discussions with an intelligent creature, you may call out that you believe they're hiding something. Roll against that creature's CHA. On a success, they will have to be forthright with you from now on. On a failure, you have disadvantage on all Negotiation checks with that character.",
                    "action_type": "Non-Combat"
                },
                {
                    "perk_name": "Sleuth",
                    "lvl_req": 40,
                    "pk_desc": "Skill proficiency +2. A failed Investigate roll will not have consequences."
                },
                {
                    "perk_name": "Learn From Your Mistakes",
                    "lvl_req": 45,
                    "pk_desc": "Once per day, you may re-roll a failed Non-Combat roll, assuming the damages caused by the failed roll can be reversed."
                },
                {
                    "perk_name": "Project Management",
                    "lvl_req": 50,
                    "pk_desc": "You may now perform the Project Management action.",
                    "action_desc": "Once per day, you may select a skill that you believe will be highly useful. All party members, including yourself, receive a +2 to that skill modifier for 1 day.",
                    "action_type": "Non-Combat"
                }
            ]
        },
        "expertise2": {
            "name": "Perception",
            "main_attr": "WIS",
            "description": "Noticing the little details all around.",
            "perks": [
                {
                    "perk_name": "Perception Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Observation skill modifier now includes your Wisdom modifier as well."
                },
                {
                    "perk_name": "Saw it Coming",
                    "lvl_req": 30,
                    "pk_desc": "If an enemy gains a bonus round because of a sneak attack, you also get to join the bonus round with standard initiative order."
                },
                {
                    "perk_name": "Klepto",
                    "lvl_req": 35,
                    "pk_desc": "All loot rates increase significantly."
                },
                {
                    "perk_name": "Strategic Positioning",
                    "lvl_req": 40,
                    "pk_desc": "When the GM indicates the start of an encounter without a surprise attack, you party is given the chance to move themselves to a square within 5m of their current positionprior to starting the encounter. Party members within melee range of an enemy cannot participate in this."
                },
                {
                    "perk_name": "Eagle Eye",
                    "lvl_req": 45,
                    "pk_desc": "You gain a +1 to hit on all Ranged Attacks."
                },
                {
                    "perk_name": "One Step Ahead",
                    "lvl_req": 50,
                    "pk_desc": "Once per encounter, your ability to recognize danger and act quickly allows you to automatically succeed a saving throw. You may choose to use this ability after the GM has informed you of what type of saving throw you would be performing, but not after you roll."
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Pistol",
            "main_attr": "DEX",
            "description": "Short-range firearms that can be used in the off-hand.",
            "perks": [
                {
                    "perk_name": "Pistol User",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0."
                },
                {
                    "perk_name": "Quickdraw",
                    "lvl_req": 5,
                    "pk_desc": "May now perform the Quickdraw action.",
                    "action_desc": "If only using one pistol in your main hand, at the start of an encounter before rolling initiative you may roll plus your skill mod against a single target's DEX to try and get a bonus attack. If you fail the roll, you use a bullet and miss and go last in initiative order for the encounter. If you succeed, you still roll to hit with a single damage die and cannot crit but then enter standard initiative order. This is negated if an enemy gets in a surprise attack.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Bolt Pistol",
                    "lvl_req": 10,
                    "pk_desc": "Damage die is now a d6. Able to use Bolt Pistols."
                },
                {
                    "perk_name": "Semi-Auto",
                    "lvl_req": 15,
                    "pk_desc": "May attack twice with a pistol equipped in your main hand. May have one pistol equipped without raising Global Suspicion."
                },
                {
                    "perk_name": "Heavy Pistol",
                    "lvl_req": 20,
                    "pk_desc": "Damage die is now a d8. Able to use Heavy Pistols."
                }
            ]
        },
        "expertise1": {
            "name": "Duelist",
            "main_attr": "CHA",
            "description": "Mastery of a single pistol.",
            "perks": [
                {
                    "perk_name": "Duelist Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Pistol skill modifier now includes your Wisdom modifier as well."
                },
                {
                    "perk_name": "Draw!",
                    "lvl_req": 30,
                    "pk_desc": "Attack twice on a successful Quickdraw. Any attacks from a successful Quickdraw may now crit. You enter standard initiative order on a failed Quickdraw."
                },
                {
                    "perk_name": "Find an Opening",
                    "lvl_req": 35,
                    "pk_desc": "Base skill mod is now 2. You have no penalty for shooting from cover when only holding a single pistol."
                },
                {
                    "perk_name": "Make It Count",
                    "lvl_req": 40,
                    "pk_desc": "The last round in your magazine has +2 to hit and deals an extra die of damage."
                },
                {
                    "perk_name": "Tactical Training",
                    "lvl_req": 45,
                    "pk_desc": "Movement and sprinting, including swimming and flying, do not affect Ranged Attacks with a single pistol."
                },
                {
                    "perk_name": "This Town Ain't Big Enough",
                    "lvl_req": 50,
                    "pk_desc": "You have advantage to hit when performing the Quickdraw action. Crit requirement with a single pistol is reduced by 2."
                }
            ]
        },
        "expertise2": {
            "name": "Gunslinger",
            "main_attr": "INT",
            "description": "Twice the shooting, but may be left a bit more vulnerable.",
            "perks": [
                {
                    "perk_name": "Gunslinger Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Pistol skill modifier now includes your INT modifier as well."
                },
                {
                    "perk_name": "Pistolero",
                    "lvl_req": 30,
                    "pk_desc": "Pistols in your off-hand no longer have a penalty to hit. May attack once with each equipped pistol on a successful Quickdraw."
                },
                {
                    "perk_name": "Body Holster",
                    "lvl_req": 35,
                    "pk_desc": "You can reload two pistols with a single Reload action. May have two pistols equipped without raising Global Suspicion."
                },
                {
                    "perk_name": "Peripheral Precision",
                    "lvl_req": 40,
                    "pk_desc": "You may now target two enemies simultaneously, meaning you can deal 1 pistol worth of damage to two targets for all attacks this turn. If you do this, every attack has -2 to hit."
                },
                {
                    "perk_name": "High-Efficiency Magazines",
                    "lvl_req": 45,
                    "pk_desc": "May attack twice with a pistol equipped in your off hand."
                },
                {
                    "perk_name": "Multi-Tasker",
                    "lvl_req": 50,
                    "pk_desc": "No longer have a penalty to hit when targetting two enemies with a Ranged Attack."
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Psyche",
            "main_attr": "WIS",
            "description": "Manipulation of mind and body.",
            "perks": [
                {
                    "perk_name": "Center Yourself",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0. You can now perform the Center Yourself action",
                    "action_desc": "As a minor action or non-combat action, you can use a charge to gain control over your mind and body to focus on your next task. You gain +2 on your next non-damage roll.",
                    "action_type": "Minor"
                },
                {
                    "perk_name": "Renewal",
                    "lvl_req": 5,
                    "pk_desc": "You can now perform the Renewal action.",
                    "action_desc": "You lay a hand on yourself or a single target. They are healed for 1d6+ your skill mod for each charge you decide to use.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Zone Out",
                    "lvl_req": 10,
                    "pk_desc": "You can now perform the Zone Out action.",
                    "action_desc": "A target of your choice must make an Wis saving throw of 12+ your skill mod. On a failure, they have a lapse in memory of the past 5 minutes, forgetting conversations and events from that time period. In combat, this will give target disadvantage on their action rolls for 2 turns.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Medicinal Touch",
                    "lvl_req": 15,
                    "pk_desc": "Renewal now heals 2d6+ skill mod HP on one target or 1d6+ skill mod HP to two targets you can reach."
                },
                {
                    "perk_name": "Homeostasis",
                    "lvl_req": 20,
                    "pk_desc": "You can now perform the Homeostasis action.",
                    "action_desc": "You lay your hand on a single target to remove all status effects plagueing them.",
                    "action_type": "Major"
                }
            ]
        },
        "expertise1": {
            "name": "Mentalist",
            "main_attr": "CHA",
            "description": "Forcing people to do what you want or hamper their minds and bodies.",
            "perks": [
                {
                    "perk_name": "Mentalist Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Psyche skill modifier now includes your Charisma modifier as well."
                },
                {
                    "perk_name": "Commanding Presence",
                    "lvl_req": 30,
                    "pk_desc": "You can now perform the Commanding Presence action.",
                    "action_desc": "You add your Psyche skill mod for any Negotiation checks for 1 hour.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Suggestion",
                    "lvl_req": 35,
                    "pk_desc": "You can now perform the Suggestion action.",
                    "action_desc": "Roll against a sentient target's WIS. On a success, you can command them to carry out a single action where they will not directly harm themself. You may direct an enemy to step into the line of fire or shoot another enemy, but you may not have them jump from a height that will cause fall damage or have them attack themself. When in combat, this action will be performed on that character's next turn, not during your turn.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Amnesia",
                    "lvl_req": 40,
                    "pk_desc": "You determine the amount of time that is forgotten with Zone Out, up to 24 hours."
                },
                {
                    "perk_name": "Mind Flay",
                    "lvl_req": 45,
                    "pk_desc": "You can now perform the Mind Flay action.",
                    "action_desc": "A non-leader or non-boss target of your choice makes a WIS saving throw of 10+ your skill mod. On a success, they take 1d8+ your skill mod mental damage but you have no control over them. On a failure, they take no damage but are fully under your control. This lasts for 5 turns or until they are attacked or healed. Outside of combat, they are under your control for 1 hour.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Thrall",
                    "lvl_req": 50,
                    "pk_desc": "A character under your control due to Mind Flay will no longer snap out of your control when attacked by either side of the fight. This lasts for 10 turns in combat or 1 day outside of combat."
                }
            ]
        },
        "expertise2": {
            "name": "Healer",
            "main_attr": "CON",
            "description": "Healing and buffing your team.",
            "perks": [
                {
                    "perk_name": "Healer Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Psyche skill modifier now includes your Constitution modifier as well."
                },
                {
                    "perk_name": "Miraculous Healing",
                    "lvl_req": 30,
                    "pk_desc": "Add two more d6 dice for use with Renewal. Homeostasis now heals for 2d6+ skill mod HP."
                },
                {
                    "perk_name": "Performance Enhancement",
                    "lvl_req": 35,
                    "pk_desc": "You can now perform the Performance Enhancement action.",
                    "action_desc": "You grant a single target, including yourself if you choose, a +2 increase to a single attribute modifier of your choice and any skills that use it. This lasts for 1 hour. After that hour, the target receives a -1 to that same attribute modifier for 1 hour.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Sleep",
                    "lvl_req": 40,
                    "pk_desc": "You can now perform the Sleep action.",
                    "action_desc": "A target of your choice makes a WIS saving throw of 12+ your skill mod. On a failure, they are put to sleep and will remain asleep until they are attacked or woken by an ally.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Hospital",
                    "lvl_req": 45,
                    "pk_desc": "You can now perform the Hospital action.",
                    "action_desc": "All targets within a 6m radius of your current position are healed for 2d8+ your skill mod damage. This includes enemies and allies.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Guardian Angel",
                    "lvl_req": 50,
                    "pk_desc": "You can now perform the Guardian Angel action.",
                    "action_desc": "You lay your hands on a target to receive immunity from all damage and status effects for two turns. You can only do this once per character per encounter.",
                    "action_type": "Major"
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Tech",
            "main_attr": "INT",
            "description": "Working with both modern and ancient technologies, and even building your own.",
            "perks": [
                {
                    "perk_name": "CTRL + F",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0. No longer at disadvantage when performing a technological Glean Information check."
                },
                {
                    "perk_name": "Augmenter",
                    "lvl_req": 5,
                    "pk_desc": "When you have access to a workstation, you can create simple mods with proper components."
                },
                {
                    "perk_name": "Patch Up",
                    "lvl_req": 10,
                    "pk_desc": "You may now perform the Patch Up action.",
                    "action_desc": "Using Spare Parts, you can repair non-organic objects, including cybernetics, robots, vehicles, etc. In terms of battle or HP implications, this amounts to 2d6+ your skill mod HP. For objects with no specific HP value, you repair items to a useable condition, though perhaps not fully functional.",
                    "action_type": "Non-Combat"
                },
                {
                    "perk_name": "Program Drone",
                    "lvl_req": 15,
                    "pk_desc": "You can craft basic drones, and have access to the Program Drone action. When drones are first activated, they will hover 1m above and 1m to the side of you and will follow you at that distance until reprogrammed. Drones can be controlled up to 100m away from your current position. When attacked, it has 1HP, 0 DIS, 0 KIN, 16 TARG, and 8 EVA.",
                    "action_desc": "You change the programming on one currently active drone. For Scout drones, that could be following a new target. For Delivery drones that could be to retrieve an item. For Sentry drones, that could be to change targets.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Pseudocode",
                    "lvl_req": 20,
                    "pk_desc": "Base skill mod is now 1. No longer at disadvantage when handling unknown tech."
                }
            ]
        },
        "expertise1": {
            "name": "Hacker",
            "main_attr": "WIS",
            "description": "Working with and controlling computers and other electronic devices.",
            "perks": [
                {
                    "perk_name": "Hacker Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Tech skill modifier now includes your Wisdom modifier as well."
                },
                {
                    "perk_name": "Hack",
                    "lvl_req": 30,
                    "pk_desc": "CRTL + F is replaced by the Hack action.",
                    "action_desc": "Roll plus skill mod to take control of a local device or any other devices connected to its server. You can get any information on the server and will not be noticed by any non-AI. You may be able to control non-AI robots, but they get to roll an INT saving throw of 12+ your skill mod.",
                    "action_type": "Non-Combat"
                },
                {
                    "perk_name": "Streamlined Commands",
                    "lvl_req": 35,
                    "pk_desc": "May reprogram two drones with a single Program Drone action."
                },
                {
                    "perk_name": "Advanced Drones",
                    "lvl_req": 40,
                    "pk_desc": "May craft advanced drones."
                },
                {
                    "perk_name": "Decryptor Drive",
                    "lvl_req": 45,
                    "pk_desc": "You may craft Decryptor Drives. Hacks with a decryptor drive are unaffected by global suspicion. Using a drive allows you to use Hack on AI-controlled systems and robots. You may only control one AI system or robot at a time. Also, you can perform remote Hacks by attaching a Decryptor Drive to a drone."
                },
                {
                    "perk_name": "Drone Army",
                    "lvl_req": 50,
                    "pk_desc": "May reprogram three drones with a single Program Drone action."
                }
            ]
        },
        "expertise2": {
            "name": "Tinkerer",
            "main_attr": "CON",
            "description": "Building new objects and repairing damaged ones.",
            "perks": [
                {
                    "perk_name": "Tinkerer Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Tech skill modifier now includes your Constitution modifier as well."
                },
                {
                    "perk_name": "Inventor",
                    "lvl_req": 30,
                    "pk_desc": "Can now create advanced mods with proper components."
                },
                {
                    "perk_name": "Looter",
                    "lvl_req": 35,
                    "pk_desc": "You collect double components from non-organic sources, including mechanical enemies."
                },
                {
                    "perk_name": "Robot Companion",
                    "lvl_req": 40,
                    "pk_desc": "You may now craft a robot companion with the proper materials. Building a robot requires a 24 hour period near a workstation. The robot will follow you and do what you command it to do. It can engage in combat, with one weapon slot and one armor mod slot. Its base stats are 10 HP, 15 KIN, 10 DIS, 10 TARG, and 6 EVA."
                },
                {
                    "perk_name": "Good as New",
                    "lvl_req": 45,
                    "pk_desc": "Patch Up now heals 4d6+ skill mod HP per component used. For objects with no specific HP value, you repair items fully, and may even be able to give them additional functionality as long as the GM approves of the new function."
                },
                {
                    "perk_name": "Advanced Robotics",
                    "lvl_req": 50,
                    "pk_desc": "Robots you craft now have 2 weapon slots and 3 armor mod slots. Its base stats are now 20 HP, 25 DIS, 20 KIN, 10 TARG, and 6 EVA."
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Unarmed",
            "main_attr": "STR",
            "description": "Using your bare hands to deal damage and control the battle.",
            "perks": [
                {
                    "perk_name": "Unarmed User",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0. "
                },
                {
                    "perk_name": "Sweeping Kick",
                    "lvl_req": 5,
                    "pk_desc": "You can now perform the Sweeping Kick action.",
                    "action_desc": "Roll to hit and deal 1d6 damage plus skill mod. Enemy makes a Dex saving throw of 12+ your skill mod. On a failure, they are knocked prone.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Combat Gloves",
                    "lvl_req": 10,
                    "pk_desc": "Damage die is now a d6. Able to use Combat Gloves"
                },
                {
                    "perk_name": "Jab Jab",
                    "lvl_req": 15,
                    "pk_desc": "No penalty for performing an Unarmed attack with your offhand."
                },
                
                {
                    "perk_name": "Power Gauntlets",
                    "lvl_req": 20,
                    "pk_desc": "Damage die is now a d8. Able to use Power Gauntlets."
                }
            ]
        },
        "expertise1": {
            "name": "Martial Artist",
            "main_attr": "DEX",
            "description": "Dodging, disarming, and causing status effects.",
            "perks": [
                {
                    "perk_name": "Martial Artist Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Unarmed skill modifier now includes your Dexterity modifier as well."
                },
                {
                    "perk_name": "Float Like a Butterfly...",
                    "lvl_req": 30,
                    "pk_desc": "+2 EVA when no weapons are equipped."
                },
                {
                    "perk_name": "Disarm",
                    "lvl_req": 35,
                    "pk_desc": "You can now perform the Disarm action.",
                    "action_desc": "You target an enemy within melee range to disarm. They roll a DEX saving throw of 14+ your skill mod. On a failure, you grab the weapon out of their hands and can either equip it yourself or throw it to the ground.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Pressure Point",
                    "lvl_req": 40,
                    "pk_desc": "You can now perform the Pressure Point action.",
                    "action_desc": "Roll to hit a melee attack. On a success, you may choose to deal no damage and instead cause a status effect to a body part of your target for three turns. Head = Blind, Legs = Paralyzed, and Arms = Burdened.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Kung Fu Master",
                    "lvl_req": 45,
                    "pk_desc": "You now add your Unarmed modifier to your EVA when no weapons are equipped."
                },
                {
                    "perk_name": "Go No Sen",
                    "lvl_req": 50,
                    "pk_desc": "When an enemy misses a melee attack on you, they cannot attempt more attacks. Instead, you may perform any Unarmed attack or action out-of-turn."
                }
            ]
        },
        "expertise2": {
            "name": "Brawler",
            "main_attr": "CON",
            "description": "Giving and taking blows.",
            "perks": [
                {
                    "perk_name": "Brawler Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Unarmed skill modifier now includes your Constitution modifier as well."
                },
                {
                    "perk_name": "...Sting Like a Bee",
                    "lvl_req": 30,
                    "pk_desc": "When performing an unarmed attack, may attack twice with your main hand. Any successful Unarmed hit roll may be transferred to any remaining Unarmed attacks this turn, including off hand attacks. In other words, if your second roll to hit is an 18, you can choose to automatically receive an 18 for your third roll."
                },
                {
                    "perk_name": "Uppercut",
                    "lvl_req": 35,
                    "pk_desc": "You can now perform the Uppercut action.",
                    "action_desc": "Roll to hit a single melee attack on a targets head, with a -2 to hit. If you roll a 14 or greater, the enemy is Stunned for 2 turns on top of normal melee damage.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Shake It Off",
                    "lvl_req": 40,
                    "pk_desc": "Once per encounter, you may choose to automatically succeed a failed saving throw that results in a status effect."
                },
                {
                    "perk_name": "Put 'Em Up",
                    "lvl_req": 45,
                    "pk_desc": "All Power Guanlets grant you an additional +3 EVA."
                },
                {
                    "perk_name": "Haymaker",
                    "lvl_req": 50,
                    "pk_desc": "You can now perform the Haymaker action.",
                    "action_desc": "Roll to hit a single melee attack with a -2 to hit. On a hit, you deal double damage (quadruple if you crit) and the opponent must roll a CON saving throw of 14+ your skill mod. On a failure, they are knocked prone and are Stunned for 3 turns.",
                    "action_type": "Major"
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Vehicles",
            "main_attr": "CON",
            "description": "Controlling all types of vehices. Main skill for pilots in space battles.",
            "perks": [
                {
                    "perk_name": "Swerve",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0. You can now perform the Swerve action.",
                    "action_desc": "On a failed vehicles check, you may try the check again to regain control, but with -2 to the final result.",
                    "action_type": "Bonus"
                },
                {
                    "perk_name": "Grease Monkey",
                    "lvl_req": 5,
                    "pk_desc": "You can now perform the Grease Monkey action.",
                    "action_desc": "You can repair vehicles that still have more than half current HP without having to use spare parts for 2d8 HP.",
                    "action_type": "Non-Combat"
                },
                {
                    "perk_name": "Motorhead",
                    "lvl_req": 10,
                    "pk_desc": "Skill proficiency +1. No longer at a disadvantage handling unknown vehicles."
                },
                {
                    "perk_name": "Ramming Speed",
                    "lvl_req": 15,
                    "pk_desc": "You may now perform the Ramming Speed action.",
                    "action_desc": "May use all vehicle movement to ram and deal damage to enemies or obstacles. Enemies not in vehicles roll a dex saving throw of 12+ skill mod, on a failure, you deal 4d6 damage. On a success, you deal no damage, but they must move 2 spaces away. For obstacles, including other vehicles, you roll a saving throw of 18 - skill mod. On a success, roll 3d6. You deal that much damage to your vehicle and twice that amount to the obstacle. On a failure, damage is equal.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Evasive Maneuver",
                    "lvl_req": 20,
                    "pk_desc": "You may now perform the Evasive Maneuver action.",
                    "action_desc": "Roll plus skill mod against the highest enemy DEX + vehicles modifier. On a success, they are at a disadvantage for every hit roll against you for three turns.",
                    "action_type": "Major"
                }
            ]
        },
        "expertise1": {
            "name": "Driver",
            "main_attr": "STR",
            "description": "Operating gravity-affected vehicles.",
            "perks": [
                {
                    "perk_name": "Driver Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Vehicles skill modifier now includes your Strength modifier as well."
                },
                {
                    "perk_name": "Hijack",
                    "lvl_req": 30,
                    "pk_desc": "You may now perform the Hijack action.",
                    "action_desc": "While either in your own vehicle or on the ground and nearby an enemy vehicle, roll an Athletics check to attempt to jump onto the enemy vehicle and Hijack it. If you roll 0-10, you fail. If you roll 11-15 you are able to mount the vehicle, but unable to remove the driver. On a 16+ you mount the vehicle and remove the driver taking full control of that vehicle.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Motorcycle Gang",
                    "lvl_req": 35,
                    "pk_desc": "You may use a personal land vehicle (such as a motorcycle) in combat with only one hand, though you receive a -4 penalty to Vehicle checks and attack hit dice rolls."
                },
                {
                    "perk_name": "Mech Suit Driver",
                    "lvl_req": 40,
                    "pk_desc": "May now purchase and use basic Mech Suits."
                },
                {
                    "perk_name": "Off Roading",
                    "lvl_req": 45,
                    "pk_desc": "Difficult terrain no longer hinders your vehicle movement or actions."
                },
                {
                    "perk_name": "I Am Titanium Man",
                    "lvl_req": 50,
                    "pk_desc": "May purchase and use advanced Mech Suits."
                }
            ]
        },
        "expertise2": {
            "name": "Pilot",
            "main_attr": "DEX",
            "description": "Operating space and underwater vehicles.",
            "perks": [
                {
                    "perk_name": "Pilot Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Vehicles skill modifier now includes your Dexterity modifier as well."
                },
                {
                    "perk_name": "Cloaking Device",
                    "lvl_req": 30,
                    "pk_desc": "You may now perform the Cloaking Device action.",
                    "action_desc": "You may enter Sneak mode when in a Space or Underwater vessel. All enemies within radar range roll an Observation check of 12+ your skill mod. While cloaked you may move undetected and can gain a sneak attack round when starting an encounter.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Probes",
                    "lvl_req": 35,
                    "pk_desc": "May craft probes from proper components."
                },
                {
                    "perk_name": "Sensor Scrambling",
                    "lvl_req": 40,
                    "pk_desc": "Cloaking device may be used during combat once per encounter to try and confuse enemy ships. Each ship that fails the Observation check has disadvantage on attacking your ship for two turns."
                },
                {
                    "perk_name": "Expert Navigator",
                    "lvl_req": 45,
                    "pk_desc": "You have advantage on Negotiation and Observation checks checks when pilotting a space or underwater vehicle."
                },
                {
                    "perk_name": "Squad Leader",
                    "lvl_req": 50,
                    "pk_desc": "You may now perform the Squad Leader action.",
                    "action_desc": "Once per space or underwater ship encounter, you may let your crewmates use your crewmates use your Vehicles modifier when performing vehicle-related actions. This effect lasts for 5 turns.",
                    "action_type": "Major"
                }
            ]
        }
    },
    {
        "baseSkill": {
            "name": "Wave",
            "main_attr": "WIS",
            "description": "Controlling light and sound waves.",
            "perks": [
                {
                    "perk_name": "Illuminate",
                    "lvl_req": 1,
                    "pk_desc": "Base skill mod is now 0. You can now perform the Illuminate action",
                    "action_desc": "An orb of light appears in your hands and illuminates in a 30-foot sphere. You may either keep the light indefinitely a hand used up, or you may cast the light in a single direction. When cast, it will dissipate when it hits an object. Does not use a charge.",
                    "action_type": "Non-Combat"
                },
                {
                    "perk_name": "Throw Voice",
                    "lvl_req": 5,
                    "pk_desc": "You can now perform the Throw Voice action.",
                    "action_desc": "You can make your voice come from a location up to 40m away for up to 5 minutes.",
                    "action_type": "Non-Combat"
                },
                {
                    "perk_name": "Night Vision",
                    "lvl_req": 10,
                    "pk_desc": "You now have full vision in dim light. If you already had full vision in dim light (due to race ability or a mod) then you have full vision in any lighting except absolute darkness."
                },
                {
                    "perk_name": "Refract",
                    "lvl_req": 15,
                    "pk_desc": "Gain access to the Refract action.",
                    "action_desc": "You refract the light around you, making you harder to see. When standing still, you are completely invisible. In combat, you have a +4 to TARG for 5 turns. Outside of combat, lifeforms must pass an Observation check of 12+ your Espionage skill modifier to notice you and lasts for 5 minutes.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Harmonic Frequency",
                    "lvl_req": 20,
                    "pk_desc": "You can now perform the Harmonic Frequency action.",
                    "action_desc": "You place a hand on the ground and radiate a harmonic frequency causing the ground to shake. Everything in a 10m radius must make a CON saving throw of 12+ your skill mod. On a failure, they are knocked prone.",
                    "action_type": "Major"
                }
            ]
        },
        "expertise1": {
            "name": "Light",
            "main_attr": "DEX",
            "description": "Creating visual illusions and changing light levels.",
            "perks": [
                {
                    "perk_name": "Light Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Wave skill modifier now includes your Dexterity modifier as well."
                },
                {
                    "perk_name": "Obscure",
                    "lvl_req": 30,
                    "pk_desc": "You can now perform the Obscure action.",
                    "action_desc": "You can dim the amount of light in a 40m radius sphere to any level except absolute darkness for up to 1 hour.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Illusion",
                    "lvl_req": 35,
                    "pk_desc": "You can now perform the Illusion action.",
                    "action_desc": "You may create a visual illusion that can fit inside a 3m cube. You can fully customize the visuals of the illusion and can make it move, but it cannot make any noise. Creatures must pass an Observation check of 14+ your skill mod to become aware of the illusion.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Invisibility",
                    "lvl_req": 40,
                    "pk_desc": "Refract now fully conceals your appearance and lasts for 1 hour. All creatures have disadvantage to the Observation check to notice you."
                },
                {
                    "perk_name": "Blinding Flash",
                    "lvl_req": 45,
                    "pk_desc": "You can now perform the Blinding Flash action.",
                    "action_desc": "Pick a space in which to create a blinding flash. All creatures in a 5m radius must make a DEX saving throw of 12+ your skill mod to shield their eyes in time. On a failure, they are blinded for 5 turns. If you alert your teammates prior to the flash, they have advantage on the saving throw.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Waking Dream",
                    "lvl_req": 50,
                    "pk_desc": "Illusion can now alter the appearance of a 30m space. On top of other possible benefits, any being unaware of the illusion receives a -2 penalty to their INT and WIS modifiers and any skills or checks that affects."
                }
            ]
        },
        "expertise2": {
            "name": "Sound",
            "main_attr": "CON",
            "description": "Auditory illusions and damage.",
            "perks": [
                {
                    "perk_name": "Sound Expertise",
                    "lvl_req": 25,
                    "pk_desc": "Your Wave skill modifier now includes your Constitution modifier as well."
                },
                {
                    "perk_name": "Tinnitus",
                    "lvl_req": 30,
                    "pk_desc": "You may now perform the Tinnitus action.",
                    "action_desc": "You fill a targets ears with a painful ringing. They must roll a CON saving throw of 12+ your skill mod. On a faliure, they are stunned and 1d6 take mental damage for 3 turns.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Impersonate",
                    "lvl_req": 35,
                    "pk_desc": "You may now perform the Impersonate action.",
                    "action_desc": "You fill a targets ears with a painful ringing. They must roll a CON saving throw of 12+ your skill mod. On a faliure, they are stunned and 1d6 take mental damage for 3 turns.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Inspirational Rhythm",
                    "lvl_req": 40,
                    "pk_desc": "You may now perform the Inspirational Rhythm action.",
                    "action_desc": "All teammates who can hear you have a +2 to all saving throws for 3 turns.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Foreboding Melody",
                    "lvl_req": 45,
                    "pk_desc": "You may now perform the Foreboding Melody action.",
                    "action_desc": "All enemies who can hear you receive a -2 penalty to their CON and CHA modifiers and any skills or checks that affects.",
                    "action_type": "Major"
                },
                {
                    "perk_name": "Disintegrate",
                    "lvl_req": 50,
                    "pk_desc": "You may now perform the Disintegrate action.",
                    "action_desc": "You may lay your hand on a solid object and create vibrations strong enough to disintegrate it. This works on small objects, small barriers, and may even work on certain doors and walls. You may also use this to destroy armor, though the wearer may roll a DEX saving throw of 10+ your skill mod. If they succeed, you don't destroy their armor and they may perform a melee attack on you immediately.",
                    "action_type": "Major"
                }
            ]
        }
    }
]