import React, { Fragment} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveCharacter } from '../../../actions/character';
import { allStatusEffects, damageTypes } from '../../../resources/arrays';

const CharEditStatus = ({
    character: {
        character
    },
    saveCharacter
}) => {

    const {
        currentHP,
        baseHP,
        currentDIS,
        baseDIS,
        currentKIN,
        baseKIN,
        TARG,
        EVA,
        statusEffects,
        weaknesses,
        resistances,
        immunities,
        magazines,
        head,
        chest,
        hands,
        feet,
        shield,
        mainHand,
        offHand,
        rechargeAvailable,
        psycheStim,
        psycheCharges,
        energyStim,
        energyCharges,
        fluidStim,
        fluidCharges,
        gravStim,
        gravCharges,
        waveStim,
        waveCharges,
        inventory
    } = character;

    const eqHead = inventory.find(i => i.equipped === "Head");
    const eqBody = inventory.find(i => i.equipped === "Body");
    const eqHands = inventory.find(i => i.equipped === "Hands");
    const eqFeet = inventory.find(i => i.equipped === "Feet");
    const eqMainHand = inventory.find(i => i.equipped === "Main Hand");
    const eqOffHand = inventory.find(i => i.equipped === "Off Hand");

    const batteries = inventory.filter(i => i.name === "Stim Battery");

    const onChange = e => {
        var newInput = e.target.value;

        if(
            e.target.name === 'currentHP'
        ){
            newInput > baseHP && (newInput = baseHP);
        }

        if(
            e.target.name === 'currentDIS'
        ){
            newInput > baseDIS && (newInput = baseDIS);
        }

        if(
            e.target.name === 'currentKIN'
        ){
            newInput > baseKIN && (newInput = baseKIN);
        }

        saveCharacter({
            ...character,
            [e.target.name]: newInput
        });
    }

    const activeEffects = arr => {
        const effects = arr.map((effect, index) => 
            <div key={index} onClick={removeFromStatusArray(arr, index)}>{effect}</div>
        )

        return effects;
    }

    const updateSEDropdown = statusEffects => {
        var allSEs = allStatusEffects.filter((s) => !statusEffects.includes(s));

        const seOptions = allSEs.map((s, index) => 
            <option key={index}>{s}</option>
        )

        return seOptions;
    }

    const updateDTDropdown = arr => {
        var allSEs = damageTypes.filter((s) => !arr.includes(s));

        const seOptions = allSEs.map((s, index) => 
            <option key={index}>{s}</option>
        )

        return seOptions;
    }

    const addToStatusArray = arr => (e) => {
        arr.push(e.target.value);
        
        saveCharacter({
            ...character,
            [e.target.name]: arr
        });
    }

    const removeFromStatusArray = (arr, ind) => (e) => {
        arr.splice(ind, 1);
        var propName = e.currentTarget.closest('.status-item').id;

        saveCharacter({
            ...character,
            [propName]: arr
        });
    }

    const populateCharges = (chargeName, maxCharges, chargeVal) => {

        var chargeElements = [];

        //For now, i goes up to 6. In the future, we will use a stim object's total charges
        for(let i = 0; i < maxCharges; i++){
            var ele = <div className={i < chargeVal ? "charge-item charged" : "charge-item"} onClick={chargeAction(chargeName, chargeVal)}></div>;
            chargeElements.push(ele);
        }

        return chargeElements;
    }

    const updateStim = e => {

        var maxCharges = 0
        
        switch(e.target.value){
            case 'Novice':
                maxCharges = 2;
                break;
            case 'Apprentice':
                maxCharges = 4;
                break;
            case 'Master':
                maxCharges = 6;
                break;
            default:
                maxCharges = 0;
                break
        }

        saveCharacter({
            ...character,
            [e.target.name]: { name: e.target.value, totalCharges: maxCharges}
        });
    }

    const updateEquipment = (equipName, equipObj) => e => {
        saveCharacter({
            ...character,
            [equipName]: {
                ...equipObj,
                [e.target.name]: e.target.value
            }
        });
    }

    const rechargeStim = (chargeName, numCharges, stim) => (e) => {

        console.log(batteries)

        if(batteries.length > 0 && numCharges < stim.totalCharges){

            const newBat = character.inventory.findIndex(i => i.name === "Stim Battery");
            character.inventory.splice(newBat, 1);

            saveCharacter({
                ...character,
            [chargeName]: numCharges + 1,
            "inventory": character.inventory
            });
        }
    }

    const chargeAction = (chargeName, charge) => e => {
        if(charge > 0 && e.target.className==="charge-item charged"){
            saveCharacter({
                ...character,
                [chargeName]: charge - 1
            });
        }
    }

    const updateGun = (equipSlot) => e => {

        const gun = character.inventory[character.inventory.findIndex(i => i.equipped === equipSlot)];

        if(e.target.name === 'attack'){
            if(gun.currentAmmo < 1) return;
            gun.currentAmmo -= 1;
        } else {
            const newMag = character.inventory.findIndex(i => i.name === "Gun Magazine");
            if(newMag !== -1 && gun.currentAmmo !== gun.magSize){
                character.inventory.splice(newMag, 1);
                gun.currentAmmo = gun.magSize;
            }
            
        }

        saveCharacter({
            ...character,
            "inventory": character.inventory
        });
    }

    return (
        <Fragment>
            <div className="edit-char-status-panel">
                <div className="health-container">
                    <div className="health-subcontainer">
                        <div>
                            <p class="lead text-blue">HP</p>
                            <div class="health-fill" style={{width: currentHP/baseHP*300 + 'px'}}></div>
                            <div class="health-border">
                                <input type="number" name='currentHP' value={currentHP} onChange={onChange}/>/<span>{baseHP}</span>
                            </div>
                        </div>
                        <div>
                            <p class="lead text-blue">DIS</p>
                            <div class="dis-fill" style={{width: currentDIS/baseDIS*300 + 'px'}}></div>
                            <div class="health-border">
                                <input type="number" name='currentDIS' value={currentDIS} onChange={onChange}/>/<span>{baseDIS}</span>
                            </div>
                        </div>
                    </div>
                    <div className="health-subcontainer">
                        <div>
                            <p class="lead text-blue">KIN</p>
                            <div class="kin-fill" style={{width: currentKIN/baseKIN*300 + 'px'}}></div>
                            <div class="health-border">
                                <input type="number" name='currentKIN' value={currentKIN} onChange={onChange}/>/<span>{baseKIN}</span>
                            </div>
                        </div>
                        <div className="targ-eva">
                            <div class="ac lead text-blue">
                                TARG<br /><input type="number" name='TARG' value={TARG} onChange={onChange} />
                            </div>
                            <div class="ac lead text-blue">
                                EVA<br /><input type="number" name='EVA' value={EVA} onChange={onChange} />
                            </div> 
                        </div>
                    </div>
                </div>
                <div className="status">
                    <div className = "status-subcontainer">
                        <div class="status-item" id="statusEffects">
                            <p>STATUS EFFECTS</p>
                            {activeEffects(statusEffects)}
                            <select name="statusEffects" value='Add a status effect' onChange={(e) => addToStatusArray(statusEffects)(e)}>
                                <option disabled selected style={{display:'none'}}>Add a status effect</option>
                                {updateSEDropdown(statusEffects)}
                            </select>
                        </div>
                        <div class="status-item">
                            <p>WEAKNESSES</p>
                            {activeEffects(weaknesses)}
                            <select name="weaknesses" value='Add a weakness' onChange={(e) => addToStatusArray(weaknesses)(e)}>
                                <option disabled selected style={{display:'none'}}>Add a weakness</option>
                                {updateDTDropdown(weaknesses)}
                            </select>
                        </div>
                    </div>
                    <div className = "status-subcontainer">
                        <div class="status-item">
                            <p>RESISTANCES</p>
                            {activeEffects(resistances)}
                            <select value='Add a resistance' onChange={(e) => addToStatusArray(resistances)(e)}>
                                <option disabled selected style={{display:'none'}}>Add a resistance</option>
                                {updateDTDropdown(resistances)}
                            </select>
                        </div>
                        <div class="status-item">
                            <p>IMMUNITIES</p>
                            {activeEffects(immunities)}
                            <select value='Add an immunity' onChange={(e) => addToStatusArray(immunities)(e)}>
                                <option disabled selected style={{display:'none'}}>Add an immunity</option>
                                {updateDTDropdown(immunities)}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="equipment-stims">
                    <div className="equipment">
                        <p className="lead text-blue">EQUIPMENT</p>
                        <p className="equip-type">HELMET</p>
                        <div className="armor-container">
                            <div class="armor-subcontainer">
                                <div class="armor-name medium">{eqHead === undefined ? "" : eqHead.name}</div>
                                <div class="armor-class">{eqHead === undefined ? "" : eqHead.subtype}</div>
                            </div>
                            <div class="armor-subcontainer">
                                <div class="armor-dis">DIS <span>{eqHead === undefined ? "" : eqHead.dis}</span></div>
                                <div class="armor-kin">KIN <span>{eqHead === undefined ? "" : eqHead.kin}</span></div>
                            </div>
                            <div class="mods">
                                <p>MODS</p>
                                <div class="mod-area">
                                    <div class="mod"></div>
                                    <div class="mod"></div>
                                    <div class="mod"></div>
                                </div>
                            </div>
                        </div>
                        <p className="equip-type">BODY</p>
                        <div className="armor-container">
                            <div class="armor-subcontainer">
                                <div class="armor-name medium">{eqBody === undefined ? "" : eqBody.name}</div>
                                <div class="armor-class">{eqBody === undefined ? "" : eqBody.subtype}</div>
                            </div>
                            <div class="armor-subcontainer">
                                <div class="armor-dis">DIS <span>{eqBody === undefined ? "" : eqBody.dis}</span></div>
                                <div class="armor-kin">KIN <span>{eqBody === undefined ? "" : eqBody.kin}</span></div>
                            </div>
                            <div class="mods">
                                <p>MODS</p>
                                <div class="mod-area">
                                    <div class="mod"></div>
                                    <div class="mod"></div>
                                    <div class="mod"></div>
                                </div>
                            </div>
                        </div>
                        <p className="equip-type">BOOTS</p>
                        <div className="armor-container">
                            <div class="armor-subcontainer">
                                <div class="armor-name medium">{eqFeet === undefined ? "" : eqFeet.name}</div>
                                <div class="armor-class">{eqFeet === undefined ? "" : eqFeet.subtype}</div>
                            </div>
                            <div class="armor-subcontainer">
                                <div class="armor-dis">DIS <span>{eqFeet === undefined ? "" : eqFeet.dis}</span></div>
                                <div class="armor-kin">KIN <span>{eqFeet === undefined ? "" : eqFeet.kin}</span></div>
                            </div>
                            <div class="mods">
                                <p>MODS</p>
                                <div class="mod-area">
                                    <div class="mod"></div>
                                    <div class="mod"></div>
                                    <div class="mod"></div>
                                </div>
                            </div>
                        </div>
                        <p className="equip-type">GLOVES</p>
                        <div className="armor-container">
                            <div class="armor-subcontainer">
                                <div class="armor-name medium">{eqHands === undefined ? "" : eqHands.name}</div>
                                <div class="armor-class">{eqHands === undefined ? "" : eqHands.subtype}</div>
                            </div>
                            <div class="armor-subcontainer">
                                <div class="armor-dis">DIS <span>{eqHands === undefined ? "" : eqHands.dis}</span></div>
                                <div class="armor-kin">KIN <span>{eqHands === undefined ? "" : eqHands.kin}</span></div>
                                <div class="armor-cond">DD
                                    <span>
                                        {eqMainHand === undefined ? "" : eqMainHand.numDice + "d"}
                                        {eqMainHand === undefined ? "" : eqMainHand.diceSize}
                                    </span>
                                </div>
                            </div>
                            <div class="mods">
                                <p>MODS</p>
                                <div class="mod-area">
                                    <div class="mod"></div>
                                    <div class="mod"></div>
                                    <div class="mod"></div>
                                </div>
                            </div>
                        </div>
                        {eqMainHand !== undefined &&
                            <p className="equip-type">MAIN HAND</p>
                        }
                        {eqMainHand !== undefined &&
                            <div className="weapon-container">
                                {eqMainHand.type !== ('Blade' || 'Blunt' || 'Gloves') && 
                                    <div>
                                        <button name="attack" className="attack-but" onClick={e => updateGun("Main Hand")(e)}>ATTACK</button><button name="reload" class="reload-but" onClick={e => updateGun("Main Hand")(e)}>RELOAD</button>
                                    </div>
                                }
                                <div className="weapon-subcontainer">
                                    <div class="weapon-name medium">{eqMainHand === undefined ? "" : eqMainHand.name}</div>
                                    <div class="weapon-class">{eqMainHand === undefined ? "" : eqMainHand.subtype}</div>
                                </div>
                                <div className="weapon-subcontainer">
                                    <div class="weapon-dd">DD
                                        <span>
                                            {eqMainHand === undefined ? "" : eqMainHand.numDice + "d"}
                                            {eqMainHand === undefined ? "" : eqMainHand.diceSize}
                                        </span>
                                    </div>
                                    <div class="weapon-range">RNG <span>{eqMainHand === undefined ? "" : eqMainHand.range}</span></div>
                                    <div class="weapon-cond">
                                        MAG 
                                        <span>{eqMainHand === undefined ? "" : eqMainHand.currentAmmo}</span> /
                                        <span>{eqMainHand === undefined ? "" : eqMainHand.magSize}</span>
                                    </div>
                                </div>
                                <div class="mods">
                                    <p>MODS</p>
                                    <div class="mod-area">
                                        <div class="mod"></div>
                                        <div class="mod"></div>
                                        <div class="mod"></div>
                                    </div>
                                </div>
                            </div>
                        }
                        {eqOffHand !== undefined &&
                            <p class="equip-type">OFF HAND</p>
                        } 
                        {eqOffHand !== undefined && eqOffHand.classification === "Weapon" &&
                            <div class="weapon-container">
                                    {eqOffHand.type !== ('Blade' || 'Blunt' || 'Gloves') && 
                                        <div>
                                            <button name="attack" class="attack-but" onClick={e => updateGun("Off Hand")(e)}>ATTACK</button><button name="reload" class="reload-but" onClick={e => updateGun("Off Hand")(e)}>RELOAD</button>
                                        </div>
                                    }<button class="reload-but">RELOAD</button>
                                    <div class="weapon-name medium">{eqOffHand === undefined ? "" : eqOffHand.name}</div>
                                    <div class="weapon-class">{eqOffHand === undefined ? "" : eqOffHand.subtype}</div>
                                    <div class="weapon-dd">DD 
                                        <span>
                                            {eqOffHand === undefined ? "" : eqOffHand.numDice + "d"}
                                            {eqOffHand === undefined ? "" : eqOffHand.diceSize}
                                        </span>
                                    </div>
                                    <div class="weapon-range">RNG <span>{eqOffHand === undefined ? "" : eqOffHand.range}</span></div>
                                    <div class="weapon-cond">
                                        MAG 
                                        <span>{eqOffHand === undefined ? "" : eqOffHand.currentAmmo}</span>/
                                        <span>{eqOffHand === undefined ? "" : eqOffHand.magSize}</span>
                                    </div>
                                    <div class="mods">
                                        <p>MODS</p>
                                        <div class="mod-area">
                                            <div class="mod"></div>
                                            <div class="mod"></div>
                                            <div class="mod"></div>
                                        </div>
                                    </div>
                            </div>
                            //Below this, need to add case where offhand is a shield
                        }
                    </div>
                    <div className="stim-wrapper">
                        <div className="stims">
                            <p className="lead text-blue">STIMS</p>
                            <p className="stim-type">ENERGY</p>
                            <div class="stim-container">
                                <button className="recharge-but" id="energyCharges" onClick={rechargeStim('energyCharges', energyCharges, energyStim)}>RECHARGE</button>
                                <div className="stim-name">
                                    <select name='energyStim' onChange={updateStim}>
                                        <option selected={energyStim.name === 'None' && 'selected'}>None</option>
                                        <option selected={energyStim.name === 'Novice' && 'selected'}>Novice</option>
                                        <option selected={energyStim.name === 'Apprentice' && 'selected'}>Apprentice</option>
                                        <option selected={energyStim.name === 'Master' && 'selected'}>Master</option>
                                    </select>
                                </div>
                                <div class="charges">
                                    {populateCharges('energyCharges', energyStim.totalCharges, energyCharges)}
                                </div>
                            </div>
                            <p class="stim-type">FLUID</p>
                            <div class="stim-container">
                                <button className="recharge-but" id="fluidCharges" onClick={rechargeStim('fluidCharges', fluidCharges, fluidStim)}>RECHARGE</button>
                                <div className="stim-name">
                                    <select name='fluidStim' onChange={updateStim}>
                                        <option selected={fluidStim.name === 'None' && 'selected'}>None</option>
                                        <option selected={fluidStim.name === 'Novice' && 'selected'}>Novice</option>
                                        <option selected={fluidStim.name === 'Apprentice' && 'selected'}>Apprentice</option>
                                        <option selected={fluidStim.name === 'Master' && 'selected'}>Master</option>
                                    </select>
                                </div>
                                <div class="charges">
                                    {populateCharges('fluidCharges', fluidStim.totalCharges, fluidCharges)}
                                </div>
                            </div>
                            <p class="stim-type">GRAV</p>
                            <div class="stim-container">
                                <button className="recharge-but" id="gravCharges" onClick={rechargeStim('gravCharges', gravCharges, gravStim)}>RECHARGE</button>
                                <div className="stim-name">
                                    <select name='gravStim' onChange={updateStim}>
                                        <option selected={gravStim.name === 'None' && 'selected'}>None</option>
                                        <option selected={gravStim.name === 'Novice' && 'selected'}>Novice</option>
                                        <option selected={gravStim.name === 'Apprentice' && 'selected'}>Apprentice</option>
                                        <option selected={gravStim.name === 'Master' && 'selected'}>Master</option>
                                    </select>
                                </div>
                                <div class="charges">
                                    {populateCharges('gravCharges', gravStim.totalCharges, gravCharges)}
                                </div>
                            </div>
                            <p class="stim-type">PSYCHE</p>
                            <div class="stim-container">
                                <button className="recharge-but" id="psycheCharges" onClick={rechargeStim('psycheCharges', psycheCharges, psycheStim)}>RECHARGE</button>
                                <div className="stim-name">
                                    <select name='psycheStim' onChange={updateStim}>
                                        <option selected={psycheStim.name === 'None' && 'selected'}>None</option>
                                        <option selected={psycheStim.name === 'Novice' && 'selected'}>Novice</option>
                                        <option selected={psycheStim.name === 'Apprentice' && 'selected'}>Apprentice</option>
                                        <option selected={psycheStim.name === 'Master' && 'selected'}>Master</option>
                                    </select>
                                </div>
                                <div class="charges">
                                    {populateCharges('psycheCharges', psycheStim.totalCharges, psycheCharges)}
                                </div>
                            </div>
                            <p class="stim-type">WAVE</p>
                            <div class="stim-container">
                                <button className="recharge-but" id="waveCharges" onClick={rechargeStim('waveCharges', waveCharges, waveStim)}>RECHARGE</button>
                                <div className="stim-name">
                                    <select name='waveStim' onChange={updateStim}>
                                        <option selected={waveStim.name === 'None' && 'selected'}>None</option>
                                        <option selected={waveStim.name === 'Novice' && 'selected'}>Novice</option>
                                        <option selected={waveStim.name === 'Apprentice' && 'selected'}>Apprentice</option>
                                        <option selected={waveStim.name === 'Master' && 'selected'}>Master</option>
                                    </select>
                                </div>
                                <div class="charges">
                                    {populateCharges('waveCharges', waveStim.totalCharges, waveCharges)}
                                </div>
                            </div>
                        </div>
                        <div class="status-items">
                            <p class="lead text-blue">ITEMS</p>
                            <div>
                                <div>MAGAZINES<p>{character.inventory.filter(i => i.name === "Gun Magazine").length}</p></div>
                                <div>BATTERIES<p>{character.inventory.filter(i => i.name === "Stim Battery").length}</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

CharEditStatus.propTypes = {
    character: PropTypes.object.isRequired,
    saveCharacter: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    character: state.character
});

export default connect(mapStateToProps, {saveCharacter})(CharEditStatus);
