import { updateCharacter } from '../actions/character';
import { skills } from '../resources/skills';
import store from '../store';

export const attributeModifier = (attribute) => {
    const modifier = Math.floor((attribute - 10) / 2);
    return modifier;
}

export const calculateSkillModifiers = (skillsState, skillLvls, currentAtts, currentExps, armorPenalty) => {

    var newMods = [];

    for (let i = 0; i < 20; i++) {
        var attrMod = attributeModifier(currentAtts[skillsState[i].baseSkill.main_attr]);
        var expMod = 0;
        var perkMod = 0;

        //Handle perk modifier
        switch (i) {
            //Astrobiology
            case 2:
                if (skillLvls[i] >= 10) {
                    perkMod = 1;
                }
                break;
            //Athletics
            case 3:
                var totalPerkMod = 0;
                if (skillLvls[i] >= 15) {
                    totalPerkMod += 1;
                }

                totalPerkMod -= armorPenalty;

                perkMod = totalPerkMod;
                break;
            //Espionage
            case 7:
                var totalPerkMod = 0;
                if (skillLvls[i] >= 5) {
                    totalPerkMod += 1;
                }
                if (skillLvls[0] >= 40 && currentExps[0] === "Light Armor") {
                    totalPerkMod += 2;
                }
                perkMod = totalPerkMod;
                break;
            //Negotiation
            case 12:
                if (skillLvls[i] >= 10) {
                    perkMod = 1;
                }

                if (skillLvls[i] >= 20) {
                    perkMod = 2;
                }
                break;
            //Observation
            case 13:
                if (skillLvls[i] >= 10) {
                    perkMod = 1;
                }

                if (skillLvls >= 40 && currentExps[i] === "Insight") {
                    perkMod = 3;
                }
                break;

            //Pistol
            case 14:
                if (skillLvls[i] >= 35 && currentExps[i] === "Duelist") {
                    perkMod = 2;
                }
                break;
            //Tech
            case 16:
                if (skillLvls[i] >= 15) {
                    perkMod = 1;
                }
                break;
            //Vehicles
            case 18:
                if (skillLvls[i] >= 10) {
                    perkMod = 1;
                }
                break;
            default:
                break;
        }

        //Handle expertise modifier
        if (skillLvls[i] >= 25) {
            if (currentExps[i] === skillsState[i].expertise1.name) {
                expMod = attributeModifier(currentAtts[skillsState[i].expertise1.main_attr]);
            } else {
                expMod = attributeModifier(currentAtts[skillsState[i].expertise2.main_attr]);
            }
        }

        //Final calculation
        if (skillLvls[i] === 0) {
            newMods[i] = -2 + attrMod + perkMod;
        }
        else {
            newMods[i] = 0 + attrMod + perkMod + expMod;
        }
    }

    return newMods;
}

export const calculateSingleSkillMod = (currSkillObj, skillLvl, currentAtts, currentExp, armorPenalty) => {
    var newMod;
    var attrMod = attributeModifier(currentAtts[currSkillObj.baseSkill.main_attr]);
    var expMod = 0;
    var perkMod = 0;

    //Handle perk modifier
    switch (currSkillObj.baseSkill.name) {
        //Astrobiology
        case "Astrobiology":
            if (skillLvl >= 10) {
                perkMod = 1;
            }
            break;
        //Athletics
        case "Athletics":
            var totalPerkMod = 0;
            if (skillLvl >= 15) {
                totalPerkMod += 1;
            }

            totalPerkMod -= armorPenalty;

            perkMod = totalPerkMod;
            break;
        //Espionage
        case "Espionage":
            var totalPerkMod
            if (skillLvl >= 5) {
                perkMod = 1;
            }
            break;
        //Negotiation
        case "Negotiation":
            if (skillLvl >= 20) {
                perkMod = 2;
            } else if (skillLvl >= 10) {
                perkMod = 1;
            }
            break;
        //Observation
        case "Observation":
            if (skillLvl >= 10) {
                perkMod = 1;
            }
            if (skillLvl >= 40 && currentExp === "Insight") {
                perkMod = 3;
            }
            break;
        //Pistol, need expertise brought in first
        case "Pistol":
            if (skillLvl >= 25 && currentExp === "Duelist") {
                perkMod = 2;
            }
            break;
        //Tech
        case "Tech":
            if (skillLvl >= 15) {
                perkMod = 1;
            }
            break;
        case "Vehicles":
            if (skillLvl >= 10) {
                perkMod = 1;
            }
            break;
        default:
            break;
    }

    //Handle expertise modifier
    if (skillLvl >= 25) {
        if (currentExp === currSkillObj.expertise1.name) {
            expMod = attributeModifier(currentAtts[currSkillObj.expertise1.main_attr]);
        } else {
            expMod = attributeModifier(currentAtts[currSkillObj.expertise2.main_attr]);
        }
    }

    if (skillLvl === 0) {
        newMod = -2 + attrMod + perkMod + expMod;
    }
    else {
        newMod = 0 + attrMod + perkMod + expMod;
    }

    return newMod;
}

export const populateActions = (skillsState, skillLvls, currentExps) => {

    //Initialize actions array with default actions
    const actions = [
        {
            name: "Attack",
            desc: "Attack the enemy with either your main hand or off hand weapon, or both if they are the same type.",
            type: "Major"
        },
        {
            name: "Weapon Swap",
            desc: "Switch currently equipped weapons for different weapons in your inventory.",
            type: "Major"
        },
        {
            name: "Reload",
            desc: "Swap out the current magazine for a new magazine on one of your equipped guns. Pistols are always a minor action to reload. Reloading Assault Weapons and Long Range weapons require a major action to reload unless you have the proper perks (Special Ops 35 and Ranger 30).",
            type: "Major"
        },
        {
            name: "Glean Information",
            desc: "Use your skills to glean additional information beyond what the GM already stated. For a scientific query, roll an Astrobiology check. For a social query, roll a Negotiation check. For an investigational query, roll an Observation check. For a technological query, roll a Tech check.",
            type: "Non-Combat"
        },
        {
            name: "Take Full Cover/Leave Full Cover",
            desc: "Hide behind some adjacent obstacle to prevent being attacked. You are unable to attack or be attacked while in full cover unless there is direct line of sight.",
            type: "Minor"
        },
        {
            name: "Use a Battery",
            desc: "Expend a battery, either to recharge a stim or for some other purpose.",
            type: "Minor"
        },
        {
            name: "Sprint",
            desc: "You may move a number of meters up to twice your current movement available. You cannot reload or swap weapons and sprint in the same turn. Ranged attacks are at a disadvantage any turn you sprint unless otherwise stated.",
            type: "Minor"
        },
        {
            name: "Move",
            desc: "You may move up a number of meters equal to your current movement available. This can be broken up between other actions as desired.",
            type: "Movement"
        },
        {
            name: "Get Up",
            desc: "If you are currently prone, you may use 10m of movement to get back up.",
            type: "Movement"
        }
    ];

    for (var i = 0; i < 20; i++) {

        var currentLvl = skillLvls[i];

        //If the skill at the current index is 0, we ignore it
        if (currentLvl > 0) {

            //Create a new array of perks that are under the level requirement
            var basePerks = skillsState[i].baseSkill.perks.filter(perk => perk.lvl_req <= currentLvl);

            //Loop through the new array to see if it has an action attached and add it to the main actions array
            for (var j = 0; j < basePerks.length; j++) {
                if (basePerks[j].action_desc !== undefined) {
                    var newAction = {
                        name: basePerks[j].perk_name,
                        desc: basePerks[j].action_desc,
                        type: basePerks[j].action_type
                    }

                    actions.push(newAction);
                }
            }

            if (currentLvl > 25) {

                var expPerks

                if (skillsState[i].expertise1.name === currentExps[i]) {
                    expPerks = skillsState[i].expertise1.perks.filter(perk => perk.lvl_req <= currentLvl);
                } else {
                    expPerks = skillsState[i].expertise2.perks.filter(perk => perk.lvl_req <= currentLvl);
                }

                for (var x = 0; x < expPerks.length; x++) {
                    if (expPerks[x].action_desc !== undefined) {
                        var newAction = {
                            name: expPerks[x].perk_name,
                            desc: expPerks[x].action_desc,
                            type: expPerks[x].action_type
                        }

                        actions.push(newAction);
                    }
                }
            }
        }
    }

    return actions;
}

export const statChanges = (charObj) => {

    var updatedChar = charObj;

    // Char Equipped Items
    var armorPenalty = 0;

    if (updatedChar.inventory != undefined) {

        //Handle armor
        var charHead = updatedChar.inventory.find(x => x.equipped === "Head");
        var charChest = updatedChar.inventory.find(x => x.equipped === "Body");
        var charBoots = updatedChar.inventory.find(x => x.equipped === "Feet");
        var charGloves = updatedChar.inventory.find(x => x.equipped === "Hands");
        var charMainHand = updatedChar.inventory.find(x => x.equipped === "Main Hand");
        var charOffHand = updatedChar.inventory.find(x => x.equipped === "Off Hand");

        updatedChar.currentCapacity = 0;

        for (var i = 0; i < updatedChar.inventory.length; i++) {
            updatedChar.currentCapacity = updatedChar.currentCapacity + parseFloat(updatedChar.inventory[i].weight.$numberDecimal);
        }

        if (updatedChar.armorLevel < 30 || updatedChar.armorExpertise === 'Light Armor') {
            armorPenalty = updatedChar.inventory.filter(x => x.subtype === "Heavy Armor" && (x.equipped === "Body" || x.equipped === "Feet")).length;
        }
    }

    //Calculate skill mods here!
    const levelArray = [
        updatedChar.armorLevel,
        updatedChar.assaultWeaponLevel,
        updatedChar.astrobiologyLevel,
        updatedChar.athleticsLevel,
        updatedChar.bladeLevel,
        updatedChar.bluntLevel,
        updatedChar.energyLevel,
        updatedChar.espionageLevel,
        updatedChar.explosivesLevel,
        updatedChar.fluidLevel,
        updatedChar.gravLevel,
        updatedChar.longRangeLevel,
        updatedChar.negotiationLevel,
        updatedChar.observationLevel,
        updatedChar.pistolLevel,
        updatedChar.psycheLevel,
        updatedChar.techLevel,
        updatedChar.unarmedLevel,
        updatedChar.vehiclesLevel,
        updatedChar.waveLevel
    ];

    const expertiseArray = [
        updatedChar.armorExpertise,
        updatedChar.assaultWeaponExpertise,
        updatedChar.astrobiologyExpertise,
        updatedChar.athleticsExpertise,
        updatedChar.bladeExpertise,
        updatedChar.bluntExpertise,
        updatedChar.energyExpertise,
        updatedChar.espionageExpertise,
        updatedChar.explosivesExpertise,
        updatedChar.fluidExpertise,
        updatedChar.gravExpertise,
        updatedChar.longRangeExpertise,
        updatedChar.negotiationExpertise,
        updatedChar.observationExpertise,
        updatedChar.pistolExpertise,
        updatedChar.psycheExpertise,
        updatedChar.techExpertise,
        updatedChar.unarmedExpertise,
        updatedChar.vehiclesExpertise,
        updatedChar.waveExpertise
    ];

    var newSkillModArray = calculateSkillModifiers(
        skills,
        levelArray,
        { "STR": updatedChar.STR, "DEX": updatedChar.DEX, "CON": updatedChar.CON, "INT": updatedChar.INT, "WIS": updatedChar.WIS, "CHA": updatedChar.CHA },
        expertiseArray,
        armorPenalty
    );

    updatedChar.armorMod = newSkillModArray[0];
    updatedChar.assaultWeaponMod = newSkillModArray[1];
    updatedChar.astrobiologyMod = newSkillModArray[2];
    updatedChar.athleticsMod = newSkillModArray[3];
    updatedChar.bladeMod = newSkillModArray[4];
    updatedChar.bluntMod = newSkillModArray[5];
    updatedChar.energyMod = newSkillModArray[6];
    updatedChar.espionageMod = newSkillModArray[7];
    updatedChar.explosivesMod = newSkillModArray[8];
    updatedChar.fluidMod = newSkillModArray[9];
    updatedChar.gravityMod = newSkillModArray[10];
    updatedChar.longRangeMod = newSkillModArray[11];
    updatedChar.negotiationMod = newSkillModArray[12];
    updatedChar.observationMod = newSkillModArray[13];
    updatedChar.pistolMod = newSkillModArray[14];
    updatedChar.psycheMod = newSkillModArray[15];
    updatedChar.techMod = newSkillModArray[16];
    updatedChar.unarmedMod = newSkillModArray[17];
    updatedChar.vehiclesMod = newSkillModArray[18];
    updatedChar.waveMod = newSkillModArray[19];

    // -- Stat reset -- //

    updatedChar.EVA = 8;
    updatedChar.maxCapacity = 30;
    updatedChar.movement = 10;

    //DIS and KIN
    updatedChar.baseDIS = 0;
    updatedChar.baseKIN = 0;

    // TARG
    switch (updatedChar.race) {
        case 'Aerzha':
            updatedChar.TARG = 13;
            break;
        case 'Muhmin':
        case 'Kozaid':
            updatedChar.TARG = 11;
            break;
        default:
            updatedChar.TARG = 12;
            break;
    }

    // Store current character skill levels as variables
    var armor = updatedChar.armorLevel;
    var assaultWeap = updatedChar.assaultWeaponLevel;
    var astrobiology = updatedChar.astrobiologyLevel;
    var athletics = updatedChar.athleticsLevel;
    var blade = updatedChar.bladeLevel;
    var blunt = updatedChar.bluntLevel;
    var energy = updatedChar.energyLevel;
    var espionage = updatedChar.espionageLevel;
    var explosives = updatedChar.explosivesLevel;
    var fluid = updatedChar.fluidLevel;
    var gravity = updatedChar.gravityLevel;
    var longRange = updatedChar.longRangeLevel;
    var negotiation = updatedChar.negotiationLevel;
    var observation = updatedChar.observationLevel;
    var pistol = updatedChar.pistolLevel;
    var psyche = updatedChar.psycheLevel;
    var tech = updatedChar.techLevel;
    var unarmed = updatedChar.unarmedLevel;
    var vehicles = updatedChar.vehiclesLevel;
    var wave = updatedChar.waveLevel;

    // Update DIS, KIN, and movement based on current armor
    if (charHead != undefined) {
        updatedChar.baseDIS += charHead.dis;
        updatedChar.baseKIN += charHead.kin;
    }

    if (charChest != undefined) {
        updatedChar.baseDIS += charChest.dis;
        updatedChar.baseKIN += charChest.kin;
        if (charChest.subtype === "Heavy Armor") {
            if (updatedChar.armor < 30 || updatedChar.armorExpertise === 'Light Armor') {
                updatedChar.movement -= 1;
            }

        }
    }

    if (charBoots != undefined) {
        updatedChar.baseDIS += charBoots.dis;
        updatedChar.baseKIN += charBoots.kin;
        if (charBoots.subtype === "Heavy Armor") {
            if (updatedChar.armor < 30 || updatedChar.armorExpertise === 'Light Armor') {
                updatedChar.movement -= 1;
            }
        }
    }

    if (charGloves != undefined) {
        updatedChar.baseDIS += charGloves.dis;
        updatedChar.baseKIN += charGloves.kin;
    }

    // Armor
    if (armor >= 5) {

        // Geared Up
        if (charHead && charChest && charBoots && charGloves) {
            updatedChar.baseDIS += 3;
            updatedChar.baseKIN += 3;
        }

        // Endurance Training
        if (armor >= 10) {
            updatedChar.maxCapacity += 10;
        }

        // Reflective Barrier
        if (armor >= 15) {
            updatedChar.TARG += 1;
        }

        //Light Armor expertise
        if (updatedChar.armorExpertise === "Light Armor") {
            //Slim and Sleek
            if (armor >= 30) {
                updatedChar.TARG += 1;
                updatedChar.EVA += 2;
            }

            //Lightweight Materials
            if (armor >= 35) {
                if (charHead !== undefined && charHead.subtype === "Light Armor") { updatedChar.currentCapacity -= parseFloat(charHead.weight.$numberDecimal); }
                if (charChest !== undefined && charChest.subtype === "Light Armor") { updatedChar.currentCapacity -= parseFloat(charChest.weight.$numberDecimal); }
                if (charBoots !== undefined && charBoots.subtype === "Light Armor") { updatedChar.currentCapacity -= parseFloat(charBoots.weight.$numberDecimal); }
                if (charGloves !== undefined && charGloves.subtype === "Light Armor") { updatedChar.currentCapacity -= parseFloat(charGloves.weight.$numberDecimal); }
            }

            // Powered Joints
            if (armor > 44) {
                updatedChar.movement += 2;
            }
        }

        if (updatedChar.armorExpertise === "Heavy Armor") {
            if (armor > 34) {
                updatedChar.maxCapacity += 20;
            }

            if (armor > 39) {
                if (charHead !== undefined && charHead.subtype === "Heavy Armor") {
                    updatedChar.baseDIS += 2;
                    updatedChar.baseKIN += 2;
                }
                if (charChest !== undefined && charChest.subtype === "Heavy Armor") {
                    updatedChar.baseDIS += 2;
                    updatedChar.baseKIN += 2;
                }
                if (charBoots !== undefined && charBoots.subtype === "Heavy Armor") {
                    updatedChar.baseDIS += 2;
                    updatedChar.baseKIN += 2;
                }
                if (charGloves !== undefined && charGloves.subtype === "Heavy Armor") {
                    updatedChar.baseDIS += 2;
                    updatedChar.baseKIN += 2;
                }
            }
        }
    }

    //Athletics
    if (athletics >= 15) {
        updatedChar.EVA += 2;

        if (athletics >= 20) {
            updatedChar.movement += 3;
        }

        //Sprinter
        if (updatedChar.athleticsExpertise === "Sprinter") {
            if (athletics >= 35) {
                updatedChar.movement += 3;
            }
            if (athletics >= 40) {
                updatedChar.EVA += Math.ceil(updatedChar.athleticsMod / 2);
            }
        }
    }

    //Blade - Fencer
    if (updatedChar.bladeExpertise === "Fencer" && blade >= 30) {
        if (charMainHand !== undefined) {
            if (charMainHand.type === "Blade" && charOffHand === undefined) {
                updatedChar.EVA += 3;
            }
        }
        if (charOffHand !== undefined) {
            if (charMainHand === undefined && charOffHand.type === "Blade") {
                updatedChar.EVA += 3;
            }
        }
        if (charMainHand !== undefined && charOffHand !== undefined) {
            if (charMainHand.type === "Blade" && charOffHand.type != "Blade" || charMainHand.type != "Blade" && charOffHand.type === "Blade") {
                updatedChar.EVA += 3;
            }
        }
    }

    //Blade - Dual-Wielder
    if (updatedChar.bladeExpertise === "Dual-Wielder" && blade >= 30) {
        if (charMainHand !== undefined && charOffHand !== undefined && charMainHand.type === "Blade" && charOffHand.type === "Blade") {
            updatedChar.EVA += 4;
        }
    }

    //Blunt - Warder
    if (charOffHand !== undefined && charOffHand.type === "Shield") {
        //Blunt 35 - Warder
        if (updatedChar.bluntExpertise === "Warder" && blunt >= 35) {

            updatedChar.baseDIS += 5;
            updatedChar.baseKIN += 5;

            //Blunt 45 - Warder
            if (updatedChar.bluntExpertise === "Warder" && blunt >= 45) {
                updatedChar.EVA += 4;
            }
        }
    }


    //Explosives 20
    if (explosives >= 15) {
        if (updatedChar.resistances.filter(x => x === "Explosive").length === 0) {
            updatedChar.resistances.push("Explosive");
        }
    }

    //Unarmed 30 - Martial Artist
    if (updatedChar.unarmedExpertise === "Martial Artist") {
        if (charMainHand === undefined && charOffHand === undefined) {
            if (unarmed >= 30) {
                updatedChar.EVA += 2;
            }
            if (unarmed >= 45) {
                updatedChar.EVA += updatedChar.unarmedMod;
            }
        }
    }

    //Unarmed 45 - Brawler
    if (updatedChar.unarmedExpertise === "Brawler" && unarmed >= 45) {
        if (charGloves !== undefined) {
            if (charGloves.subtype === "Power Gauntlets") {
                updatedChar.EVA += 3;
            }
        }
    }

    updatedChar.initiative = updatedChar.athleticsMod + updatedChar.observationMod;

    

    //All weapon dice changes!!!!
    var AWArray = updatedChar.inventory.filter(x => x.type === "Assault Weapon");
    var BladeArray = updatedChar.inventory.filter(x => x.type === "Blade");
    var BluntArray = updatedChar.inventory.filter(x => x.type === "Blunt");
    var LRArray = updatedChar.inventory.filter(x => x.type === "Long Range");
    var pistolArray = updatedChar.inventory.filter(x => x.type === "Pistol");
    //var GloveArray = updatedChar.inventory.filter(x => x.type === "Glove");
    
    var items = store.getState().item.items;

    //Assault Weapon buffs
    for(var i=0;i<AWArray.length;i++){

        var AWInd = updatedChar.inventory.findIndex(x => x === AWArray[i]);
        var originalItem = items.find(x => x.name === AWArray[i].name);

        updatedChar.inventory[AWInd].critRequirement = 20;
        
        if(updatedChar.assaultWeaponLevel >= 10){
        
            updatedChar.inventory[AWInd].diceSize = 6;

            if(updatedChar.assaultWeaponLevel >= 15){
                
                var magDiff = 0;

                if(originalItem.magSize === updatedChar.inventory[AWInd].magSize){
                    magDiff = originalItem.magSize - updatedChar.inventory[AWInd].currentAmmo;
                } else {
                    magDiff = updatedChar.inventory[AWInd].magSize - updatedChar.inventory[AWInd].currentAmmo
                }

                if(updatedChar.assaultWeaponExpertise === "Bullet Hell" && updatedChar.assaultWeaponLevel >= 45){
                    updatedChar.inventory[AWInd].magSize = originalItem.magSize + 10;
                } else {
                    updatedChar.inventory[AWInd].magSize = originalItem.magSize + 2;
                }
                
                updatedChar.inventory[AWInd].currentAmmo = updatedChar.inventory[AWInd].magSize - magDiff;

                //Increase range for High-Power Pulses perk
                if(updatedChar.assaultWeaponExpertise === "Special Ops" && updatedChar.assaultWeaponLevel >= 45){
                    updatedChar.inventory[AWInd].range = originalItem.range + 10;
                } else {
                    updatedChar.inventory[AWInd].range = originalItem.range;
                }
            }

            if(updatedChar.assaultWeaponLevel >= 20){
                updatedChar.inventory[AWInd].diceSize = 8;
            }
        } else {
            updatedChar.inventory[AWInd].diceSize = 4;
            updatedChar.inventory[AWInd].magSize = originalItem.magSize;
            updatedChar.inventory[AWInd].range = originalItem.range;
        }
    }

    //Blade Buffs
    for(var i=0;i<BladeArray.length;i++){
        var BladeInd = updatedChar.inventory.findIndex(x => x === BladeArray[i]);
        var originalItem = items.find(x => x.name === BladeArray[i].name);

        updatedChar.inventory[BladeInd].critRequirement = 20;

        if(updatedChar.bladeLevel >= 10){
            
            updatedChar.inventory[BladeInd].diceSize = 6;

            if(updatedChar.bladeLevel >= 20){
                updatedChar.inventory[BladeInd].diceSize = 8;
            }

            if(updatedChar.bladeExpertise === "Fencer" && updatedChar.bladeLevel >= 35){
                updatedChar.inventory[BladeInd].critRequirement = 18;
            }
        } else {
            updatedChar.inventory[BladeInd].diceSize = originalItem.diceSize;
        }
    }

    //Blunt Buffs
    for(var i=0;i<BluntArray.length;i++){
        var BluntInd = updatedChar.inventory.findIndex(x => x === BluntArray[i]);
        var originalItem = items.find(x => x.name === BluntArray[i].name);

        updatedChar.inventory[BluntInd].critRequirement = 20;

        if(updatedChar.bluntLevel >= 10){
            
            updatedChar.inventory[BluntInd].diceSize = 6;

            if(updatedChar.bluntLevel >= 20){
                updatedChar.inventory[BluntInd].diceSize = 8;
            }
        } else {
            updatedChar.inventory[BluntInd].diceSize = originalItem.diceSize;
        }
    }

    //Long Range Buffs
    for(var i=0;i<LRArray.length;i++){
        var LRInd = updatedChar.inventory.findIndex(x => x === LRArray[i]);
        var originalItem = items.find(x => x.name === LRArray[i].name);

        updatedChar.inventory[LRInd].critRequirement = 20;

        if(updatedChar.longRangeLevel >= 10){
            
            updatedChar.inventory[LRInd].diceSize = 6;

            if(updatedChar.longRangeLevel >= 15){
                updatedChar.inventory[LRInd].critRequirement = 18;
            }

            if(updatedChar.longRangeLevel >= 20){
                updatedChar.inventory[LRInd].diceSize = 8;
            }

            if(updatedChar.longRangeExpertise === "Sniper" && updatedChar.longRangeLevel >= 30){
                updatedChar.inventory[LRInd].critRequirement -=3;

                if(updatedChar.longRangeLevel >= 40 && updatedChar.inventory[LRInd].subtype === "Tripod Rifle"){
                    updatedChar.inventory[LRInd].critRequirement -=3;
                }

                if(updatedChar.longRangeLevel === 50){
                    updatedChar.inventory[LRInd].critRequirement -=2;
                }
            }

            if(updatedChar.longRangeExpertise === "Sniper" && updatedChar.longRangeLevel >= 45){
                updatedChar.inventory[LRInd].critRequirement -= 3;
            }
        } else {
            updatedChar.inventory[LRInd].diceSize = originalItem.diceSize;
        }
    }

    //Pistol Buffs
    for(var i=0;i<pistolArray.length;i++){
        var pistolInd = updatedChar.inventory.findIndex(x => x === pistolArray[i]);
        var originalItem = items.find(x => x.name === pistolArray[i].name);

        updatedChar.inventory[pistolInd].critRequirement = 20;

        if(updatedChar.pistolLevel >= 10){
            
            updatedChar.inventory[pistolInd].diceSize = 6;

            if(updatedChar.pistolLevel >= 20){
                updatedChar.inventory[pistolInd].diceSize = 8;
            }

            if(updatedChar.pistolExpertise === "Duelist" && updatedChar.pistolLevel === 50){
                updatedChar.inventory[pistolInd].critRequirement = 18;
            }
        } else {
            updatedChar.inventory[pistolInd].diceSize = originalItem.diceSize;
        }
    }

    return updatedChar;
}

export const generateAttackObject = (attackType, attackSource, character) => {


    
    var attackArray = [];

    //Default is bare hands/pilot gloves
    var attackInfo = {
        weaponType: "Unarmed",
        source: "Main Hand",
        skillLevel: character.unarmedLevel,
        skillMod: character.unarmedMod,
        skillExpertise: character.unarmedExpertise,
        numDice: 1,
        diceSize: 4,
        penalty: 0
    };

    var charMainHand;
    var charOffHand;
    var charGloves;
    
    //Get current equipped weapons and use them to set attackInfo local state
    charMainHand = character.inventory.find(x => x.equipped === "Main Hand");
    charOffHand = character.inventory.find(x => x.equipped === "Off Hand");
    charGloves = character.inventory.find(x => x.equipped === "Hands");

    //If no gloves are equipped
    if( charGloves === undefined){
        charGloves = {
            name: "Bare Hands",
            type: "Bare Hands",
            diceSize: 4,
            numDice: 1,
            critRequirement: 20
        }
    }

    if(charOffHand === undefined){
        charOffHand = charGloves;
    }

    if(charMainHand === undefined){
        charMainHand = charGloves;
    }

    var offHandPenalty = -4;

    

    if(attackType === "Melee"){

        if(attackSource === "Main Hand"){
            //Melee attack with main hand blade
            if(charMainHand.type === "Blade"){
                var attack1 = {
                    weaponType: "Blade",
                    source: "Main Hand",
                    skillLevel: character.bladeLevel,
                    skillMod: character.bladeMod,
                    skillExpertise: character.bladeExpertise,
                    numDice: charMainHand.numDice,
                    diceSize: charMainHand.diceSize,
                    critRequirement: charMainHand.critRequirement
                };

                attackArray.push(attack1);

                //If there's a blade in the offhand as well
                if(charOffHand.type === "Blade"){
                    if(character.bladeLevel >= 35 && character.bladeExpertise === "Dual-Wielder"){
                        offHandPenalty = 0;
                    }

                    var attack2 = {
                        weaponType: "Blade",
                        source: "Off Hand",
                        skillLevel: character.bladeLevel,
                        skillMod: character.bladeMod,
                        skillExpertise: character.bladeExpertise,
                        numDice: charOffHand.numDice,
                        diceSize: charOffHand.diceSize,
                        critRequirement: charOffHand.critRequirement,
                        offHandPenalty: offHandPenalty
                    };

                    attackArray.push(attack2);
                }

                //Swift Strikes perk
                if(character.bladeLevel >= 30 && character.bladeExpertise === "Dual-Wielder"){
                    var attack3 = {
                        weaponType: "Blade",
                        source: "Main Hand",
                        skillLevel: character.bladeLevel,
                        skillMod: character.bladeMod,
                        skillExpertise: character.bladeExpertise,
                        numDice: charMainHand.numDice,
                        diceSize: charMainHand.diceSize,
                        critRequirement: charMainHand.critRequirement
                    };

                    attackArray.push(attack3);
                    
                }

                //Whirl of Blades perk
                if(character.bladeLevel >= 50 && character.bladeExpertise === "Dual-Wielder" && charOffHand.type === "Blade"){
                    var attack4 = {
                        weaponType: "Blade",
                        source: "Off Hand",
                        skillLevel: character.bladeLevel,
                        skillMod: character.bladeMod,
                        skillExpertise: character.bladeExpertise,
                        numDice: charOffHand.numDice,
                        diceSize: charOffHand.diceSize,
                        critRequirement: charOffHand.critRequirement,
                        offHandPenalty: 0
                    };

                    attackArray.push(attack4);
                }
            }

            //Melee attack with main hand blunt
            if(charMainHand.type === "Blunt") {

                var attack1 = {
                    weaponType: "Blunt",
                    skillLevel: character.bluntLevel,
                    skillMod: character.bluntMod,
                    skillExpertise: character.bluntExpertise,
                    numDice: charMainHand.numDice,
                    diceSize: charMainHand.diceSize,
                    critRequirement: charMainHand.critRequirement
                };

                attackArray.push(attack1);

                // Melee attack with blunt also in off hand
                if(charOffHand.type === "Blunt"){
                    if(character.bladeLevel >= 35 && character.bladeExpertise === "Dual-Wielder"){
                        offHandPenalty = 0;
                    }

                    var attack2 = {
                        weaponType: "Blunt",
                        source: "Off Hand",
                        skillLevel: character.bluntLevel,
                        skillMod: character.bluntMod,
                        skillExpertise: character.bluntExpertise,
                        numDice: charOffHand.numDice,
                        diceSize: charOffHand.diceSize,
                        critRequirement: charOffHand.critRequirement,
                        offHandPenalty: offHandPenalty
                    };

                    attackArray.push(attack2);
                }
            }
        }
        
        if(attackSource === "Off Hand"){
            //Melee attack with off-hand blade
            if(charOffHand.type === "Blade"){
                if(character.bladeLevel >= 35 && character.bladeExpertise === "Dual-Wielder"){
                    offHandPenalty = 0;
                }

                var attack1 = {
                    weaponType: "Blade",
                    source: "Off Hand",
                    skillLevel: character.bladeLevel,
                    skillMod: character.bladeMod,
                    skillExpertise: character.bladeExpertise,
                    numDice: charOffHand.numDice,
                    diceSize: charOffHand.diceSize,
                    critRequirement: charOffHand.critRequirement,
                    offHandPenalty: offHandPenalty
                };

                attackArray.push(attack1);

                //Whirl of Blades perk
                if(character.bladeLevel >= 50 && character.bladeExpertise === "Dual-Wielder" && charOffHand.type === "Blade"){
                    var attack2 = {
                        weaponType: "Blade",
                        source: "Off Hand",
                        skillLevel: character.bladeLevel,
                        skillMod: character.bladeMod,
                        skillExpertise: character.bladeExpertise,
                        numDice: charOffHand.numDice,
                        diceSize: charOffHand.diceSize,
                        critRequirement: charOffHand.critRequirement,
                        offHandPenalty: 0
                    };

                    attackArray.push(attack2);
                }
            }

            // Melee attack with blunt also in off hand
            if(charOffHand.type === "Blunt"){
                if(character.bladeLevel >= 35 && character.bladeExpertise === "Dual-Wielder"){
                    offHandPenalty = 0;
                }

                var attack1 = {
                    weaponType: "Blunt",
                    source: "Off Hand",
                    skillLevel: character.bluntLevel,
                    skillMod: character.bluntMod,
                    skillExpertise: character.bluntExpertise,
                    numDice: charOffHand.numDice,
                    diceSize: charOffHand.diceSize,
                    critRequirement: charOffHand.critRequirement,
                    offHandPenalty: offHandPenalty
                };

                attackArray.push(attack1);
            }
        }
    } 
    
    if (attackType === "Ranged"){

        if(attackSource === "Main Hand"){

            //Ranged attack with Assault Weapon (always main hand)
            if(charMainHand.type === "Assault Weapon"){
                var attack1 = {
                    weaponType: "Assault Weapon",
                    skillLevel: character.assaultWeaponLevel,
                    skillMod: character.assaultWeaponMod,
                    skillExpertise: character.assaultWeaponExpertise,
                    numDice: charMainHand.numDice,
                    diceSize: charMainHand.diceSize,
                    critRequirement: charMainHand.critRequirement
                };

                attackArray.push(attack1);
                
                
                //Itchy Trigger Finger perk
                if(character.assaultWeaponLevel >= 5){
                    var attack2 = {
                        weaponType: "Assault Weapon",
                        skillLevel: character.assaultWeaponLevel,
                        skillMod: character.assaultWeaponMod,
                        skillExpertise: character.assaultWeaponExpertise,
                        numDice: charMainHand.numDice,
                        diceSize: charMainHand.diceSize,
                        critRequirement: charMainHand.critRequirement
                    };
    
                    attackArray.push(attack2);
                }

                if(character.assaultWeaponExpertise === "Bullet Hell"){
                    if(character.assaultWeaponLevel >= 30){
                        var attack3 = {
                            weaponType: "Assault Weapon",
                            skillLevel: character.assaultWeaponLevel,
                            skillMod: character.assaultWeaponMod,
                            skillExpertise: character.assaultWeaponExpertise,
                            numDice: charMainHand.numDice,
                            diceSize: charMainHand.diceSize,
                            critRequirement: charMainHand.critRequirement
                        };
        
                        attackArray.push(attack3);

                        var attack4 = {
                            weaponType: "Assault Weapon",
                            skillLevel: character.assaultWeaponLevel,
                            skillMod: character.assaultWeaponMod,
                            skillExpertise: character.assaultWeaponExpertise,
                            numDice: charMainHand.numDice,
                            diceSize: charMainHand.diceSize,
                            critRequirement: charMainHand.critRequirement
                        };
        
                        attackArray.push(attack4);
                    }

                    if(character.assaultWeaponLevel >= 50){
                        var attack5 = {
                            weaponType: "Assault Weapon",
                            skillLevel: character.assaultWeaponLevel,
                            skillMod: character.assaultWeaponMod,
                            skillExpertise: character.assaultWeaponExpertise,
                            numDice: charMainHand.numDice,
                            diceSize: charMainHand.diceSize,
                            critRequirement: charMainHand.critRequirement
                        };
        
                        attackArray.push(attack5);

                        var attack6 = {
                            weaponType: "Assault Weapon",
                            skillLevel: character.assaultWeaponLevel,
                            skillMod: character.assaultWeaponMod,
                            skillExpertise: character.assaultWeaponExpertise,
                            numDice: charMainHand.numDice,
                            diceSize: charMainHand.diceSize,
                            critRequirement: charMainHand.critRequirement
                        };
        
                        attackArray.push(attack6);
                    }
                }

                if(character.assaultWeaponExpertise === "Special Ops"){
                    if(character.assaultWeaponLevel >= 35){
                        var attack3 = {
                            weaponType: "Assault Weapon",
                            skillLevel: character.assaultWeaponLevel,
                            skillMod: character.assaultWeaponMod,
                            skillExpertise: character.assaultWeaponExpertise,
                            numDice: charMainHand.numDice,
                            diceSize: charMainHand.diceSize,
                            critRequirement: charMainHand.critRequirement
                        };
        
                        attackArray.push(attack3);
                    }
                }
            }

            //Ranged attack with Long Range (always main hand)
            if (charMainHand.type === "Long Range"){
                var attack1 = {
                    weaponType: "Long Range",
                    skillLevel: character.longRangeLevel,
                    skillMod: character.longRangeMod,
                    skillExpertise: character.longRangeExpertise,
                    numDice: charMainHand.numDice,
                    diceSize: charMainHand.diceSize,
                    critRequirement: charMainHand.critRequirement
                };

                attackArray.push(attack1);

                if(character.longRangeLevel >= 40 && character.longRangeExpertise === "Ranger"){
                    var attack2 = {
                        weaponType: "Long Range",
                        skillLevel: character.longRangeLevel,
                        skillMod: character.longRangeMod,
                        skillExpertise: character.longRangeExpertise,
                        numDice: charMainHand.numDice,
                        diceSize: charMainHand.diceSize,
                        critRequirement: charMainHand.critRequirement
                    };
    
                    attackArray.push(attack2);
                }
            }

            //Ranged attack with main hand Pistol 
            if (charMainHand.type === "Pistol"){

                attack1 = {
                    weaponType: "Pistol",
                    skillLevel: character.pistolLevel,
                    skillMod: character.pistolMod,
                    skillExpertise: character.pistolExpertise,
                    numDice: charMainHand.numDice,
                    diceSize: charMainHand.diceSize,
                    critRequirement: charMainHand.critRequirement
                };

                attackArray.push(attack1);

                if (charOffHand.type === "Pistol"){

                    if(character.pistolLevel >= 30 && character.pistolExpertise === "Gunslinger"){
                        offHandPenalty = 0;
                    }
        
                    attack2 = {
                        weaponType: "Pistol",
                        skillLevel: character.pistolLevel,
                        skillMod: character.pistolMod,
                        skillExpertise: character.pistolExpertise,
                        numDice: charOffHand.numDice,
                        diceSize: charOffHand.diceSize,
                        critRequirement: charOffHand.critRequirement,
                        offHandPenalty: offHandPenalty
                    };
    
                    attackArray.push(attack2);
                }

                if(character.pistolLevel >= 15){
                    attack3 = {
                        weaponType: "Pistol",
                        skillLevel: character.pistolLevel,
                        skillMod: character.pistolMod,
                        skillExpertise: character.pistolExpertise,
                        numDice: charMainHand.numDice,
                        diceSize: charMainHand.diceSize,
                        critRequirement: charMainHand.critRequirement
                    };
    
                    attackArray.push(attack3);
                }

                if (charOffHand.type === "Pistol" && character.pistolLevel >= 45 && character.pistolExpertise === "Gunslinger"){
                    
                    offHandPenalty = 0;

                    attack4 = {
                        weaponType: "Pistol",
                        skillLevel: character.pistolLevel,
                        skillMod: character.pistolMod,
                        skillExpertise: character.pistolExpertise,
                        numDice: charOffHand.numDice,
                        diceSize: charOffHand.diceSize,
                        critRequirement: charOffHand.critRequirement,
                        offHandPenalty: offHandPenalty
                    };
    
                    attackArray.push(attack4);
                }
            }
        }

        if(attackSource === "Off Hand"){
            if(character.pistolLevel >= 30 && character.pistolExpertise === "Gunslinger"){
                offHandPenalty = 0;
            }

            attack2 = {
                weaponType: "Pistol",
                skillLevel: character.pistolLevel,
                skillMod: character.pistolMod,
                skillExpertise: character.pistolExpertise,
                numDice: charOffHand.numDice,
                diceSize: charOffHand.diceSize,
                critRequirement: charOffHand.critRequirement,
                offHandPenalty: offHandPenalty
            };

            attackArray.push(attack2);

            if (charOffHand.type === "Pistol" && character.pistolLevel >= 45 && character.pistolExpertise === "Gunslinger"){
                
                offHandPenalty = 0;

                attack4 = {
                    weaponType: "Pistol",
                    skillLevel: character.pistolLevel,
                    skillMod: character.pistolMod,
                    skillExpertise: character.pistolExpertise,
                    numDice: charOffHand.numDice,
                    diceSize: charOffHand.diceSize,
                    critRequirement: charOffHand.critRequirement,
                    offHandPenalty: offHandPenalty
                };

                attackArray.push(attack4);
            }
        } 
    }

    if( attackType === "Unarmed"){
        
        if(attackSource === "Main Hand"){

            attack1 = {
                weaponType: "Unarmed",
                skillLevel: character.unarmedLevel,
                skillMod: character.unarmedMod,
                skillExpertise: character.unarmedExpertise,
                numDice: charMainHand.numDice,
                diceSize: charMainHand.diceSize,
                critRequirement: charMainHand.critRequirement
            };

            attackArray.push(attack1);

            if (charOffHand.type === "Gloves"){

                if(character.unarmedLevel >= 15){
                    offHandPenalty = 0;
                }
    
                attack2 = {
                    weaponType: "Unarmed",
                    skillLevel: character.unarmedLevel,
                    skillMod: character.unarmedMod,
                    skillExpertise: character.unarmedExpertise,
                    numDice: charOffHand.numDice,
                    diceSize: charOffHand.diceSize,
                    critRequirement: charOffHand.critRequirement,
                    offHandPenalty: offHandPenalty
                };

                attackArray.push(attack2);
            }

            if(character.unarmedLevel >= 30 && character.unarmedExpertise === "Brawler"){
                attack3 = {
                    weaponType: "Unarmed",
                    skillLevel: character.unarmedLevel,
                    skillMod: character.unarmedMod,
                    skillExpertise: character.unarmedExpertise,
                    numDice: charMainHand.numDice,
                    diceSize: charMainHand.diceSize,
                    critRequirement: charMainHand.critRequirement
                };

                attackArray.push(attack3);
            }
        }

        if(attackSource === "Off Hand"){

            

            if(character.unarmedLevel >= 15){
                offHandPenalty = 0;
            }

            attack1 = {
                weaponType: "Unarmed",
                skillLevel: character.unarmedLevel,
                skillMod: character.unarmedMod,
                skillExpertise: character.unarmedExpertise,
                numDice: charOffHand.numDice,
                diceSize: charOffHand.diceSize,
                critRequirement: charOffHand.critRequirement,
                offHandPenalty: offHandPenalty
            };

            attackArray.push(attack1);
        }
    }

    return attackArray;
}

export const attackSituations = (attackInfo, character) => {

    var situations = [];

    // Manual advantage
    var advantageSitchObj = {
        name: "Advantage",
        active: false
    }
    situations.push(advantageSitchObj);

    // Manual disadvantage
    var disadvantageSitchObj = {
        name: "Disadvantage",
        active: false
    }
    situations.push(disadvantageSitchObj);

    // Sneak attack (no perks yet)
    var sneakSitchObj = {
        name: "Sneak Attack",
        active: false
    }
    situations.push(sneakSitchObj);

    var dimlightSitchObj = {
        name: "Dim Lighting",
        active: false
    }
    situations.push(dimlightSitchObj);

    var darknessSitchObj = {
        name: "Darkness",
        active: false
    }
    situations.push(darknessSitchObj);

    // Situations for ranged attacks
    if(attackInfo[0].weaponType === "Assault Weapon" || attackInfo[0].weaponType === "Long Range" || attackInfo[0].weaponType === "Pistol"){
        
        // Leaving Cover for ranged attack (no perks yet)
        var coverSitchObj = {
            name: "Out of Cover",
            active: false
        }
        situations.push(coverSitchObj);

        // Sprinting, swimming, and flying all give disadvantage to ranged attacks
        var sprintSitchObj = {
            name: "Sprinting",
            active: false
        }
        situations.push(sprintSitchObj);

        var swimSitchObj = {
            name: "Swimming",
            active: false
        }
        situations.push(swimSitchObj);

        var flyingSitchObj = {
            name: "Flying",
            active: false
        }
        situations.push(flyingSitchObj);
    }

    // Situations for melee attacks
    if(attackInfo[0].weaponType === "Blade" || attackInfo[0].weaponType === "Blunt" || attackInfo[0].weaponType === "Unarmed"){
        console.log('Melee attack')
    }

    // Status effects may affect attacks

    // Heavy strike for Blunt? (need to make sure it's only the first attack and then remove other attacks)
    if(attackInfo[0].weaponType === "Blunt"){
        var vskinSitchObj = {
            name: "Damage to KIN",
            active: false
        }
        situations.push(vskinSitchObj);
    }

    // Heavy strike for Blunt? (need to make sure it's only the first attack and then remove other attacks)
    if(attackInfo[0].weaponType === "Blunt" && attackInfo[0].skillLevel >= 5){
        var hvystrikeSitchObj = {
            name: "Heavy Strike",
            active: false
        }
        situations.push(hvystrikeSitchObj);
    }

    // Special Ops 30 - Choose to keep Spray roll
    if(attackInfo[0].weaponType === "Assault Weapon" && attackInfo[0].attackExpertise == "Special Ops" && attackInfo[0].skillLevel >= 30){
        var groupingSitchObj = {
            name: "Grouping",
            active: false
        }
        situations.push(groupingSitchObj);
    }
    
    // Blade 5 - Sharpen
    if(attackInfo[0].weaponType === "Blade" && attackInfo[0].skillLevel >= 5){
        var sharpSitchObj = {
            name: "Sharpen",
            active: false
        }
        situations.push(sharpSitchObj);
    }

    // Long Range 5 - Focused Breathing to increase hit die by 4
    if(attackInfo[0].weaponType === "Long Range" && attackInfo[0].skillLevel >= 5){
        var focusSitchObj = {
            name: "Focused Breathing",
            active: false
        }
        situations.push(focusSitchObj);
    }

    // Ranger 35 - Gain +2 to hit enemy who used >7m of movement
    if(attackInfo[0].weaponType === "Long Range" && attackInfo[0].attackExpertise == "Ranger" && attackInfo[0].skillLevel >= 5){
        var focusSitchObj = {
            name: "Focused Breathing",
            active: false
        }
        situations.push(focusSitchObj);
    }
    
    // Ranger 50 - Second attack not penalized (handled in modal), extra die of damage if target is considered "Big Game"
    if(attackInfo[0].weaponType === "Long Range" && attackInfo[0].attackExpertise == "Ranger" && attackInfo[0].skillLevel == 50){
        var biggameSitchObj = {
            name: "Big Game Hunter",
            active: false
        }
        situations.push(biggameSitchObj);
    }
   
    // Gunslinger 40 & 50 - -2 to hit all attacks if attacking two different targets (removed at level 50)
    if(attackInfo[0].weaponType === "Pistol" && attackInfo[0].attackExpertise == "Gunslinger" && attackInfo[0].skillLevel >= 40){
        var twotargSitchObj = {
            name: "Two Targets",
            active: false
        }
        situations.push(twotargSitchObj);
    }

    // Brawler 30 - Hit die can carry over
    if(attackInfo[0].weaponType === "Unarmed" && attackInfo[0].attackExpertise == "Brawler" && attackInfo[0].skillLevel >= 30){
        var keephitSitchObj = {
            name: "Keep Hit Die",
            active: false
        }
        situations.push(keephitSitchObj);
    }

    // Brawler 35 - Uppercut targets head with -2 to hit
    if(attackInfo[0].weaponType === "Unarmed" && attackInfo[0].attackExpertise == "Brawler" && attackInfo[0].skillLevel >= 35){
        var uppercutSitchObj = {
            name: "Uppercut",
            active: false
        }
        situations.push(uppercutSitchObj);
    }

    // Brawler 50 - Haymaker is -2 to hit, but deals an additional *2 damage (on top of crit)
    if(attackInfo[0].weaponType === "Unarmed" && attackInfo[0].attackExpertise == "Brawler" && attackInfo[0].skillLevel == 50){
        var haymakerSitchObj = {
            name: "Haymaker",
            active: false
        }
        situations.push(haymakerSitchObj);
    }

    // Driver 35 - Can use offhand attacks when Driving with a -4 penalty to hit
    if(character.vehiclesExpertise == "Driver" && character.vehiclesLevel >= 35){
        var drivingSitchObj = {
            name: "Driving",
            active: false
        }
        situations.push(drivingSitchObj);
    }



    // These get handled in the Modal
    // Espionage 20 & Thief 45 increasing damage when sneaking
    // Skyrunner 45 - -2 penalty to hit when flying (normally disadvantage)
    // Sniper 45 - Auto crits for sneak attack
    // Ranger 30 - Remove cover penalty for long range
    // Ranger 40 - Second attack should have -4 penalty to hit
    // Perception 45 - +1 to hit on all Ranged attacks
    // Duelist 40 - If current ammo is 1, this attack has +2 to hit and deals extra die of damage (NOTE: need modal to handle ammo first!)

    //console.log(situations)

    return situations;
}