import React, { Fragment, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { skills } from '../../../resources/skills';

//Actions
import { getCharacter } from '../../../actions/character';

//Components
import CharViewMain from './CharViewMain';
import CharViewStatus from './CharViewStatus';
import CharViewSkills from './CharViewSkills';
import CharViewActions from './CharViewActions';

const ViewCharacter = ({
    getCharacter,
    character: { character },
    match
}) => {

    useEffect(() => {
        getCharacter(match.params.id);
    }, [getCharacter, match.params.id]);

    const [tabIndex, setTabIndex] = useState(0);

    return !character ? (
        <Fragment></Fragment>
    ) : (
        <Fragment>
            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                <div className="char-header">
                    <div className="header-img">
                        <img className="mary-1" src="https://i.pinimg.com/originals/1d/39/9c/1d399c244e42e794268522ba73b8469c.jpg" alt="" />
                    </div>
                    <div className="header-info">
                        <div className="char-name large text-orange">{character.name}</div>
                        <div className="char-level lead text-light1">Level {character.level} {character.race}</div>
                    </div>
                    <TabList className="header-menu">
                        <Tab className="header-tab">Main</Tab>
                        <Tab className="header-tab">Status</Tab>
                        <Tab className="header-tab">Skills</Tab>
                        <Tab className="header-tab">Actions</Tab>
                    </TabList>
                </div>
                <TabPanel>
                    <CharViewMain />
                </TabPanel>
                <TabPanel>
                    <CharViewStatus />
                </TabPanel>
                <TabPanel>
                    <CharViewSkills />
                </TabPanel>
                <TabPanel>
                    <CharViewActions />
                </TabPanel>
            </Tabs>
        </Fragment>
    );
};

ViewCharacter.propTypes = {
    getCharacter: PropTypes.func.isRequired,
    character: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    character: state.character
});

export default connect(mapStateToProps, {getCharacter})(ViewCharacter);
