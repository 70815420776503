import {
    GET_MY_CHARACTERS,
    GET_CHARACTER,
    CREATE_CHARACTER,
    CHARACTER_ERROR,
    SAVE_CHARACTER,
    DELETE_CHARACTER,
    UPDATE_CHARACTER,
    CLEAR_CHARACTER,
    GET_PREBUILTS
} from '../actions/types';

const initialState = {
    character: null,
    characters: [],
    loading: true,
    error: {}
}

function characterReducer(state = initialState, action) {

    const { type, payload } = action;
    
    switch(type){
        case SAVE_CHARACTER:
        case CREATE_CHARACTER:
        case GET_CHARACTER:
        case UPDATE_CHARACTER:
            return {
                ...state,
                character: payload,
                characters: [],
                loading: false
            };
        case GET_MY_CHARACTERS:
        case GET_PREBUILTS:
            return {
                ...state,
                characters: payload,
                character: null,
                loading: false
            };
        case DELETE_CHARACTER:
            return {
                ...state,
                characters: state.characters.filter((char) => char._id !== payload),
                loading: false
            };
        case CLEAR_CHARACTER:
            return {
                ...initialState
            };
        case CHARACTER_ERROR:
            return {
                character: null,
                characters: [],
                loading: false,
                error: payload
            };
        
        default:
            return state;
    }
}

export default characterReducer;