import React, { Fragment, useState} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { saveCharacter } from '../../../actions/character';
import {setAlert} from '../../../actions/alert';

const ItemStore = ({
    item: {
        items
    },
    character: {
        character
    },
    saveCharacter,
    closeStore
}) => {

    var [classFilter, updateClassFilter] = useState('');
    var [shoppingCart, updateShoppingCart] = useState([]);
    var [newFunds, updateNewFunds] = useState(character.funds);
    var [subtotal, updateSubtotal] = useState(0);
    var [addedWeight, updateWeight] = useState(0);

    const addToCart = (purchase) => (e) => {

        if(newFunds - purchase.value < 0 && e.target.name != "loot"){
            alert('Insufficient funds');
        } else{
            purchase = {
                ...purchase,
                "equipped": "",
                "currentAmmo": purchase.magSize,
                "equippedMods": []
            }
    
            updateShoppingCart(shoppingCart => [...shoppingCart, purchase]);
            if(e.target.name === "buy") {
                updateSubtotal(subtotal + purchase.value);
                updateNewFunds(newFunds - purchase.value);
            }
            updateWeight(addedWeight + parseFloat(purchase.weight.$numberDecimal));
        }
    }

    const clearCart = e => {
        updateShoppingCart([]);
        updateSubtotal(0);
        updateNewFunds(character.funds);
        updateWeight(0);
    }

    const showItems = () => {

        var filteredItems = [];

        if(classFilter != ''){
            filteredItems = items.filter(i => i.classification === classFilter);
        } else {
            filteredItems = items;
        }

        filteredItems.sort((a,b) => a.value - b.value);

        const displayedItems = filteredItems.map((item, index) => 
            <div key={index} className="itemContainer">
                <div className="itemNameType">
                    <p className="itemName">{item.name}</p>
                    <div className="itemType">
                        <p>{item.type}</p>
                        <p>{item.subtype}</p>
                    </div>
                </div>
                <div className="itemDesc">
                    {item.classification === "Armor" ? 
                        <div><p>DIS: {item.dis}</p><p>KIN: {item.kin}</p><p>Mod Slots: {item.modSlots}</p></div>
                    : item.classification === "Weapon" ?
                        <div><p>Num Dice: {item.numDice}</p>
                        {(item.type === "Assault Weapon" || item.type === "Long Range" || item.type === "Pistol") &&
                            <div>
                                <p>Range: {item.range}m</p>
                                <p>Mag: {item.magSize}</p>
                            </div>
                        }
                        <p>Mod Slots: {item.modSlots}</p></div>
                    : <div><p>{item.description}</p></div>
                    }
                </div>
                <div className="storeOptions">
                    <p>{item.value}</p>
                    <div className="storeButtons">
                        {item.buyable ? <button name="buy" onClick={e => addToCart(item)(e)}>Buy</button> : <div></div>}
                        <button name="loot" onClick={e => addToCart(item)(e)}>Loot</button>
                    </div>
                </div>
            </div>
        )

        return displayedItems;
    }

    const changeClassFilter = e => {

        if(e.target.selectedIndex === 0){
            updateClassFilter('');
        } else {
            updateClassFilter(e.target.value);
        }
    }

    const confirmPurchase = e => {

        var newInventory = [...character.inventory, ...shoppingCart];

        saveCharacter({
            ...character,
            "inventory": newInventory,
            "currentCapacity": character.currentCapacity + addedWeight,
            "funds": character.funds - subtotal
        });

        closeStore();
    }

    return (
        <Fragment>
            <div className='closeStoreModal' onClick={e => closeStore()(e)}>X</div>
            <div className="storeWrapper">
                <div className="storeHeader">
                    <h1>Buy Items</h1>
                    <div className="storeSubheader">
                        <select name="filter1" onChange={changeClassFilter}>
                            <option>-- Item Class --</option>
                            <option>Armor</option>
                            <option>Weapon</option>
                            <option>Consumable</option>
                            <option>Misc</option>
                        </select>
                        <div className="storeFunds">
                            <span>Funds:</span> <div>{newFunds}</div>
                        </div>
                    </div>
                    
                </div>
                
                <div className="itemList">
                    {showItems()}
                </div>
                <div className="storeFooter">
                    <div className="storeSubfooter">
                        <h2>SUBTOTAL: {subtotal}</h2>
                        <p>Items: {shoppingCart.length}</p>
                        <p>Weight: {addedWeight}</p>
                    </div>
                    
                    <div className="storeFooterButtons">
                        <button onClick={confirmPurchase}>CONFIRM</button>
                        <button onClick={clearCart}>CLEAR</button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

ItemStore.propTypes = {
    item: PropTypes.object.isRequired,
    character: PropTypes.object.isRequired,
    saveCharacter: PropTypes.func.isRequired,
    closeStore: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    item: state.item,
    character: state.character
});

export default connect(mapStateToProps, {saveCharacter})(ItemStore);
