import React, { Fragment} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { attributeModifier } from '../../../utils/calculations';
import { saveCharacter } from '../../../actions/character';

const CharEditMain = ({
    character: {
        character
    },
    saveCharacter
}) => {

    const {
        gender,
        height,
        physique,
        alignment,
        STR,
        DEX,
        CON,
        INT,
        WIS,
        CHA,
        initiative,
        movement,
        languages,
        personality,
        motivation,
        fears,
        background
    } = character;

    const onChange = e => {
        var newInput = e.target.value;

        saveCharacter({
            ...character,
            [e.target.name]: newInput
        });
    }


    return (
        <Fragment>
            <div className="edit-char-main-panel">
                <div className="first-row">
                    <div className="vitals">
                        <p className="lead text-blue">VITALS</p>
                        <div>
                            GENDER <input className="vital-container" name='gender' value={gender} onChange={onChange}/>
                        </div>
                        <div>
                            HEIGHT <select className="vital-select" name='height' onChange={onChange}>
                                <option selected={height === 'Diminutive' && 'selected'}>Diminutive</option>
                                <option selected={height === 'Short' && 'selected'}>Short</option>
                                <option selected={height === 'Average' && 'selected'}>Average</option>
                                <option selected={height === 'Tall' && 'selected'}>Tall</option>
                                <option selected={height === 'Towering' && 'selected'}>Towering</option>
                            </select>
                        </div>
                        <div>
                            PHYSIQUE <select className="vital-select" name='physique' onChange={onChange}>
                                <option selected={physique === 'Scrawny' && 'selected'}>Scrawny</option>
                                <option selected={physique === 'Slender' && 'selected'}>Slender</option>
                                <option selected={physique === 'Fit' && 'selected'}>Fit</option>
                                <option selected={physique === 'Muscular' && 'selected'}>Muscular</option>
                                <option selected={physique === 'Overweight' && 'selected'}>Overweight</option>
                                <option selected={physique === 'Obese' && 'selected'}>Obese</option>
                            </select>
                        </div>
                        <div>
                        ALIGNMENT <select className="vital-select" name='alignment' onChange={onChange}>
                                <option selected={alignment === 'Lawful Good' && 'selected'} >Lawful Good</option>
                                <option selected={alignment === 'Lawful Neutral' && 'selected'}>Lawful Neutral</option>
                                <option selected={alignment === 'Lawful Evil' && 'selected'}>Lawful Evil</option>
                                <option selected={alignment === 'Neutral Good' && 'selected'}>Neutral Good</option>
                                <option selected={alignment === 'True Neutral' && 'selected'}>True Neutral</option>
                                <option selected={alignment === 'Neutral Evil' && 'selected'}>Neutral Evil</option>
                                <option selected={alignment === 'Chaotic Good' && 'selected'}>Chaotic Good</option>
                                <option selected={alignment === 'Chaotic Neutral' && 'selected'}>Chaotic Neutral</option>
                                <option selected={alignment === 'Chaotic Evil' && 'selected'}>Chaotic Evil</option>
                            </select>
                        </div>
                    </div>
                    <div className="attributes">
                        <p className="lead text-blue">ATTRIBUTES</p>
                        <div className="attribute-container">
                            <div className="attribute-item">
                                STR<br /><input type="number" name='STR' value={STR} min="0" max="30" onChange={onChange} />
                                <div className="att-mod">{STR > 11 ? ('+' + attributeModifier(STR)) : attributeModifier(STR)}</div>
                            </div>
                            <div className="attribute-item">
                                DEX<br /><input type="number" name='DEX' value={DEX} min="0" max="30" onChange={onChange} />
                                <div className="att-mod">{DEX > 11 ? ('+' + attributeModifier(DEX)) : attributeModifier(DEX)}</div>
                            </div>
                            <div className="attribute-item">
                                CON<br /><input type="number" name='CON' value={CON} min="0" max="30" onChange={onChange} />
                                <div className="att-mod">{CON > 11 ? ('+' + attributeModifier(CON)) : attributeModifier(CON)}</div>
                            </div>
                            <div className="attribute-item">
                                INT<br /><input type="number" name='INT' value={INT} min="0" max="30" onChange={onChange} />
                                <div className="att-mod">{INT > 11 ? ('+' + attributeModifier(INT)) : attributeModifier(INT)}</div>
                            </div>
                            <div className="attribute-item">
                                WIS<br /><input type="number" name='WIS' value={WIS} min="0" max="30" onChange={onChange} />
                                <div className="att-mod">{WIS > 11 ? ('+' + attributeModifier(WIS)) : attributeModifier(WIS)}</div>
                            </div>
                            <div className="attribute-item">
                                CHA<br /><input type="number" name='CHA' value={CHA} min="0" max="30" onChange={onChange} />
                                <div className="att-mod">{CHA > 11 ? ('+' + attributeModifier(CHA)) : attributeModifier(CHA)}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className = "second-row">
                    <div className="other-stats">
                        <p className="lead text-blue">OTHER STATS</p>
                        <div>
                            <div>Initiative Bonus <br /><p>{initiative}</p></div>
                            <div>Movement <br /><p>{movement}</p></div>
                        </div>
                    </div>
                    <div className="languages">
                        <p className="lead text-blue">LANGUAGES</p>
                        <div className="lang-container">
                            {languages.map((lang) => (
                                <span key={lang}>
                                    {lang}{lang !== languages[languages.length - 1] && ", "}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="personality">
                    <p className="lead text-blue">PERSONALITY</p>
                    <textarea className="info-container" name='personality' value={personality} cols="93" rows="5" onChange={onChange}></textarea>
                </div>
                <div className="motivation">
                    <p className="lead text-blue">MOTIVATION</p>
                    <textarea className="info-container" name='motivation' value={motivation} cols="93" rows="5" onChange={onChange}></textarea>
                </div>
                <div className="fears">
                    <p className="lead text-blue">FEARS</p>
                    <textarea className="info-container" name='fears' value={fears} cols="93" rows="5" onChange={onChange}></textarea>
                </div>
                <div className="backstory">
                    <p className="lead text-blue">BACKGROUND</p>
                    <textarea className="info-container" name='background' value={background} cols="202" rows="10" onChange={onChange}></textarea>
                </div>
            </div>
        </Fragment>
    )
}

CharEditMain.propTypes = {
    character: PropTypes.object.isRequired,
    saveCharacter: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    character: state.character
});

export default connect(mapStateToProps, {saveCharacter})(CharEditMain);
