import React, {Fragment, useState} from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import PropTypes from 'prop-types'


const Register = ({ setAlert, register, isAuthenticated }) => {

    const [formData, setFormData] = useState({
        email: '',
        username: '',
        password: '',
        password2: ''
    }); 

    const { email, username, password, password2 } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        e.preventDefault();
        if(password !== password2) {
            setAlert('Passwords do not match', 'danger');
        } else {
            register({ email, username, password });
        }
    }

    if(isAuthenticated){
        return <Navigate to='/dashboard' />
    }

    return <Fragment>
            <h1 className="large text-light2">
                Create an Account
            </h1>
            <form className="form" onSubmit={e => onSubmit(e)}>
                <div className="form-group">
                    <input 
                        type="email" 
                        placeholder="Email Address"
                        name='email'
                        value={email}
                        onChange={e => onChange(e)}
                        required
                        autoFocus />
                </div>
                <div className="form-group">
                    <input 
                        type="text" 
                        placeholder="Username"
                        name='username'
                        value={username}
                        onChange={e => onChange(e)}
                        required />
                </div>
                <div className="form-group">
                    <input 
                        type="password" 
                        placeholder="Password" 
                        name='password'
                        value={password}
                        onChange={e => onChange(e)}
                        minLength="6" />
                </div>
                <small className="form-text">Minimum 6 characters. Use at least one uppercase letter and one number.</small>
                <div className="form-group">
                    <input 
                        type="password" 
                        placeholder="Confirm Password" 
                        name='password2'
                        value={password2}
                        onChange={e => onChange(e)}
                        minLength="6" />
                </div>
                <input type="submit" value="Register" className="btn btn-primary" />
                <p className="lead text-light1 mary-1">
                    Already have an account? <Link to="/login">Sign In</Link>
                </p>
            </form>
        </Fragment>
    ;
};

Register.propTypes = {
    setAlert: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert, register })(Register);
