import React, { Fragment, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';

const Login = ({ login, isAuthenticated }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    }); 

    const { email, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        e.preventDefault();
        login(email, password);
    };

    // Navigate if logged in
    if(isAuthenticated){
        return <Navigate to='/dashboard' />
    }

    return <Fragment>
            <h1 className="large text-light2">
                Sign In to Your Account
            </h1>
            <form className="form" onSubmit={e => onSubmit(e)}>
                <div className="form-group">
                    <input 
                        type="email" 
                        placeholder="Email Address"
                        name='email'
                        value={email}
                        onChange={e => onChange(e)}
                        required
                        autoFocus />
                </div>
                <div className="form-group">
                    <input 
                        type="password" 
                        placeholder="Password" 
                        name='password'
                        value={password}
                        onChange={e => onChange(e)}
                        minLength="6" />
                </div>
                <input type="submit" value="Log In" className="btn btn-primary" />
            </form>
            <p className="lead text-light1 mary-1">
                Don't have an account? <Link to="/register">Sign Up</Link>
            </p>
        </Fragment>
    ;
};

Login.propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {login})(Login);
