import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const WikiHome = ({
    
}) => {

    /*var [currentEntry, updateCurrentEntry] = useState("");

    const onChange = e => {
    }*/

    console.log('here');


    return (
        <Fragment>
            <div className="wikiHomeContainer">
                <Link to="/wiki/create-new-page" className="btn">Create New Page</Link>
            </div>
        </Fragment>
    )
}

WikiHome.propTypes = {
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, {})(WikiHome);
