import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import mapImg from '../../img/map.jpg';
import { Square } from './utils/Square';

const EditMap = ({
    campaign: campaign,
    match
}) => {

    const currentArea = campaign.campaign.areas.find(a => a._id === match.params.id);

    const zoomLevels = [0.5, 0.75, 1, 1.5, 2, 2.5, 3, 4];
    var [localProps, updateLocalProps] = useState({
        zoomFactor: 2,
        currentPanX: 0,
        currentPanY: 0,
        panFactorX: 0,
        panFactorY: 0
    });

    //General properties
    
    /*var [zoomFactor, updateZoomFactor] = useState(2);
    var [currentPanX, updateCurrentPanX] = useState(0);
    var [currentPanY, updateCurrentPanY] = useState(0);
    var [panFactorX, updatePanFactorX] = useState(0);
    var [panFactorY, updatePanFactorY] = useState(0);*/
    var [mapCurrentX, updateMapCurrentX] = useState(0);
    var [mapCurrentY, updateMapCurrentY] = useState(0);

    //Grid Properties
    const gridColor = "RGBA(100,100,100,0.5)";
    var [squareSize, setSquareSize] = useState(50);
    var [gridCols, setGridCols] = useState(0);
    var [gridRows, setGridRows] = useState(0);

    //Mouse properties
    var [lMouseDown, toggleLMouse] = useState(false);
    var [mouseDownX, updateMouseDownX] = useState(0);
    var [mouseDownY, updateMouseDownY] = useState(0);

    var [selectedSquare, updateSelectedSquare] = useState({});
    
    var grid = []; 

    const buildGrid = () => {

        var newGrid = [];

        //Create each square in the grid and add them to the grid list
        for(var i = 0; i < gridCols; i++){
            for(var j = 0; j < gridRows; j++){
                var newSquare = new Square((i*gridRows)+j,i,j, squareSize);
                newGrid.push(newSquare);
            }
        }

        grid = newGrid;

    }

    const bgCanvasRef = useRef(null);
    const bgContextRef = useRef(null);
    const bgImageRef = useRef(null);

    //Set initial definitions when page loads, then run the render functions for first time
    useEffect(() => {
        const bgImg = new Image();
        bgImg.src = mapImg;

        bgImg.onload = () => {

            bgImageRef.current = bgImg;

            const bgCanv = bgCanvasRef.current;

            //Canvas height should fit 100%, and then width is based on the aspect ratio
            bgCanv.width = window.innerWidth;

            //Negative 50 to account for navbar
            bgCanv.height = window.innerHeight - 65;
            var bgCanvRect = bgCanv.getBoundingClientRect();

            //Define canvas context and set its global ref
            const bgCTX = bgCanv.getContext('2d');
            bgContextRef.current = bgCTX;

            //Calculate initial number of grid cols and rows based on initial square size
            setGridCols(Math.floor(bgCanvasRef.current.width/squareSize));
            setGridRows(Math.floor(bgCanvasRef.current.height/squareSize));
            buildGrid();

            //Initial render
            updateMap();
        }
    }, [gridCols, gridRows, localProps]);

    const updateMap = () => {

        bgContextRef.current.clearRect(0,0,bgCanvasRef.current.width, bgCanvasRef.current.height); 

        updateMapCurrentX(bgCanvasRef.current.width/2 - bgCanvasRef.current.width*zoomLevels[localProps.zoomFactor]/2 + localProps.currentPanX + localProps.panFactorX);
        updateMapCurrentY(bgCanvasRef.current.height/2 - bgCanvasRef.current.height*zoomLevels[localProps.zoomFactor]/2 + localProps.currentPanY + localProps.panFactorY);

        drawBG();
        drawGrid();

        
    }

    const drawBG = () => {

        bgContextRef.current.drawImage(
            bgImageRef.current,
            mapCurrentX, 
            mapCurrentY,
            bgCanvasRef.current.width * zoomLevels[localProps.zoomFactor],
            bgCanvasRef.current.height * zoomLevels[localProps.zoomFactor]
        );

        
    }

    const drawGrid = () => {
        bgContextRef.current.strokeStyle = gridColor;
        for(var i = 0; i < grid.length; i++){
            bgContextRef.current.strokeRect(
                mapCurrentX + grid[i].x * squareSize * zoomLevels[localProps.zoomFactor], 
                mapCurrentY + grid[i].y * squareSize * zoomLevels[localProps.zoomFactor],
                squareSize*zoomLevels[localProps.zoomFactor], 
                squareSize*zoomLevels[localProps.zoomFactor]
            );
        }
    }

    const handleMouseDown = (e) => {

        switch(e.button){
            case 0:
                toggleLMouse(true);
                updateMouseDownX(e.clientX);
                updateMouseDownY(e.clientY);

                var newSelectedSquare = grid.find(s => 
                    e.clientX >= s.centerX - squareSize*zoomLevels[localProps.zoomFactor]/2 && 
                    e.clientX <= s.centerX + squareSize*zoomLevels[localProps.zoomFactor]/2 && 
                    e.clientY >= s.centerY - squareSize*zoomLevels[localProps.zoomFactor]/2 && 
                    e.clientY <= s.centerY + squareSize*zoomLevels[localProps.zoomFactor]/2
                );

                updateSelectedSquare(newSelectedSquare);
                break;
            case 1:
                console.log('Middle mouse button!');
                break;
            case 2:
                console.log('Right mouse button!');
                break;
            default:
                break;
        }
    }

    const handleMouseUp = (e) => {
        e.preventDefault();

        switch(e.button){
            case 0:
                toggleLMouse(false);
                //Reset mouse info
                toggleLMouse(false);
                updateMouseDownX(0);
                updateMouseDownY(0);

                //Set the new pan amount and reset the pan factor
                updateLocalProps({
                    ...localProps, 
                    currentPanX: localProps.currentPanX + localProps.panFactorX,
                    currentPanY: localProps.currentPanY + localProps.panFactorY,
                    panFactorX: 0,
                    panFactorY: 0
                });
                /*updateCurrentPanX(localProps.currentPanX + localProps.panFactorX);
                updateCurrentPanY(localProps.currentPanY + localProps.panFactorY);
                updatePanFactorX(0);
                updatePanFactorY(0);*/
                break;
            case 1:
                console.log('Middle mouse button!');
                break;
            case 2:
                console.log('Right mouse button!');
                break;
            default:
                break;
        }
    }

    const handleMouseMove = (e) => {
        if(lMouseDown){
            updateLocalProps({
                ...localProps, 
                panFactorX: e.clientX - mouseDownX,
                panFactorY: e.clientY - mouseDownY
            });
        }
    }

    const handleMouseWheel = (e) => {
        //Zoom in and out of the map
        if(e.deltaY > 0){
            console.log('zoom out');
            if(localProps.zoomFactor <= 0){
                updateLocalProps({
                    ...localProps, 
                    zoomFactor: 0
                });
            } else {
                updateLocalProps({
                    ...localProps, 
                    zoomFactor: localProps.zoomFactor-1
                });
            }
        }
        else {
            console.log('zoom in');
            if(localProps.zoomFactor >= zoomLevels.length-1){
                updateLocalProps({
                    ...localProps, 
                    zoomFactor: zoomLevels.length - 1
                });
            } else {
                updateLocalProps({
                    ...localProps, 
                    zoomFactor: localProps.zoomFactor + 1
                });
            }
        }
    }

    return (
        <Fragment>
            <div className="mapContainer">
                <canvas
                className="bgCanvas"
                onMouseDown={e => handleMouseDown(e)}
                onMouseUp={e => handleMouseUp(e)}
                onMouseMove={e => handleMouseMove(e)}
                onWheel = {e => handleMouseWheel(e)}
                onMouseLeave = {e => handleMouseUp(e)}
                onContextMenu={e => {
                    e.preventDefault()
                }}
                ref={bgCanvasRef}
                ></canvas>
            </div>
            
        </Fragment>
        
    )
}

EditMap.propTypes = {
    campaign: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    campaign: state.campaign
});

export default connect(mapStateToProps, {})(EditMap);
