import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveCharacter } from '../../../actions/character';
import { calculateSkillModifiers, calculateSingleSkillMod } from '../../../utils/calculations';
import { skills } from '../../../resources/skills';

const CharCreationSkills = ({
    currentState,
    saveCharacter
}) => {

    console.log(currentState);

    const {
        race,
        STR,
        DEX,
        CON,
        INT,
        WIS,
        CHA,
        skillPoints,
        armorLevel,
        armorExpertise,
        assaultWeaponLevel,
        assaultWeaponExpertise,
        astrobiologyLevel,
        astrobiologyExpertise,
        athleticsLevel,
        athleticsExpertise,
        bladeLevel,
        bladeExpertise,
        bluntLevel,
        bluntExpertise,
        energyLevel,
        energyExpertise,
        espionageLevel,
        espionageExpertise,
        explosivesLevel,
        explosivesExpertise,
        fluidLevel,
        fluidExpertise,
        gravLevel,
        gravExpertise,
        longRangeLevel,
        longRangeExpertise,
        negotiationLevel,
        negotiationExpertise,
        observationLevel,
        observationExpertise,
        pistolLevel,
        pistolExpertise,
        psycheLevel,
        psycheExpertise,
        techLevel,
        techExpertise,
        unarmedLevel,
        unarmedExpertise,
        vehiclesLevel,
        vehiclesExpertise,
        waveLevel,
        waveExpertise
    } = currentState;

    let charPropArray = Object.keys(currentState);
    charPropArray = charPropArray.slice(charPropArray.indexOf('armorLevel'));
    charPropArray = charPropArray.splice(0,charPropArray.indexOf('waveExpertise')+1);

    const levelArray = [
        armorLevel,
        assaultWeaponLevel,
        astrobiologyLevel,
        athleticsLevel,
        bladeLevel,
        bluntLevel,
        energyLevel,
        espionageLevel,
        explosivesLevel,
        fluidLevel,
        gravLevel,
        longRangeLevel,
        negotiationLevel,
        observationLevel,
        pistolLevel,
        psycheLevel,
        techLevel,
        unarmedLevel,
        vehiclesLevel,
        waveLevel
    ];

    const expertiseArray = [
        armorExpertise,
        assaultWeaponExpertise,
        astrobiologyExpertise,
        athleticsExpertise,
        bladeExpertise,
        bluntExpertise,
        energyExpertise,
        espionageExpertise,
        explosivesExpertise,
        fluidExpertise,
        gravExpertise,
        longRangeExpertise,
        negotiationExpertise,
        observationExpertise,
        pistolExpertise,
        psycheExpertise,
        techExpertise,
        unarmedExpertise,
        vehiclesExpertise,
        waveExpertise
    ];

    const modArray = calculateSkillModifiers(
        skills, 
        levelArray, 
        {
            'STR': STR,
            'DEX': DEX,
            'CON': CON,
            'INT': INT,
            'WIS': WIS,
            'CHA': CHA
        },
        expertiseArray
    );

    const createSkillTable = skills => {

        const skillRows = skills.map((s, index) => 
            <div key={index} id={index} className='skill-row'  >
                <div className="select-skill">
                    <button onClick={toggleSelect}>SELECT</button>
                </div>
                <div className="skill-name">{s.baseSkill.name}</div>
                <div className="skill-mod">
                    <input name={charPropArray[1 + 3*index]} value={modArray[index]} onBlur={() => console.log('here!')} />
                </div>
                <div className="skill-att">{s.baseSkill.main_attr}</div>
                <div className="skill-level">
                    <div className="skill-level-value">{levelArray[index]}</div>
                </div>
                
                <div className="skill-level-chart">
                    <div data-for={`basePerkInfo${s.baseSkill.name}`} data-tip='0' className={levelArray[index] > 0 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 1 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 2 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 3 ? "skill-mark filled" : "skill-mark"}></div>
                    <div data-for={`basePerkInfo${s.baseSkill.name}`} data-tip='1' className={levelArray[index] > 4 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 5 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 6 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 7 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 8 ? "skill-mark filled" : "skill-mark"}></div>
                    <div data-for={`basePerkInfo${s.baseSkill.name}`} data-tip='2' className={levelArray[index] > 9 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 10 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 11 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 12 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 13 ? "skill-mark filled" : "skill-mark"}></div>
                    <div data-for={`basePerkInfo${s.baseSkill.name}`} data-tip='3' className={levelArray[index] > 14 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 15 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 16 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 17 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 18 ? "skill-mark filled" : "skill-mark"}></div>
                    <div data-for={`basePerkInfo${s.baseSkill.name}`} data-tip='4' className={levelArray[index] > 19 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 20 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 21 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 22 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 23 ? "skill-mark filled" : "skill-mark"}></div>
                    <div data-for={`expPerkInfo${s.baseSkill.name}`} data-tip='0' className={levelArray[index] > 24 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 25 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 26 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 27 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 28 ? "skill-mark filled" : "skill-mark"}></div>
                    <div data-for={`expPerkInfo${s.baseSkill.name}`} data-tip='1' className={levelArray[index] > 29 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 30 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 31 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 32 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 33 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 34 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 35 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 36 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 37 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 38 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 39 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 40 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 41 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 42 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 43 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 44 ? "perk filled" : "perk"}></div>
                    <div className={levelArray[index] > 45 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 46 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 47 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 48 ? "skill-mark filled" : "skill-mark"}></div>
                    <div className={levelArray[index] > 49 ? "perk filled" : "perk"}></div>
                    <ReactTooltip 
                        id={`basePerkInfo${s.baseSkill.name}`}
                        className='skillTooltip'
                        place='right'
                        effect='solid'
                        getContent={(dataTip) => 
                            <Fragment>
                                <h1>{dataTip && s.baseSkill.perks[dataTip].perk_name}</h1>
                                <div><p>Perk Description</p>{dataTip && s.baseSkill.perks[dataTip].pk_desc}</div>
                                <div><p>Action Description</p>{dataTip && s.baseSkill.perks[dataTip].action_desc && s.baseSkill.perks[dataTip].action_desc}</div>
                                <div><p>Action Type</p>{dataTip && s.baseSkill.perks[dataTip].action_type && s.baseSkill.perks[dataTip].action_type}</div>
                            </Fragment>
                            
                        } 
                    />
                    <ReactTooltip 
                        id={`expPerkInfo${s.baseSkill.name}`}
                        className='skillTooltip'
                        place='right'
                        effect='solid'
                        getContent={(dataTip) =>
                            <Fragment>
                                <h1>{dataTip ? (expertiseArray[index] === s.expertise1.name ? s.expertise1.perks[dataTip].perk_name : s.expertise2.perks[dataTip].perk_name) : ''}</h1>
                                <div><p>Perk Description</p>{dataTip ? (expertiseArray[index] === s.expertise1.name ? s.expertise1.perks[dataTip].pk_desc : s.expertise2.perks[dataTip].pk_desc) : ''}</div>
                                <div>
                                    <p>Action Description</p>{ dataTip ? (expertiseArray[index] === s.expertise1.name ? s.expertise1.perks[dataTip].action_desc : s.expertise2.perks[dataTip].action_desc) : ''}
                                </div>
                                <div><p>Action Type</p>{dataTip ? (expertiseArray[index] === s.expertise1.name ? s.expertise1.perks[dataTip].action_type : s.expertise2.perks[dataTip].action_type) : ''}</div>
                            </Fragment>
                        } 
                    />
                </div>
                <div className="expertise_container">
                    <select name={charPropArray[2 + index*3]} className="expertise_select" defaultValue={expertiseArray[index]} onChange={onChange}>
                        <option>{s.expertise1.name}</option>
                        <option>{s.expertise2.name}</option>
                    </select>   
                </div>
            </div>
        )

        return skillRows;
    };

    const toggleSelect = event => {
        let clickedRow = event.target.closest('.skill-row');
        
        if(clickedRow.className === 'skill-row selected'){
            clickedRow.className = 'skill-row';
            updateSkill(clickedRow.id, 0, skillPoints+1);
            event.target.innerHTML = "SELECT"
        } else {
            if(skillPoints > 0 && clickedRow.childNodes[4].firstChild.innerHTML === '0'){
                clickedRow.className = 'skill-row selected';
                if(race==='Cyborg'){ updateSkill(clickedRow.id, 10, skillPoints-1) }
                else { updateSkill(clickedRow.id, 5, skillPoints-1) }
                event.target.innerHTML = "REMOVE";
            }
        }
    }

    const updateSkill = (ind, newLvl, newSkillPoints) => {

        //Note, haven't found a way to fire off saveCharacter automatically after the modArray is instantiated with calculateSkillModifiers, so we do that on click of skill here
        //Any new modifier gets overridden at the bottom.

        let newMod = calculateSingleSkillMod(skills[ind], newLvl, { "STR": STR, "DEX": DEX, "CON": CON, "INT": INT, "WIS": WIS, "CHA": CHA });

        saveCharacter({
            ...currentState,
            'skillPoints': newSkillPoints,
            [charPropArray[1 + 3*0]]: modArray[0],
            [charPropArray[1 + 3*1]]: modArray[1],
            [charPropArray[1 + 3*2]]: modArray[2],
            [charPropArray[1 + 3*3]]: modArray[3],
            [charPropArray[1 + 3*4]]: modArray[4],
            [charPropArray[1 + 3*5]]: modArray[5],
            [charPropArray[1 + 3*6]]: modArray[6],
            [charPropArray[1 + 3*7]]: modArray[7],
            [charPropArray[1 + 3*8]]: modArray[8],
            [charPropArray[1 + 3*9]]: modArray[9],
            [charPropArray[1 + 3*10]]: modArray[10],
            [charPropArray[1 + 3*11]]: modArray[11],
            [charPropArray[1 + 3*12]]: modArray[12],
            [charPropArray[1 + 3*13]]: modArray[13],
            [charPropArray[1 + 3*14]]: modArray[14],
            [charPropArray[1 + 3*15]]: modArray[15],
            [charPropArray[1 + 3*16]]: modArray[16],
            [charPropArray[1 + 3*17]]: modArray[17],
            [charPropArray[1 + 3*18]]: modArray[18],
            [charPropArray[1 + 3*19]]: modArray[19],
            [charPropArray[0 + 3*ind]]: newLvl,
            [charPropArray[1 + 3*ind]]: newMod,
        });
    }

    const onChange = e => {
        saveCharacter({...currentState, [e.target.name]: e.target.value});
    };

    return ( 
    <Fragment>
        <div className="char-creation-skills-panel">
            <div className="skill-top">
                <div className="skill-info">
                    <p>
                        Skills are the driving force of your character's interactions within a campaign. They govern what your character can do inside and outside of combat. 
                        The majority of rolls will be skill-based, using the skill modifier value you see next to the skill name. 
                        As you increase a skill's level, you will unlock various perks which will in turn make those skills more effective or unlock new actions you can perform.
                        Once a skill reaches level 25, you will pick an expertise for that skill which will make your skill much more powerful but also lock it into a specific archetype.
                        You don't need to decide on an expertise now, but keep in mind the direction you want to build your character.
                    </p>
                    <br />
                    <p>Click on skill to select it. Your race is {race}. {
                        race !== "Human" && race !== "Cyborg" ? "You may select 1 skill currently level 0 to become level 5."
                        : race === "Human" ? "You may select 5 different skills to become level 5." : "You may select 2 skills to become level 10 and then one skill to become level 5."
                        }
                    </p>
                </div>
                <div className="skill-points-container">
                    {race === "Cyborg" ? (
                        <div className="skill-points-item">
                            <p>Level 10 skills remaining:</p>
                            <div>{skillPoints}</div>
                        </div>
                    ) : (
                        <div className="skill-points-item">
                            <p>Level 5 skills remaining:</p>
                            <div>{skillPoints}</div>
                        </div>
                    )}
                </div>
            </div>
            <div className="skill-header">
                <div className="select-skill"></div>
                <div className="skill-name">SKILL<br />NAME</div>
                <div className="skill-mod">MOD</div>
                <div className="skill-att">MAIN ATTR.</div>
                <div className="skill-level">LEVEL</div>
                <div className="skill-level-chart">PERK CHART</div>
                <div className="expertise_container">EXPERTISE</div>
            </div>
            {createSkillTable(skills)}
        </div>
    </Fragment>
    );
};

CharCreationSkills.propTypes = {
    saveCharacter: PropTypes.func.isRequired,
    currentState: PropTypes.object.isRequired
};

export default connect(null, {saveCharacter})(CharCreationSkills);