import React, { Fragment} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveCharacter } from '../../../actions/character';

const CharCreationAttributes = ({
    currentState,
    saveCharacter
}) => {

    const attributeModifier = (attribute) => {
        const modifier = Math.floor((attribute - 10)/2);
        return modifier;
    }

    const increaseAttr = currentAttrVal => (e) => {
        if(attributePoints > 0 && currentAttrVal < 30){
            saveCharacter({
                ...currentState,
                'attributePoints': attributePoints - 1,
                [e.target.value]: currentAttrVal + 1
            });
        }
    }

    const decreaseAttr = currentAttrVal => (e) => {
        if(currentAttrVal > 0){
            saveCharacter({
                ...currentState,
                'attributePoints': attributePoints + 1,
                [e.target.value]: currentAttrVal - 1
            });
        }
    }

    const {
        attributePoints,
        STR,
        DEX,
        CON,
        INT,
        WIS,
        CHA
    } = currentState;

    return ( <Fragment>
        <div className="char-creation-attributes-panel">
            <p className="att_info">
                Attributes are used for skill modifiers and some saving throws. Each attribute will have a modifier based on its level. This modifier may be added to skill depending on the skill and an unlocked expertise.
            </p><br />
            <p>
                All attributes start out as 10 and have a modifier of 0. Every two levels, you will be able to increase one of your attributes by 1. For creating a character, the race you chose will affect your attributes, and then you can subtract or add from each attribute as you like. You cannot have an attribute go lower than 2 or higher than 30.
            </p>
            <h1>STRENGTH</h1>
            <div className="att-row">
                <div className="att-desc">
                    <p>
                        Strength (STR) determines your ability to do strenuous physical activity, such as lifting and pushing.
                    </p>
                    <p>
                        It is the main attribute of these skills: Assault Weapon, Athletics, Blunt, Unarmed
                    </p>
                    <p>
                        These skills have an expertise which uses it: Armor, Energy, Fluids, Gravity, Vehicles
                    </p>
                    </div>
                <div className="att-item">
                    <div>
                        <p>{STR}</p>
                        <div className="att-mod">{STR > 11 ? ('+' + attributeModifier(STR)) : attributeModifier(STR)}</div>
                    </div>
                    <button value="STR" onClick={(e) => decreaseAttr(STR)(e)} className={STR > 0 ? 'minus' : 'disabled'}>-</button>
                    <button value="STR" onClick={(e) => increaseAttr(STR)(e)} className={attributePoints === 0 ? 'disabled' : 'plus'}>+</button>
                </div>
            </div>
            <h1>DEXTERITY</h1>
            <div className="att-row">
                <div className="att-desc">
                    <p>
                    Dexterity (DEX) is a measure of your overall quickness and your ability to react to situations.
                    </p>
                    <p>
                        It is the main attribute of these skills: Blade, Pistol
                    </p>
                    <p>
                        These skills have an expertise which uses it: Armor, Assault Weapon, Athletics, Espionage, Explosives, Gravity, Long Range, Unarmed, Vehicles, Wave
                    </p>
                    </div>
                <div className="att-item">
                    <div>
                        <p>{DEX}</p>
                        <div className="att-mod">{DEX > 11 ? ('+' + attributeModifier(DEX)) : attributeModifier(DEX)}</div>
                    </div>
                    <button value="DEX" onClick={(e) => decreaseAttr(DEX)(e)} className={DEX > 0 ? 'minus' : 'disabled'}>-</button>
                    <button value="DEX" onClick={(e) => increaseAttr(DEX)(e)} className={attributePoints === 0 ? 'disabled' : 'plus'}>+</button>
                </div>
            </div>
            <h1>CONSTITUTION</h1>
            <div className="att-row">
                <div className="att-desc">
                    <p>
                        CONSTITUTION (CON) is your ability to withstand mental and physical pressure.
                    </p>
                    <p>
                        It is the main attribute of these skills: Armor, Explosives, Vehicles
                    </p>
                    <p>
                        These skills have an expertise which uses it: Astrobiology, Athletics, Blunt, Energy, Fluids, Psyche, Tech, Unarmed, Wave
                    </p>
                    </div>
                <div className="att-item">
                    <div>
                        <p>{CON}</p>
                        <div className="att-mod">{CON > 11 ? ('+' + attributeModifier(CON)) : attributeModifier(CON)}</div>
                    </div>
                    <button value="CON" onClick={(e) => decreaseAttr(CON)(e)} className={CON > 0 ? 'minus' : 'disabled'}>-</button>
                    <button value="CON" onClick={(e) => increaseAttr(CON)(e)} className={attributePoints === 0 ? 'disabled' : 'plus'}>+</button>
                </div>
            </div>
            <h1>INTELLIGENCE</h1>
            <div className="att-row">
                <div className="att-desc">
                    <p>
                        Intelligence (INT) determines your ability to recall learned information.
                    </p>
                    <p>
                        It is the main attribute of these skills: Astrobiology, Long Range, Observation, Tech
                    </p>
                    <p>
                        These skills have an expertise which uses it: Blade, Blunt, Explosives, Negotiation, Pistol
                    </p>
                    </div>
                <div className="att-item">
                    <div>
                        <p id="str">{INT}</p>
                        <div className="att-mod">{INT > 11 ? ('+' + attributeModifier(INT)) : attributeModifier(INT)}</div>
                    </div>
                    <button value="INT" onClick={(e) => decreaseAttr(INT)(e)} className={INT > 0 ? 'minus' : 'disabled'}>-</button>
                    <button value="INT" onClick={(e) => increaseAttr(INT)(e)} className={attributePoints === 0 ? 'disabled' : 'plus'}>+</button>
                </div>
            </div>
            <h1>WISDOM</h1>
            <div className="att-row">
                <div className="att-desc">
                    <p>
                        Wisdom (WIS) is an innate understanding of your surroundings and making decisions based on them.
                    </p>
                    <p>
                        It is the main attribute of these skills: Energy, Fluid, Gravity, Psyche, Wave
                    </p>
                    <p>
                        These skills have an expertise which uses it: Assault Weapon, Espionage, Long Range, Negotiation, Observation, Tech
                    </p>
                    </div>
                <div className="att-item">
                    <div>
                        <p>{WIS}</p>
                        <div className="att-mod">{WIS > 11 ? ('+' + attributeModifier(WIS)) : attributeModifier(WIS)}</div>
                    </div>
                    <button value="WIS" onClick={(e) => decreaseAttr(WIS)(e)} className={WIS > 0 ? 'minus' : 'disabled'}>-</button>
                    <button value="WIS" onClick={(e) => increaseAttr(WIS)(e)} className={attributePoints === 0 ? 'disabled' : 'plus'}>+</button>
                </div>
            </div>
            <h1>CHARISMA</h1>
            <div className="att-row">
                <div className="att-desc">
                    <p>
                        Charisma (CHA) aids you as you interact with society and intelligent species.
                    </p>
                    <p>
                        It is the main attribute of these skills: Espionage, Negotiation
                    </p>
                    <p>
                        These skills have an expertise which uses it: Astrobiology, Blade, Observation, Pistol, Psyche
                    </p>
                    </div>
                <div className="att-item">
                    <div>
                        <p>{CHA}</p>
                        <div className="att-mod">{CHA > 11 ? ('+' + attributeModifier(CHA)) : attributeModifier(CHA)}</div>
                    </div>
                    <button value="CHA" onClick={(e) => decreaseAttr(CHA)(e)} className={CHA > 0 ? 'minus' : 'disabled'}>-</button>
                    <button value="CHA" onClick={(e) => increaseAttr(CHA)(e)} className={attributePoints === 0 ? 'disabled' : 'plus'}>+</button>
                </div>
            </div>
        </div>
    </Fragment>
    )
}

CharCreationAttributes.propTypes = {
    saveCharacter: PropTypes.func.isRequired,
    currentState: PropTypes.object.isRequired
};

export default connect(null, {saveCharacter})(CharCreationAttributes);
