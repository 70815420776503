import axios from 'axios';
import { setAlert } from './alert';

import {
    GET_ALL_ITEMS,
    ITEM_ERROR
} from './types';

//Get all items in db
export const getAllItems = () => async dispatch => {
    try {
        const res = await axios.get('/api/item');

        dispatch({
            type: GET_ALL_ITEMS,
            payload: res.data
        });
    } catch (err) {
        const errors = err.response.data.errors;

        if(errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: ITEM_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}