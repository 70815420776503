import React, { Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getPrebuiltCharacters, createCharacter } from '../../actions/character';

const CharCreationOptions = ({
    character
}) => {

    return (
        <Fragment>
            <div className="createCharWrapper">
                <div className="createCharColumn">
                    <h1>Freeform</h1>
                    <div>
                        You are in complete control of your character, and no one will tell you what to do...except the GM...and your party...and all of society.
                    </div>
                    <Link className="createCharConfirm" to="/create-character" state={{build: ""}}>Select</Link>
                </div>
                <div className="createCharColumn">
                    <h1>Use a Build</h1>
                    <div>
                        You will still be in control of your character's background, equipment, and such, but the build will help guide you through changes to attributes and skills when you level up.
                    </div>
                    <div className="select">Select</div>
                </div>
                <div className="createCharColumn">
                    <h1>Pre-Built Character</h1>
                    <div>Recommended for players of campaigns that have pre-built characters or for players who cannot be bothered to spend a few minutes doing it themselves.</div>
                    <Link className="createCharConfirm" to="/prebuilt-characters">Select</Link>
                </div>
            </div>
            
        </Fragment>
    )
}

CharCreationOptions.propTypes = {
    character: PropTypes.object.isRequired,
    getPrebuiltCharacters: PropTypes.func.isRequired,
    createCharacter: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    character: state.character
});

export default connect(mapStateToProps, {getPrebuiltCharacters, createCharacter})(CharCreationOptions);