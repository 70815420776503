import React, { Fragment, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';

//Actions
import { getCharacter } from '../../../actions/character';
import { saveCharacter } from '../../../actions/character';
import { updateCharacter } from '../../../actions/character';
import { getAllItems } from '../../../actions/item';

//Components
import CharEditMain from './CharEditMain';
import CharEditStatus from './CharEditStatus';
import CharEditSkills from './CharEditSkills';
import CharEditActions from './CharEditActions';
import CharEditInventory from './CharEditInventory';

import LevelUp from '../modals/LevelUp';

const EditCharacter = ({
    getCharacter,
    character: { character },
    saveCharacter,
    updateCharacter,
    getAllItems,
    item
}) => {

    let { id } = useParams();

    console.log(id);

    useEffect(() => {
        getCharacter(id);
        getAllItems();
    }, [getCharacter, id, getAllItems]);

    const [currentChar, changeChar] = useState(character);
    const [tabIndex, setTabIndex] = useState(0);
    var [showLvlUpModal, toggleLvlUpModal] = useState(false);

    const openLvlUpModal = (e) => {
        toggleLvlUpModal(true);
    }

    const closeModals = (e) => {
        toggleLvlUpModal(false);
    }

    const submitCharacter = () => {
        updateCharacter(character);
        changeChar(character);
    }

    const onChange = e => {
        if(e.target.name === 'level'){

            var newLvl = e.target.value;

            if(newLvl > 20){
                newLvl = 20;
            }

            saveCharacter({
                ...character,
                'level': newLvl
            });
        }
    }

    return !character ? (
        <Fragment></Fragment>
    ) : (
        <Fragment>
            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                <div className="char-header">
                    <div className="header-info">
                        <div className="char-name large text-orange">{character.name}</div>
                        <div className="char-level lead text-light1">
                            <div>Level <span>{character.level}</span><span>{character.race}</span></div>
                            <div className="level-up-but" onClick={openLvlUpModal}>LEVEL UP</div>
                        </div>
                        <Modal
                            isOpen={showLvlUpModal}
                            onRequestClose={closeModals}
                            className="lvlUpModal"
                            overlayClassName="lvlUpOverlay"
                        >
                            <LevelUp closeModal={closeModals} />
                        </Modal>
                    </div>
                    <TabList className="header-menu">
                        <Tab className="header-tab">Main</Tab>
                        <Tab className="header-tab">Status</Tab>
                        <Tab className="header-tab">Skills</Tab>
                        <Tab className="header-tab">Actions</Tab>
                        <Tab className="header-tab">Inventory</Tab>
                    </TabList>
                </div>
                <TabPanel>
                    <CharEditMain />
                </TabPanel>
                <TabPanel>
                    <CharEditStatus />
                </TabPanel>
                <TabPanel>
                    <CharEditSkills />
                </TabPanel>
                <TabPanel>
                    <CharEditActions />
                </TabPanel>
                <TabPanel>
                    <CharEditInventory />
                </TabPanel>
            </Tabs>
            <div className="char-creation-footer">
                {currentChar === character ? (
                    "There are no changes to save."
                ) : (
                    <button className="submit" onClick={submitCharacter}>SAVE CHANGES</button>
                )}
            </div>
        </Fragment>
    );
};

EditCharacter.propTypes = {
    getCharacter: PropTypes.func.isRequired,
    character: PropTypes.object.isRequired,
    saveCharacter: PropTypes.func.isRequired,
    updateCharacter: PropTypes.func.isRequired,
    getAllItems: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    character: state.character,
    item: state.item
});

export default connect(mapStateToProps, {getCharacter, saveCharacter, updateCharacter, getAllItems})(EditCharacter);
