import React, { Fragment} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { attributeModifier } from '../../../utils/calculations';

const CharViewMain = ({
    character: {
        character
    }
}) => {

    const {
        gender,
        height,
        physique,
        alignment,
        STR,
        DEX,
        CON,
        INT,
        WIS,
        CHA,
        passivePerception,
        initiative,
        movement,
        languages,
        personality,
        motivation,
        fears,
        backstory
    } = character;


    return (
        <Fragment>
            <div className="char-sheet-main-panel">
                <div className="main-row">
                    <div className="vitals">
                        <p className="lead text-blue">VITALS</p>
                        <div>
                            GENDER <div className="vital-container">{gender}</div>
                        </div>
                        <div>
                            HEIGHT <div className="vital-container">{height}</div>
                        </div>
                        <div>
                            PHYSIQUE <div className="vital-container">{physique}</div>
                        </div>
                        <div>
                        ALIGNMENT <div className="vital-container">{alignment}</div>
                        </div>
                    </div>
                    <div className="stats">
                        <div className="attributes">
                            <p className="lead text-blue">ATTRIBUTES</p>
                            <div className="attribute-container">
                                <div>
                                    STR<p id="str">{STR}</p>
                                    <div className="att-mod">
                                        {STR > 11 ? ('+' + attributeModifier(STR)) : attributeModifier(STR)}
                                    </div>
                                </div>
                                <div>
                                    DEX<p id="dex">{DEX}</p>
                                    <div className="att-mod">
                                        {DEX > 11 ? ('+' + attributeModifier(DEX)) : attributeModifier(DEX)}
                                    </div>
                                </div>
                                <div>
                                    CON<p id="con">{CON}</p>
                                    <div className="att-mod">
                                        {CON > 11 ? ('+' + attributeModifier(CON)) : attributeModifier(CON)}
                                    </div>
                                </div>
                                <div>
                                    INT<p id="int">{INT}</p>
                                    <div className="att-mod">
                                        {INT > 11 ? ('+' + attributeModifier(INT)) : attributeModifier(INT)}
                                    </div>
                                </div>
                                <div>
                                    WIS<p id="wis">{WIS}</p>
                                    <div className="att-mod">
                                        {WIS > 11 ? ('+' + attributeModifier(WIS)) : attributeModifier(WIS)}
                                    </div>
                                </div>
                                <div>
                                    CHA<p id="cha">{CHA}</p>
                                    <div className="att-mod">
                                        {CHA > 11 ? ('+' + attributeModifier(CHA)) : attributeModifier(CHA)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="other-stats">
                            <p className="lead text-blue">OTHER STATS</p>
                            <div>
                                <div>Passive Perception <p id="passive-perception">{passivePerception}</p></div>
                                <div>Initiative <p id="initiative">{initiative}</p></div>
                                <div>Movement <p id="movement">{movement}m</p></div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="main-row">
                    <div className="languages">
                        <p className="lead text-blue">LANGUAGES</p>
                        <div className="lang-container">
                            {languages.map((lang) => (
                                <span key={lang}>
                                    {lang}{lang !== languages[languages.length - 1] && ", "}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className="personality">
                        <p className="lead text-blue">PERSONALITY</p>
                        <div className="info-container">{personality}</div>
                    </div>
                </div>
                <div className="main-row">
                    <div className="motivation">
                        <p className="lead text-blue">MOTIVATION</p>
                        <div className="info-container">{motivation}</div>
                    </div>
                    <div className="fears">
                        <p className="lead text-blue">FEARS</p>
                        <div className="info-container">{fears}</div>
                    </div>
                </div>
                <div className="main-row">
                    <div className="backstory">
                        <p className="lead text-blue">BACKSTORY</p>
                        <div className="info-container">{backstory}</div>
                    </div>
                </div>
            </div>
            
        </Fragment>
    )
}

CharViewMain.propTypes = {
    character: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    character: state.character
});

export default connect(mapStateToProps)(CharViewMain);
